import React, { useState } from 'react';
import { Image } from 'app/components/elements';
import { Modal, Carousel } from "react-bootstrap";

export function PhotoModal(props) {

  return (
    <Modal show={props.showPhotoModal} dialogClassName="modal-xl" onHide={() => props.setShowPhotoModal(false)} centered className="p-0">
      <Modal.Body className="p-3" style={{backgroundColor: '#000'}}>
        <Carousel>
          {props.srcs.map((src) => (
            <Carousel.Item>
              <Image src={src} style={{maxHeight: '85vh'}}/>
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  );

}
