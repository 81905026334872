import React from 'react';
import { Outlet } from 'react-router-dom';
import { Footer, Header, OrderCongratulations, Scroller } from './';
import { ModalContainer } from 'app/components/modal';

function Layout(props) {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <Scroller />
      <ModalContainer />
    </>
  );
}


export default Layout;
