import React, { useEffect, useState } from 'react';

function ConnectorInfo(props) {
  //console.log('props connector', props);
  const [name, setName] = useState('');
  const [iconSrc, setIconSrc] = useState();

  useEffect(() => {
    let title = props.connector.name.toLowerCase();
    title = title.charAt(0).toUpperCase() + title.slice(1);
    setName(title);
  }, [props.connector])

  useEffect(() => {
    if (name === 'Metamask') {
      setIconSrc(require('app/assets/wallet-logos/metamask.png'));
    } else {
      setIconSrc(require('app/assets/wallet-logos/ethereum.png'));
    }
  }, [name])

  return (
    <div className="d-inline-flex align-items-center">
      <img src={iconSrc} width={36} />
      <div className="d-none d-sm-block ms-3">{name}</div>
    </div>
  )
}

export { ConnectorInfo };
