import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classNames from "classnames";

import "./radios-inline.sass";

class RadiosInline extends React.Component {

  static propTypes = {
    colors: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledMessage: PropTypes.node,
    items: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func
  };

  static defaultProps = {
    onChange: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      thumbLeft: 0,
      thumbWidth: 0
    };

    this._elementRef = React.createRef();
  }

  componentDidMount() {
    const { parentElement: { offsetLeft, offsetWidth } } = this._elementRef.current.querySelector('input:checked');

    this.setState({
      thumbLeft: offsetLeft,
      thumbWidth: offsetWidth,
    });
  }

  _inputOnChange = ({ target: { parentElement: { offsetLeft, offsetWidth }, name, value } }) => {
    const { disabled, onChange } = this.props;

    if (disabled) {
      return false;
    }

    this.setState({
      thumbLeft: offsetLeft,
      thumbWidth: offsetWidth,
    });

    onChange({ name, value });
  };

  _renderInputElement = (index, checked = false, value = (index + 1), label = (index + 1)) => {
    const { colors, disabled, name  } = this.props;

    return (
      <label key={`${name}-${value}`}>
        <input
          type="radio"
          name={name}
          value={value}
          defaultChecked={checked}
          disabled={disabled}
          onChange={this._inputOnChange}
        />

        <span
          style={(
            colors ?
              { backgroundColor: value } :
              null
          )}
        >
          {label}
        </span>
      </label>
    );
  };

  _renderDisabledMessage = () => (
    <div className="radios-inline__disabled-message">
      {this.props.disabledMessage}
    </div>
  );

  render() {
    const {
      props: { colors, disabled, items },
      state: { thumbLeft, thumbWidth }
    } = this;

    const isFirstChecked = (_.findIndex(items, item => (!!item.checked)) === -1);

    return (
      <div
        ref={this._elementRef}
        className={classNames(
          'radios-inline',
          {
            colors,
            disabled,
          }
        )}
      >
        <div
          className="radios-inline__thumb"
          style={{
            left: thumbLeft,
            width: thumbWidth,
          }}
        />

        {
          _.map(items, (item, index) => this._renderInputElement(
            index,
            (
              (isFirstChecked && index === 0) ?
                true :
                item.checked
            ),
            item.value,
            item.label
          ))
        }
      </div>
    );
  }
}

export { RadiosInline }
