import axios from 'axios';

import { store } from 'app/store/reducers/';

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const LOG_IN = 'LOG_IN';
export const LOG_IN_FACEBOOK = 'LOG_IN_FACEBOOK';
export const LOG_IN_TWITTER = 'LOG_IN_TWITTER';
export const LOG_IN_JWT = 'LOG_IN_JWT';
export const VERIFY_CODE = 'VERIFY_CODE'
export const LOG_OUT = 'LOG_OUT';
export const GET_FEATURED_USERS = 'GET_FEATURED_USERS';
export const GET_USER = 'GET_USER';
export const GET_USER_BALANCE = 'GET_USER_BALANCE';
export const GET_USER_PROFILE_PHOTO = 'GET_USER_PROFILE_PHOTO';
export const SET_USER = 'SET_USER';
export const SET_USER_PROFILE_PHOTO = 'SET_USER_PROFILE_PHOTO';
export const DELETE_USER_PROFILE_PHOTO = 'DELETE_USER_PROFILE_PHOTO';
export const UPDATE_USER = 'UPDATE_USER';
export const PATCH_TWO_FACTOR_AUTH = 'PATCH_TWO_FACTOR_AUTH';
export const GET_TWO_FACTOR_AUTH = 'TWO_FACTOR_AUTH';


export const createAccount = async (data) => ({
  type: CREATE_ACCOUNT,
  payload: await axios.post('/users', data),
});

//export const logIn = async ({ email, password }, onSuccess = () => {}) => ({
export const logIn = async ({ email, password }) => ({
  type: LOG_IN,
  payload: await axios.post('/tokens', { email, password }),
  /*payload: axios.all([
    axios.post('/tokens', { email, password }),
    axios.get('/users/balance')
  ]),*/
  //meta: { onSuccess },
});


export const logInJWT = async (data) => ({
  type: LOG_IN_JWT,
  payload: await axios.post('/users/'+data.userId+'/confirmations', { "confirmationCode": data.code }),
});

export const logInFacebook = async (accessToken) => ({
  type: LOG_IN_FACEBOOK,
  payload: await axios.post('/tokens/facebook', { accessToken }),
});

export const logInTwitter = async (code) => ({
  type: LOG_IN_TWITTER,
  payload: await axios.post('/tokens/twitter', { code: code }),
});


export const verify2FA = async (verificationCode) => ({
  type: VERIFY_CODE,
  payload: await axios.post('/tokens/verify?code='+verificationCode),
});



export const logOut = () => ({
  type: LOG_OUT,
  payload: null,
});

export const getFeaturedUsers = async () => ({
  type: GET_FEATURED_USERS,
  payload: await axios.get('/users/featured'),
});

//export const getUser = async (id = store.getState().user.userId) => (
export const getUser = async (userId) => {
  const id = (userId) ? userId : store.getState().user.userId;
  return {
    type: GET_USER,
    payload: await axios.get(`/users/${id}`),
  };
};

export const getUserBalance = async () => ({
  type: GET_USER_BALANCE,
  payload: await axios.get('/users/balance'),
});

/*export const getUserProfilePhoto = (id = store.getState().user.userId) => ({
  type: GET_USER_PROFILE_PHOTO,
  //payload: axios.get(`/users/${id}/profileImage`),
  payload: (
    id ?
      `${axios.defaults.baseURL}users/${id}/profileImage` :
      null
  ),
  meta: { id },
});*/

export const setUser = (data) => ({
  type: SET_USER,
  payload: data,
});
export const setUserProfilePhoto = async (file) => {
  const formData = new FormData();
  formData.append('photo', file);
  return {
    type: SET_USER_PROFILE_PHOTO,
    payload: await axios.post('/users/profileImage', formData, { headers: { 'Content-Type': 'multipart/form-data' } }),
  };
};
export const deleteUserProfilePhoto = async () => ({
  type: DELETE_USER_PROFILE_PHOTO,
  payload: await axios.delete('/users/'+store.getState().user.userId+'/profileImage')
});
/*export const setUserData = (data = {}) => ({
  type: SET_USER,
  payload: data,
});*/


export const updateUser = async (data, userId = store.getState().user.userId) => ({
  type: UPDATE_USER,
  payload: await axios.patch(`/users/${userId}`, data),
  meta: data,
});


export const patch2FA = async (data, userId = store.getState().user.userId) => ({
  type: PATCH_TWO_FACTOR_AUTH,
  payload: await axios.patch(`/users/twoFactorAuth`, data),
});

export const get2FA = async (data) => ({
  type: GET_TWO_FACTOR_AUTH,
  payload: await axios.get(`/users/twoFactorAuth`),
});
