import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './chart.sass';

import { numberFormat, separateNumber } from 'app/utils';

import { Attributes } from 'app/components';
import { Icon } from 'app/components/elements';

import { ChartElement } from '..';

class Chart extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    noXAxis: PropTypes.bool,
  };

  _getChartInfo = () => {
    const arr = _.map(this.props.data, (item) => item.value);

    const min = _.min(arr);
    const max = _.max(arr);

    return {
      chartMin: Math.round(min),
      chartMax: Math.round(max),
      // chartChange: Math.round(((min - max) / max) * 10000) / 100,
      chartChange: Math.round(( (max - min) / min) * 10000) / 100,
    };
  };

  render() {
    const { data, noXAxis } = this.props;

    const { chartMin, chartMax, chartChange } = this._getChartInfo();

    return (
      <div className="chart">
{/*        <div className="chart__header">
          <Attributes
            theme="grey-blue"
            items={[
              {
                title: 'Lowest',
                value: (
                  <div className="icons-inline">
                    <Icon className="no-fill" icon="priceDollar" viewBox="0 0 20 20" />

                    <span>{numberFormat(chartMin)}</span>
                  </div>
                ),
              },
              {
                title: 'Highest',
                value: (
                  <div className="icons-inline">
                    <Icon className="no-fill" icon="priceDollar" viewBox="0 0 20 20" />

                    <span>{numberFormat(chartMax)}</span>
                  </div>
                ),
              },
              {
                title: 'Change',
                value: (
                  <div className="icons-inline">
                    <span className={chartChange > 0 ? 'text-green' : 'text-red'}>
                      {numberFormat(chartChange)}
                      <strong>%</strong>
                    </span>
                  </div>
                ),
              },
            ]}
          />
        </div>*/}

        <ChartElement data={data} noXAxis={noXAxis} />
      </div>
    );
  }
}

export { Chart };
