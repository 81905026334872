import axios from 'axios';


// - - - - - - - - - - - GLOBAL CONSTANTS - - - - - - - - - - - - -



export function getEtherscanURL() {
  return (process.env.REACT_APP_ENV === 'dev') ? "https://goerli.etherscan.io" : "https://etherscan.io";
}

export function getOpenSeaURL() {
  return (process.env.REACT_APP_ENV === 'dev') ?
    ("https://testnets.opensea.io/assets/sepolia/").concat(process.env.REACT_APP_NFT_CONTRACT_ADDRESS)
  :
    ("https://opensea.io/assets/ethereum/").concat(process.env.REACT_APP_NFT_CONTRACT_ADDRESS)
}





// - - - - - - - - - - - ADMIN - - - - - - - - - - - - -

export async function getStatistics(params) {
  return axios.get("admin/statistics", { params });
}






// - - - - - - - - - - - Car Gifting / Redeeming - - - - - - - - - - - - -

export async function getCampaignInfo(campaign) {
  return axios.get(`/campaigns/${campaign}`);
}

export async function redeemCampaignCar(campaign) {
  return axios.post(`/campaigns/${campaign}`, {});
  //return await axios.post(`/campaigns/${campaign}`, {}).then((res) => res.data); // Empty body
}




// - - - - - - - - - - - Bridge - - - - - - - - - - - - -


export async function getNetworkFee(id) {
  return await axios.get("/blockchain/evm-bridge/transfers/estimatedFee/"+id);
}





// - - - - - - - - - - - Vehicle - - - - - - - - - - - - -
export async function getVehicleByID(id) {
  return await axios.get("/vehicles/"+id);
}


export async function getVehicleByMakeModelProd({make, model, productionNum}) {
  if (make && model && productionNum) {
    if (!isNaN(make)) {
      // support old links in the format: http://localhost:3000/model/377/Cadillac_Ghostbusters_Ecto/0003
      // where in the position of "make" we have the car id
      return axios.get("/vehicles/"+make);
    } else {
      return axios.get(`/vehicles/${make}/${model}/${productionNum}`);
    }
  }
};


export async function getVehicleByMakeModel({make, model}) {
  if (make && model) {
    const searchLatest = await axios.get("/vehicles", {
      params: {
        search: `${make} ${model}`,
        inShowroom: true,
        sortField: "model,make",
        sortType: "ASC",
        size: 1,
        page: 0
      }
    });
    //console.log('searchLatest', searchLatest);
    const latestID = searchLatest.content[0].id;

    const fullVehicle = await getVehicleByID(latestID);
    //console.log('fullVehicle', fullVehicle);
    return fullVehicle;
  }
};


export async function getVehicleHistory(id) {
  return axios.get(`vehicles/${id}/history`);
}



export async function sellVehicle({id, price}) {
  return axios.post(`vehicles/${id}/auction`, { price })
}

export async function cancelSell(id) {
  return axios.delete(`vehicles/${id}/auction`)
}









// - - - - - - - - - - - Vehicles - - - - - - - - - - - - -

const defaultVehiclesParams = {
  size: 9,
  page: 0,
  sortField: 'model,make',
  sortType: 'ASC',
};

export async function getVehicles(params) {
  if (!params.size) params.size = 9;
  if (!params.page) params.page = 0;
  if (!params.sortField) params.sortField = 'model,make';
  if (!params.sortType) params.sortType = 'ASC';
  return axios.get('/vehicles', { params: params });
}


// export async function getVehicles(type, params = {}, watchList = false) {
//   let tabsName;
//   let title;
//   let description;
//   if (params instanceof URLSearchParams) {
//     // User client went on direct url with params preset already
//     defaultVehiclesParams.forEach((param, key) => {
//       if (!params.has(key)) {
//         params.append(key, param);
//       }
//     });
//   } else {
//     switch (type) {
//       case "ALL":
//         params.inShowroom = true;
//         title = "All Cars";
//         tabsName = "all";
//         break;
//       case "NEW":
//         params.inShowroom = true;
//         params.sortField = 'releaseDate,model,make'
//         params.sortType = 'DESC'
//         title = "New In";
//         tabsName = "new";
//         break;
//       case "FEATURED":
//         params.featured = true;
//         params.inAuction = false;
//         params.inShowroom = true;
//         title = "Featured";
//         tabsName = "featured";
//         break;
//       case "MARKETPLACE":
//         params.inAuction = true;
//         title = "Secondary Market";
//         tabsName = "marketplace";
//         description = "The Scondary Market showcases all cars listed for sale by other users at the price specified by them.";
//         break;
//       default:
//         params.inShowroom = true;
//     }
//
//     params = { ...defaultVehiclesParams, ...params };
//   }
//
//
//   try {
//     const response = await axios.get('/vehicles', { params });
//     const data = {title: title, tabsName: tabsName, description: description, ...response}
//     return data;
//   } catch (e) {
//     throw new Error(e);
//   }
//
// }
