import React from 'react';
import PropTypes from 'prop-types';
import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';
import _ from 'lodash';
import moment from 'moment';

import './chart-element.sass';

import { numberFormat } from 'app/utils';

import { Icon } from 'app/components/elements';

class ChartElement extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    noXAxis: PropTypes.bool,
    tooltipOffset: PropTypes.number,
  };

  static defaultProps = {
    tooltipOffset: 10,
  };

  _axisData = [];

  _tooltipProps = null;

  constructor(props) {
    super(props);

    this.state = {
      activeDotData: {
        value: 0,
        date: '',
      },
      activeDotX: 0,
      activeDotY: 0,
      tooltipX: 0,
      tooltipY: false,
    };

    if (!props.noXAxis) {
      const step = Math.round(props.data.length / 12);

      _.forEach(props.data, ({ date }, index) => {
        if (index % step === 0) {
          this._axisData.push(moment(date).format('MMM DD'));
        }
      });
    }

    this._chartWrapRef = React.createRef();
    this._tooltipRef = React.createRef();
  }

  // componentDidUpdate(props) {
  //   if (!props.noXAxis && props.data.length) {
  //     this._axisData = [];
  //     const step = Math.round(props.data.length / 12);
  //
  //     _.forEach(props.data, ({ date }, index) => {
  //       if (index % step === 0) {
  //         this._axisData.push(moment(date).format('MMM DD'));
  //       }
  //     });
  //
  //     console.log('this._axisData', this._axisData, props);
  //   }
  // }

  _renderActiveDot = (props) => {
    this._tooltipProps = props;

    return null;
  };

  _mouseMoveHandler = () => {
    if (!this._tooltipProps) {
      return;
    }

    if (this._tooltipProps.cx === this.state.activeDotX) {
      return;
    }

    const { tooltipOffset } = this.props;

    const chartWrapWidth = this._chartWrapRef.current.getBoundingClientRect().width;
    const tooltipRect = this._tooltipRef.current.getBoundingClientRect();

    let tooltipX = 0;
    const tooltipWidthHalf = tooltipRect.width / 2;
    const leftOutside = this._tooltipProps.cx - tooltipWidthHalf - tooltipOffset;
    const rightOutside = chartWrapWidth - this._tooltipProps.cx - tooltipWidthHalf - tooltipOffset;

    if (leftOutside < 0) {
      tooltipX = leftOutside;
    } else if (rightOutside < 0) {
      tooltipX = -rightOutside;
    }

    this.setState({
      activeDotData: this._tooltipProps.payload,
      activeDotX: this._tooltipProps.cx,
      activeDotY: this._tooltipProps.cy,
      tooltipX,
      tooltipY: this._tooltipProps.cy - tooltipRect.height - tooltipOffset / 2 < tooltipOffset,
    });
  };

  render() {
    const {
      props: { data, noXAxis },
      state: { activeDotData, activeDotX, activeDotY, tooltipX, tooltipY },
    } = this;

    return (
      <div ref={this._chartWrapRef} className="chart-wrap">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data} margin={{ top: 0, left: 0, right: 0, bottom: 0 }} onMouseMove={this._mouseMoveHandler}>
            <Tooltip cursor={false} content={() => null} isAnimationActive={false} />

            <Line
              dataKey="value"
              legentType="none"
              dot={false}
              activeDot={this._renderActiveDot}
              stroke="#10ACF3"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </LineChart>
        </ResponsiveContainer>

        {!noXAxis && (
          <div className="chart__axis">
            {_.map(this._axisData, (item, index) => (
              <span key={index}>{item}</span>
            ))}
          </div>
        )}

        <div className="chart__info-element chart__line-x" style={{ transform: `translateY(${activeDotY + 1}px)` }} />
        <div className="chart__info-element chart__line-y" style={{ transform: `translateX(${activeDotX}px)` }} />

        <div
          className="chart__info-element chart__active-dot"
          style={{ transform: `translate(${activeDotX}px, ${activeDotY}px)` }}
        >
          <div
            ref={this._tooltipRef}
            className="chart__tooltip"
            style={{
              transform: `translate(calc(-50% - ${tooltipX}px), ${tooltipY ? 'calc(100% + 1.87497rem)' : '0'})`,
            }}
          >
            <div className="chart__tooltip__price">
              {(this.props.type === "transactions") &&
                <span>{numberFormat(activeDotData.value)} txns</span>
              }
              {(this.props.type === "users") &&
                <span>{numberFormat(activeDotData.value)} users</span>
              }
              {(this.props.type === "dollars") &&
                <div className="icons-inline">
                  <Icon icon="priceDollar" viewBox="0 0 20 20" />
                  <span>{'$' + numberFormat(activeDotData.value)}</span>
                </div>
              }
            </div>

            <div className="chart__tooltip__date">{moment(activeDotData.date).format('MM/DD/YYYY h:mm A')}</div>
          </div>
        </div>
      </div>
    );
  }
}

export { ChartElement };
