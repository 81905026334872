import moment from "moment";

import { LOG_OUT } from "app/store/actions/userActions";

/*const responseImitation = (current, expected) => {
  if (!current) {
    return expected;
  }

  if (!expected) {
    return current;
  }

  return { ...current, ...expected };
};*/

export const redirectMiddleware = ({ getState, dispatch }) => (next => (action = {}) => {
  const { meta = {} } = action;
  const { expireDate, jwt, auth } = getState().user;

  const isExpired = (
    moment(expireDate).isValid() ?
      moment().isSameOrAfter(expireDate * 1000) :
      true
  );

  if (((isExpired || !jwt) && auth) && action.type !== LOG_OUT) {
    return dispatch({ type: LOG_OUT });
  }

  if (meta.onSuccess && !action.error) {
    meta.onSuccess && meta.onSuccess();
  }

  /*if (action.type === 'GET_VEHICLE') {
    action.payload = responseImitation(...action.payload)
  }*/

  return next(action);
});
