import React from 'react';
import PropTypes from 'prop-types';

import './buttons-inline.sass';

const ButtonsInline = ({ children, className }) => <div className={`buttons-inline ${className}`}>{children}</div>;

ButtonsInline.propTypes = {
  //children: PropTypes.array.isRequired,
  className: PropTypes.string,
};
ButtonsInline.defaultProps = {
  className: '',
};

export { ButtonsInline };
