import React, { useState } from 'react';
import { Player, LoadingSpinner, BigPlayButton } from 'video-react';
import { Modal } from "react-bootstrap";

import './video-modal.sass'

function VideoModal(props) {

  return (
    <Modal show={props.showVideoModal} dialogClassName="modal-xl" onHide={() => props.setShowVideoModal(false)} centered className="p-0">
      <Modal.Body className="p-0">
        <Player autoPlay={true} loop={true} >
          <source src={props.src} />
          <LoadingSpinner />
          <BigPlayButton position="center" />
        </Player>
      </Modal.Body>
    </Modal>
  );

}

export { VideoModal };
