import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./separate-section.sass";

const SeparateSection = ({ children, big, small }) => (
  <div
    className={classNames(
      'separate-section',
      {
        big,
        small,
      }
    )}
  >
    {children}
  </div>
);

SeparateSection.propTypes = {
  children: PropTypes.node.isRequired,
  big: PropTypes.bool,
  small: PropTypes.bool,
};

export { SeparateSection }
