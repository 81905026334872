import React from "react";
import PropTypes from "prop-types";

import "./logo.sass";

import { Image } from "..";

import LogoDark from "./images/logo_dark.png";
import LogoWhite from "./images/logo_light.png";

const Logo = (props) => (
    <img
      src={(props.white ? LogoWhite : LogoDark)}
      alt="MotoBloq"
      style={props.style}
      className={props.className}
    />
);

Logo.propTypes = {
  white: PropTypes.bool
};

export { Logo }
