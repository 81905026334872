import { useEffect, useState } from 'react'
import { useContractRead } from 'wagmi'
import motobloqABI from "app/contracts/motobloqABI.json";
//import { getNFTContractAddress } from 'app/api';

export default function useEthereumCarOwner(vehicleId, enabled) {

  const read = useContractRead({
    address: process.env.REACT_APP_NFT_CONTRACT_ADDRESS,
    abi: motobloqABI,
    functionName: 'ownerOf',
    args: [vehicleId],
    enabled: ((vehicleId > 0) && enabled !== false),
  });

  return read;
}
