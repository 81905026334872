import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import './select.sass';

import { Icon } from 'app/components/elements';

class Select extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    labelPre: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    search: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['auto'])]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    labelPre: '',
    onChange: () => {},
  };

  _options = [];

  _selectSearchType = 'auto';

  _selectKeyEvents = {
    13: () => this._setActiveOption(),
    38: () => this._optionFocusPrev(),
    40: () => this._optionFocusNext(),
  };

  _selectOptionsContainerHeight = null;

  constructor(props) {
    super(props);

    this._options = _.map(props.options, (label, value) => ({
      label,
      value,
    }));

    this.state = {
      selectOpen: false,
      options: this._options,
      optionsDisplay: true,
      optionsDisplayTop: false,
      optionsShow: false,
      label: props.value && props.options[props.value] ? props.options[props.value] : this._options[0].label,
      value: props.value ? props.value : this._options[0].value,
    };

    this.state.activeOptionIndex = this.state.focusOptionIndex =
      props.value && props.options[props.value] ? _.findIndex(this._options, (item) => item.value === props.value) : 0;

    if (props.search) {
      this._selectSearchType = props.search;
    }

    this._elementRef = React.createRef();
  }

  componentDidMount() {
    this._selectOptionsContainerHeight = this._elementRef.current.querySelector('.select-options-wrap').offsetHeight;

    this.setState({ optionsDisplay: false });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        selectOpen: state.selectOpen,
        options: state.options,
        optionsDisplay: state.optionsDisplay,
        optionsDisplayTop: state.optionsDisplayTop,
        optionsShow: state.optionsShow,
        label: props.options[props.value],
        value: props.value,
      };
    }

    return null;
  }

  _selectOpen = () => {
    const elementPos = this._elementRef.current.getBoundingClientRect();

    this.setState({
      selectOpen: true,
      optionsDisplay: true,
      optionsDisplayTop: window.innerHeight - elementPos.bottom < this._selectOptionsContainerHeight,
      optionsShow: true,
      focusOptionIndex: this.state.activeOptionIndex,
    });

    window.addEventListener('click', this._windowClick, false);
    window.addEventListener('keydown', this._windowKeyDown, false);
  };
  _selectClose = () => {
    this.setState({
      selectOpen: false,
      options: this._options,
      optionsShow: false,
    });

    window.removeEventListener('click', this._windowClick, false);
    window.removeEventListener('keydown', this._windowKeyDown, false);

    setTimeout(() => this.setState({ optionsDisplay: false }), 150);
  };
  _selectToggle = () => this[`_select${this.state.selectOpen ? 'Close' : 'Open'}`]();

  _windowClick = ({ target }) => {
    if (!target) {
      return;
    }

    if (!this._elementRef.current.contains(target)) {
      this._selectClose();
    }
  };
  _windowKeyDown = (e) => {
    if (this._selectKeyEvents[e.keyCode.toString()]) {
      e.preventDefault();

      this._selectKeyEvents[e.keyCode.toString()]();
    }
  };

  _selectSearch = ({ target: { value } }) => {
    const options = _.filter(this._options, (item) => item.label.toLowerCase().indexOf(value.toLowerCase()) !== -1);

    this.setState({
      options,
      focusOptionIndex: options.length && value ? 0 : this.state.activeOptionIndex,
    });
  };

  _optionOnMouseOver = (option) => this.setState({ focusOptionIndex: this._getOptionIndex(option) });
  _optionFocusPrev = () => {
    if (this.state.focusOptionIndex === 0) {
      return;
    }

    this.setState({ focusOptionIndex: this.state.focusOptionIndex - 1 });
  };
  _optionFocusNext = () => {
    if (this.state.focusOptionIndex === this.state.options.length - 1) {
      return;
    }

    this.setState({ focusOptionIndex: this.state.focusOptionIndex + 1 });
  };

  _setActiveOption = (option = this.state.options[this.state.focusOptionIndex]) => {
    const newState = {
      label: option.label,
      value: option.value,
    };

    newState.activeOptionIndex = newState.focusOptionIndex = this._getOptionIndex(option);

    this.setState(newState);

    this._selectClose();

    this.props.onChange(newState);
  };

  _getOptionIndex = (option) => _.findIndex(this.state.options, (item) => item.value === option.value);

  _renderSelectSearch = () => {
    if (
      this._selectSearchType === false ||
      (this._selectSearchType === 'auto' && this._options.length < 25) ||
      (typeof this._selectSearchType === 'number' && this._options.length < this._selectSearchType)
    ) {
      return null;
    }

    return (
      <div className="select-search">
        <input
          type="text"
          className="select-search__input"
          autoFocus={true}
          placeholder="Start typing..."
          onChange={this._selectSearch}
        />
      </div>
    );
  };

  _renderSelectOptions = () => (
    <div
      className={classNames('select-options-wrap', {
        top: this.state.optionsDisplayTop,
        open: this.state.optionsShow,
      })}
    >
      {!this.state.optionsDisplayTop ? this._renderSelectSearch() : null}

      <div className="select-options">
        {_.map(this.state.options, (option, index) => (
          <div
            key={option.value}
            className={classNames('select-option', {
              active: this.state.value === option.value,
              focus: this.state.focusOptionIndex === index,
            })}
            onMouseOver={() => this._optionOnMouseOver(option)}
            onClick={() => this._setActiveOption(option)}
          >
            {option.label}
            {this.props.className === 'currency' && (
              <span className="currency-action">{this.state.value === option.value ? 'Active' : 'Set-up'}</span>
            )}
          </div>
        ))}
      </div>

      {this.state.optionsDisplayTop && this._renderSelectSearch()}
    </div>
  );

  render() {
    const {
      props: { className, disabled, labelPre, name, options, value },
      state: { optionsDisplayTop, selectOpen, label, optionsDisplay },
    } = this;

    return (
      <div
        ref={this._elementRef}
        className={classNames(
          'select-container',
          {
            top: optionsDisplayTop,
            open: selectOpen,
            disabled,
          },
          className,
        )}
      >
        <select name={name} defaultValue={value} disabled={true}>
          {_.map(options, (label, value) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>

        <div className="select-wrap" onClick={disabled ? () => {return} : this._selectToggle}>
          <div className="select-value">
            {labelPre ? <span className="select-value__pre-label">{labelPre}</span> : ''}
            {label}
          </div>

          <Icon icon="dropdown" />
        </div>

        {optionsDisplay && this._renderSelectOptions()}
      </div>
    );
  }
}

export { Select };
