import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { showModal } from "app/store/actions/modalsActions";

class ModalToggle extends React.Component {
  
  static propTypes = {
    children: PropTypes.node.isRequired,
    modal: PropTypes.element.isRequired
  };
  
  _modalCreate = () => {
    const { showModal, modal } = this.props;
    
    showModal(modal);
  };
  
  render() {
    
    return React.cloneElement(this.props.children, { onClick: this._modalCreate });
  }
}

ModalToggle = connect(
  () => ({}),
  { showModal }
)(ModalToggle);

export { ModalToggle }
