import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Icon } from 'app/components/elements';
import { useLocation } from "react-router";

import './scroller.sass';
import { windowScrollTo } from 'app/utils';

function Scroller(props) {
  const location = useLocation();

  const [show, setShow] = useState(false);

  const _scrollToTopHandler = () => {
    windowScrollTo();
  };

  useEffect(() => {

    function _windowScrollHandler() {
      setShow(window.scrollY > window.innerWidth);
    };

    window.addEventListener('scroll', _windowScrollHandler, false);
    return () => {
      window.removeEventListener('scroll', _windowScrollHandler, false);
    }
  }, [])


  useEffect(() => {
    if (location.state && location.state.scrollTag) {
      // Scroll to anchor by #id
      setTimeout(() => {
        const element = document.getElementById(location.state.scrollTag);
        if (element) element.scrollIntoView({ behavior: 'smooth' });
      }, 50);
    } else {
      // Scroll to top of page
      _scrollToTopHandler();
    }
  }, [location]);


  return (
    <button
      className={classNames('button circle scroll-to-top', { show: show })}
      onClick={_scrollToTopHandler}
    >
      <Icon icon="dropdown" white />
    </button>
  );

}

export { Scroller };
