import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom"

import axios from 'axios';
import { isMobile, isIOS } from "react-device-detect";
import { Loader } from 'app/components/loader/loader';

import { Row, Col, Button, Stack } from 'react-bootstrap';
import { Logo } from 'app/components/elements';
// import { Link } from "react-router-dom";
// import { route } from "app/routes";


import { useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, Stage, Grid, OrbitControls, Environment } from '@react-three/drei'
import { EffectComposer, Bloom, ToneMapping } from '@react-three/postprocessing'
import { easing } from 'maath'


//import "@google/model-viewer";
import "@google/model-viewer/dist/model-viewer";


export default function ModelViewer(props) {

  // const { id, make, model, productionNum } = useParams();

  const { id, make, model, productionNum } = useMemo(() => {
    const parts = window.location.pathname.split('/');
    return {
      id: parts[2],
      make: parts[2],
      model: parts[3],
      productionNum: parts[4],
    }
  }, [window.location.pathname])



  const [vehicleGltfURL, setVehicleGltfURL] = useState();
  const [message, setMessage] = useState("Loading 3D model...");


  const [windowSize, setWindowSize] = useState({width: window.innerWidth, height: window.innerHeight});


  useEffect(() => {
    const fetchCarGLTF = async () => {
      try {
        if (make && model && productionNum) {
          const response = await axios.get(`/vehicles/${make}/${model}/${productionNum}/model`);
          setVehicleGltfURL(response.url);
        } else if (id) {
          const response = await axios.get(`/vehicles/${id}/model`);
          setVehicleGltfURL(response.url);
        } else {
          setMessage("Invalid car")
        }
      } catch (e) {
        console.log('e', e);
        setMessage("Error loading model")
        // setMessage('Something went wrong - We are redirecting you to the home page.')
        // setTimeout(() => { navigate('/') }, 4000)
      }
    }
    fetchCarGLTF();
  }, [])



  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    });
    return () => {
      window.removeEventListener("resize", () => {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      })
    }
  }, []);

  const background = useGLTF("/assets/Motobloq_Hemisphere.glb")


  //<OrbitControls autoRotate autoRotateSpeed={0.05} enableZoom={false} makeDefault minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2} />
  return (
    <>
      {vehicleGltfURL ?
        <>

        <Canvas flat shadows camera={{ position: [0, 17, 0], fov: 25 }} style={{ height: windowSize.height, cursor: 'grab' }}>
          <fog attach="fog" args={['black', 10, 1000]} />
          {/*<Stage intensity={0.5} environment="city" shadows={{ type: 'accumulative', bias: -0.001, intensity: Math.PI }}>*/}
            <primitive object={background.scene} scale={9} position={[0, -0.95 ,0]} />
            <Kamdo modelURL={vehicleGltfURL} />
          {/*</Stage>*/}
          <Grid renderOrder={-1} position={[0, -0.98, 0]} infiniteGrid cellSize={0.6} cellThickness={0.6} sectionSize={3.3} sectionThickness={1.5} sectionColor={[0.5, 0.5, 10]} fadeDistance={500} />
          <OrbitControls autoRotate autoRotateSpeed={0.05} enableZoom={true} maxDistance={17} makeDefault  maxPolarAngle={1.55}/>
          <EffectComposer disableNormalPass>
            <Bloom luminanceThreshold={2} mipmapBlur />
            <ToneMapping />
          </EffectComposer>
          <Environment background preset="sunset" blur={0.8} />
        </Canvas>

        </>

        :

        <div className="container text-center my-5">
          <p>{message}</p>
          <Loader show={true} className="my-5" style={{ display: 'inline-flex' }}/>
        </div>

      }
    </>
  )

}

function Kamdo(props) {
  const head = useRef()
  const stripe = useRef()
  const light = useRef()
  const { scene, nodes, materials } = useGLTF(props.modelURL);

  // useFrame((state, delta) => {
  //   const t = (1 + Math.sin(state.clock.elapsedTime * 2)) / 2
  //   stripe.current.color.setRGB(2 + t * 20, 2, 20 + t * 50)
  //   easing.dampE(head.current.rotation, [0, state.pointer.x * (state.camera.position.z > 1 ? 1 : -1), 0], 0.4, delta)
  //   light.current.intensity = 1 + t * 4
  // })

  return (
    <group rotation={[0, 0, 0]} position={[0, -0.9 ,0]}>
      <primitive object={scene} scale={1} />
    </group>
  )

  // return (
  //   <group rotation={[0, Math.PI, 0]}>
  //     <mesh castShadow receiveShadow geometry={nodes.body001.geometry} material={materials.Body} />
  //     <group ref={head}>
  //       <mesh castShadow receiveShadow geometry={nodes.head001.geometry} material={materials.Head} />
  //       <mesh castShadow receiveShadow geometry={nodes.stripe001.geometry}>
  //         <meshBasicMaterial ref={stripe} toneMapped={false} />
  //         <pointLight ref={light} intensity={1} color={[10, 2, 5]} distance={2.5} />
  //       </mesh>
  //     </group>
  //   </group>
  // )
}

// useGLTF.preload(vehicleData?.assets?.gltf)
