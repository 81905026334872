import axios from 'axios';
// import moment from 'moment';
import _ from 'lodash';

// import { asset } from 'app/utils';

import { GET_VEHICLE_LOADING, GET_VEHICLE_HISTORY, ADD_TO_AUCTION, GET_VEHICLE, SHOW_ORDER_CONGRATULATIONS, HIDE_ORDER_CONGRATULATIONS, GET_CAR_PRE_SALE_INFO } from 'app/store/actions/vehicleActions';


const vehicleData = {
  error: false,
  isLoading: true,
  inWatchlist: false,
  history: [],
  orderCongratulationsId: null,
  id: '',
  ownerId: null,
  ownerEmail: null,
  make: '',
  model: '',
  year: 0,
  countryOfOrigin: '',
  countryCode: '',
  description: '',
  engine: '',
  horsePower: 0,
  maxSpeedPerMph: 0,
  productionPerYear: 0,
  color: '',
  type: '',
  sellingPlace: 'SHOWROOM',
  releaseName: '',
};

export function vehicle(state = vehicleData, action = {}) {
  switch (action.type) {
    case GET_VEHICLE_LOADING:
      return { ...state, isLoading: true };
    case GET_VEHICLE:
      return { ...state, isLoading: false, ...action.payload };
    case GET_VEHICLE_HISTORY:
      const history = _.concat(
        _.map(_.reverse(action.payload[1]), (item) => {
          item.owner = {
            id: item.newOwnerId,
            profilePhoto: `${axios.defaults.baseURL}users/${action.payload[0].ownerId}/profileImage`,
          };

          return item;
        }),
        // [
        //   {
        //     id: Number(action.payload[0].id),
        //     owner: {
        //       id: action.payload[0].ownerId,
        //       profilePhoto: asset('images/motobloq-profile-photo.svg'),
        //     },
        //     soldDate: moment().format(),
        //     soldPrice: action.payload[0].value || 1000,
        //     source: 'SHOWROOM',
        //   },
        // ],
      );

      return { ...state, ...action.payload[0], ...{ history } };
    case ADD_TO_AUCTION:
      return { ...state, ...action.payload };
    case SHOW_ORDER_CONGRATULATIONS:
    case HIDE_ORDER_CONGRATULATIONS:
      return { ...state, orderCongratulationsId: action.payload };

    case GET_CAR_PRE_SALE_INFO:
      return { ...state, ...action.payload, sellingPrice: action.payload.sellingPrice || null };

    default:
      return state;
  }
}
