import moment from 'moment';

export function timeToPretty(time) {
  //const format = moment(time, 'PThhhHmmMssS').format('hh:mm:ss');
  //const time = "PT451H41M18S"

  var hours = time.substring(
    time.indexOf("T") + 1,
    time.lastIndexOf("H")
  );

  var minutes = time.substring(
    time.indexOf("H") + 1,
    time.lastIndexOf("M")
  );
  //console.log('hours', hours, minutes);

  if (parseInt(hours) > 24) {
    return Math.floor(hours / 24).toString() + ' Days'
  } else {
    return hours.toString() + ' Hours';
  }
}
