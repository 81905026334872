import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Radio } from '..';

class Radios extends React.Component {
  static propTypes = {
    checkFirst: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    const checkedIndex = _.findIndex(props.items, (item) => item.checked);

    this.state = { checkedValue: checkedIndex !== -1 ? props.items[checkedIndex].value : null };
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props;

    if (items !== prevProps.items) {
      const checkedIndex = _.findIndex(items, (item) => item.checked);

      this.setState({ checkedValue: checkedIndex !== -1 ? items[checkedIndex].value : null });
    }
  }

  _onChange = (value) => {
    this.setState({ checkedValue: value });

    this.props.onChange(value);
  };

  render() {
    const {
      props: { checkFirst, items, name },
      state: { checkedValue },
    } = this;

    return (
      <div className="radio-list">
        {_.map(items, ({ value, label }, index) => {
          if (!value) {
            value = index + 1;
          }

          return (
            <Radio
              key={value}
              checkbox
              listItem
              name={name}
              value={value}
              checked={(checkFirst && index === 0) || checkedValue === value}
              label={label}
              onChange={() => this._onChange(value)}
            />
          );
        })}
      </div>
    );
  }
}

export { Radios };
