import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './section-title.sass';

const SectionTitle = ({ children, big, small, white, className }) => (
  <div
    className={classNames('section-title', className, {
      big,
      small,
      white,
    })}
  >
    {children}
  </div>
);

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
  big: PropTypes.bool,
  small: PropTypes.bool,
  white: PropTypes.bool,
  className: PropTypes.string,
};

export { SectionTitle };
