import React from 'react';
import PropTypes from 'prop-types';
// import axios from 'axios';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { route } from 'app/routes';

import './featured-users.sass';

import { CartUserGarage } from 'app/components';
import { SectionHeader, SectionText, SectionTitle, SeparateSection } from 'app/components/elements';

class FeaturedUsers extends React.Component {
  static propTypes = {
    description: PropTypes.node,
    exclude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.node,
  };

  _data = null;

  constructor(props) {
    super(props);

    this.state = {
      users: [
        {
          ownerId: 'uiuiouio',
          nickname: 'nickname',
          vehicles: [
            {
              id: 'b6d2883a-f2eb-45c7-8735-87892f058fb4',
              make: 'Bentley ',
              model: 'EXP_100',
              year: 1969,
              countryOfOrigin: 'USA',
              countryCode: 'US',
              description: 'Mustang',
              engine: '4.9 L Boss V8 4-barrel',
              horsePower: 290,
              maxSpeedPerMph: 128,
              productionPerYear: 30000,
              color: 'RED',
              type: 'MUSCLE',
              transferabilityStatus: 'GIVE_AWAY',
              winnerId: '5d3176cf-b21f-41c9-8cce-cec47a66479b',
              winnerName: 'MB_James',
            },
            {
              id: '1',
              make: 'Jaguar',
              model: 'C-X75',
              year: 1997,
              countryOfOrigin: 'England',
              countryCode: 'GB',
              description:
                "The McLaren F1 was conceived by Gordon Murray to be the ultimate roadcar and by all accounts he succeeded, having been acclaimed the 'greatest car ever created' by multiple sources. On March 31, 1998 a prototype F1 (XP5) set the Guinness World Record for fastest production car at 240.1 mph which it held until 2005.  The McLaren still holds the record for the fastest naturally  aspirated production car.  An F1 also won LeMans outright in 1995.  Of the 106 cars produced from 1992-98 only 64 were road versions of which a mere 7 were federalize for the USA.  Current values for a US spec F1 road car are in excess of $15,000,000. USD.  Did you know the McLaren F1 has 3 seats with the driver in the center and slightly ahead of the passengers?",
              engine: '6.1 L V12',
              horsePower: 602,
              maxSpeedPerMph: 240.1,
              productionPerYear: 11,
              color: 'ORANGE',
              type: 'SUPERCAR',
              transferabilityStatus: 'RESERVABLE',
              estimatedSalePrice: 157,
              isAvailable: true,
            },
            {
              id: '2',
              make: 'Subaru',
              model: 'WRX_STI',
              year: 1997,
              countryOfOrigin: 'England',
              countryCode: 'GB',
              description:
                "The McLaren F1 was conceived by Gordon Murray to be the ultimate roadcar and by all accounts he succeeded, having been acclaimed the 'greatest car ever created' by multiple sources. On March 31, 1998 a prototype F1 (XP5) set the Guinness World Record for fastest production car at 240.1 mph which it held until 2005.  The McLaren still holds the record for the fastest naturally  aspirated production car.  An F1 also won LeMans outright in 1995.  Of the 106 cars produced from 1992-98 only 64 were road versions of which a mere 7 were federalize for the USA.  Current values for a US spec F1 road car are in excess of $15,000,000. USD.  Did you know the McLaren F1 has 3 seats with the driver in the center and slightly ahead of the passengers?",
              engine: '6.1 L V12',
              horsePower: 602,
              maxSpeedPerMph: 240.1,
              productionPerYear: 11,
              color: 'ORANGE',
              type: 'SUPERCAR',
              transferabilityStatus: 'RESERVABLE',
              estimatedSalePrice: 157,
              isAvailable: false,
              isComingSoon: true,
            },
          ],
        },
      ],
    };
  }

  componentDidMount() {
    // axios.all([axios.get('/users/featured'), axios.get('/vehicles', { params: { size: 100 } })]).then((data) => {
    //   // TODO: bad code
    //   data[1] = _.unionBy(data[1], ({ make, model, year }) => `${make} ${model} ${year}`);
    //   this._data = data;
    //   //   this._updateData();
    // });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.exclude !== this.props.exclude && this._data) {
      //   this._updateData();
    }
  }

  _updateData = () => {
    let users = _.map(this._data[0], (user) => {
      //user.vehicles = _.filter(this._data[1], ({ ownerId }) => (ownerId === user.ownerId));

      // TODO: bad code
      user.vehicles = _.sampleSize(this._data[1], 6);

      return user;
    });

    if (this.props.exclude) {
      users = _.filter(users, ({ ownerId }) => ownerId !== this.props.exclude);
    }

    this.setState({ users });
  };

  render() {
    const {
      props: { description, title },
      state: { users },
    } = this;

    return (
      <section className="featured-users">
        <div className="container">
          <SectionHeader>
            <SectionTitle>{title}</SectionTitle>

            {!!description && <SectionText>{description}</SectionText>}
          </SectionHeader>

          <div className="featured-users__list">
            <div className="row">
              {_.map(users, (user) => (
                <div key={user.ownerId} className="col-4 col-sm-12">
                  <CartUserGarage user={user} />
                </div>
              ))}
            </div>
          </div>

          <SeparateSection>
            <Link to={route('garage')}>View all garages</Link>
          </SeparateSection>
        </div>
      </section>
    );
  }
}

export { FeaturedUsers };
