import React from 'react';
import PropTypes from 'prop-types';

import './checkbox.sass';

import { Radio } from '../radio/radio';

const Checkbox = ({ checked, label, name, value, onChange, className, children }) => (
  <Radio
    checkbox
    name={name}
    value={value}
    checked={checked}
    label={label || children}
    onChange={onChange}
    className={className}
  />
);

Checkbox.propTypes = {
  checkbox: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  value: '1',
};

export { Checkbox };
