import PropTypes from "prop-types";

const ModalBody = ({ children }) => children;

ModalBody.displayName = 'ModalBody';

ModalBody.propTypes = {
  children: PropTypes.node
};

ModalBody.defaultProps = {
  children: null
};

export { ModalBody }
