import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import * as analytics from '../ga4'

export function useGoogleAnalytics() {
  const location = useLocation()

  useEffect(() => {
    analytics.init()
  }, [])

  useEffect(() => {
    const path = location.pathname + location.search;
    //console.log('sending to analytics path:', path);
    analytics.sendPageview(path);
  }, [location])

}

export default useGoogleAnalytics
