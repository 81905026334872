import ga4 from 'react-ga4';

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID
const isProduction = (process.env.REACT_APP_BASE_URL !== "https://api.motobloqdevapi.host/v1/api/");


export const init = () => ga4.initialize(TRACKING_ID, {
  testMode: !isProduction
  //testMode: false
})

export const sendEvent = (name) => ga4.event({
  category: "my test category",
  action: name
})

export const sendPageview = (path) => {
  //console.log('sending to analytics hit path:', path);
  ga4.send({
    hitType: 'pageview',
    page: path
  });
}
