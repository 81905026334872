import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';


function RequireNoAuth({ children }) {
  const user = useSelector((state) => state.user);

  return (user.auth === false) ? children : <Navigate to="/" replace />;
}

export { RequireNoAuth };
