import React from "react";

import "./page-loader.sass";

const PageLoader = () => (
  <div className="page-loader">
    <svg focusable="false" preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 100">
      <circle cx="50%" cy="50%" r="45"/>
    </svg>
  </div>
);

export { PageLoader }
