import React from 'react';
import PropTypes from 'prop-types';

import './car-preview.sass';
import { Image } from 'app/components/elements';
import { Row, Col } from 'react-bootstrap';


/**
 * Component for showing one car previev (front side).
 */

function CarPreview(props) {
  const imagesRandom = ['Ford GT40 34 View.png', 'Dastun 240Z 34 View.png', 'Blastolene Special 34 View.png', 'Alfa Romeo 4C 34 View.png', 'Ford Fiesta 34 View.png', 'Corvette C3 34 View.png']

  function displayRandomCar() {
    return (
      <Image
        src={require('./images/'.concat(imagesRandom[Math.floor(Math.random()*imagesRandom.length)]))}
        style={props.style}
        className={'preview-front-side ' + props.className}
        noLazyLoad
      />
    )
  }

  return (
    <Row className="justify-content-center align-items-end" style={{overflow: 'hidden'}}>
      <Col>
        <div className="car-preview-offset">
          {(props.vehicleData) &&
            <Image
              src={props.vehicleData.assets.front_side_right ? props.vehicleData.assets.front_side_right : props.vehicleData.assets.front_side}
              webpSrc={!props.vehicleData.assets.front_side_right ? props.vehicleData.assets.front_side_webp : undefined}
              style={props.style}
              className={props.className}
              noLazyLoad
            />
          }
          {(!props.vehicleData && props.showRandom) &&
            <>
              {displayRandomCar()}
            </>
          }
        </div>
      </Col>
    </Row>
  );

}

// static propTypes = {
//   vehicleData: PropTypes.object.isRequired,
// };

export { CarPreview };
