import axios from 'axios';
import _ from 'lodash';
import { store } from 'app/store/reducers';

import { withdrawalSellVehicle } from 'app/store/actions/vehicleActions';

export const GET_USER_COLLECTION = 'GET_USER_COLLECTION';
export const GET_WATCHLIST = 'GET_WATCHLIST';


export const GET_ETHEREUM = 'GET_ETHEREUM';
export const GET_BUYING = 'GET_BUYING';
export const GET_TRADE = 'GET_TRADE';
export const GET_SELLING = 'GET_SELLING';

export const GET_RECOMMENDATIONS = 'GET_RECOMMENDATIONS';
export const ADD_TO_WATCHLIST = 'ADD_TO_WATCHLIST';
export const REMOVE_FROM_WATCHLIST = 'REMOVE_FROM_WATCHLIST';

export const ADD_CAR_PROCESSING = 'ADD_CAR_PROCESSING';

const defaultVehiclesParams = {
  size: 9,
  page: 0,
  sortField: 'whenBought',
  sortType: 'DESC',
};

export const addToWatchList = async (vehicleId) => ({
  type: ADD_TO_WATCHLIST,
  payload: await axios.post('/users/watchlist', { vehicleId }),
});
export const removeFromWatchList = async (vehicleId) => ({
  type: REMOVE_FROM_WATCHLIST,
  payload: await axios.delete(`/users/watchlist/${vehicleId}`),
});

export const getCollectionList = (ownerId = store.getState().user.userId) => getGarageData(GET_USER_COLLECTION, { ownerId });

export const getWatchList = (params) => getGarageData(GET_WATCHLIST, params, true);

export const getSellList = (params) => getGarageData(GET_SELLING, { ownerId: store.getState().user.userId, inAuction: true, ...params });



export const deleteAuction = carID => async dispatch => {
  const response = await axios.delete(`vehicles/${carID}/auction`);
  dispatch(getSellList());
  return;
}


// export const getBayingList = (params) => getGarageData(GET_WATCHLIST, params);

export const getGarageData = async (type, params = {}, ownerId) => {

  if (params instanceof URLSearchParams) {
    _.forEach(defaultVehiclesParams, (param, key) => {
      if (!params.has(key)) {
        params.append(key, param);
      }
    });
  } else {
    params = { ...defaultVehiclesParams, ...params };
  }

  // console.log('paramzzz', params, ownerId);

  //if (!params.ownerId)

  if (type === GET_USER_COLLECTION) {
    return {
      type,
      payload: await axios.get(('/garages/').concat(ownerId), { params }),
      meta: params,
    };
  } else if (type === GET_WATCHLIST) {
    return {
      type,
      payload: await axios.get('/users/watchlist', { params }),
      meta: params,
    }
  } else {
    return {
      type,
      payload: await axios.get('/vehicles', { params }),
      meta: params,
    }
  }


  // return {
  //   type,
  //   //payload: await axios.get(watchList ? '/users/watchlist' : '/vehicles', { params }),
  //   payload: await axios.get(watchList ? '/users/watchlist' : ('/garages/').concat(ownerId), { params }),
  //   meta: params,
  // };

};
