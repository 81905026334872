import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import './form.sass';

import { reactChildrenMap, showNotification } from 'app/utils';

class Form extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    inline: PropTypes.bool,
    messageOnInvalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => {},
    onSubmit: () => {},
  };

  _wasSubmit = false;

  _regex = {
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  };

  _validateInputs = {};

  constructor(props) {
    super(props);

    reactChildrenMap(props.children, null, (child) => {
      const { name, validator } = child.props;

      if (validator) {
        this._validateInputs[name] = {
          elementRef: React.createRef(),
          validator: validator,
        };
      }
    });

    this._formRef = React.createRef();
  }

  getData = () => this._getFormData();

  _getFormData = () => {
    const formData = {};

    _.forEach(
      this._formRef.current.querySelectorAll('[name]'),
      (item) => (formData[item.name.toString()] = item.value),
    );

    return formData;
  };

  _formOnChange = (e) => {
    // if (this._wasSubmit) {
    this._validate(e.target);
    // }

    this.props.onChange(e);
  };

  _formOnSubmit = (e) => {
    e.preventDefault();

    const { messageOnInvalid, onSubmit } = this.props;

    this._wasSubmit = true;

    let valid = true;
    //console.log("e.currentTarget", e.currentTarget);
    _.forEach(e.currentTarget, (input) => {
      if (!this._validate(input)) {
        valid = false;
      }
    });
    if (messageOnInvalid && !valid) {
      showNotification(
        typeof messageOnInvalid === 'string'
          ? messageOnInvalid
          : 'Form has invalid fields. Please check all fields and try again',
        'error',
      );
    }

    const errors = onSubmit({
      valid,
      event: e,
      formData: this._getFormData(),
    });

    if (errors) {
      _.forEach(errors, (message, key) => {
        const field = this._validateInputs[key];
        if (message) {
          field.elementRef.current.setError(message);
        }
      });
    }
  };

  _testValue = (target, key = 'required') => {
    let validate = true;

    switch (key) {
      case 'required':
        validate = target.value && target.value !== '';
        break;

      case 'email':
        validate = this._regex[key].test(target.value);
        break;

      case 'pattern':
        validate = this._validateInputs[target.name].validator.pattern.regex.test(target.value);
        break;

      case 'confirm':
        validate = target.value === this._validateInputs['password'].elementRef.current.state.inputValue;
        break;

      default:
        break;
    }

    return validate;
  };

  _getErrorMessage = (target, key) => {
    const validator = this._validateInputs[target.name].validator[key];

    switch (key) {
      case 'pattern':
        return validator.message;
      default:
        return validator;
    }
  };

  _validate = (target, error) => {
    const validateInput = this._validateInputs[target.name];
    const confirmInput = this._validateInputs['confirmPassword'];
    if (target.name === 'password' && confirmInput) {
      const validateConfirm = target.value === confirmInput.elementRef.current.state.inputValue;

      if (validateConfirm) {
        confirmInput.elementRef.current.setSuccess();
      } else {
        confirmInput.elementRef.current.setError(confirmInput.validator.confirm);
      }
    }

    if (validateInput) {
      let errorMessage = null;

      _.forEach(validateInput.validator, (value, key) => {
        if (!this._testValue(target, key)) {
          errorMessage = this._getErrorMessage(target, key);

          return false;
        }
      });

      if (validateInput.elementRef.current) {
        if (!errorMessage) {
          validateInput.elementRef.current.setSuccess();
        } else {
          validateInput.elementRef.current.setError(errorMessage);
        }
      }

      if (errorMessage) {
        return false;
      }
    }

    return true;
  };

  _renderChildren = () =>
    reactChildrenMap(this.props.children, null, (child, index) => {
      const validateInput = this._validateInputs[child.props.name];
      const cloneElementProps = { key: index };
      if (validateInput) {
        cloneElementProps.ref = validateInput.elementRef;
      }
      return React.cloneElement(child, cloneElementProps);
    });

  render() {
    const { className, inline } = this.props;

    return (
      <form
        ref={this._formRef}
        className={classNames('form', { inline }, className)}
        onChange={this._formOnChange}
        onSubmit={this._formOnSubmit}
      >
        {this._renderChildren()}
      </form>
    );
  }
}

export { Form };
