import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './text-scale.sass';

class TextScale extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    maxWidth: PropTypes.number,
    minScale: PropTypes.number,
    big: PropTypes.bool,
  };

  static defaultProps = {
    maxWidth: 100,
    minScale: 0.5,
  };

  _timerId = null;

  constructor(props) {
    super(props);

    this.state = { scale: 1 };

    this._elementRef = React.createRef();
    this._wrapRef = React.createRef();
  }

  componentDidMount() {
    this._setScale();

    window.addEventListener('resize', this._setScale, false);
  }
  componentWillUpdate(prevProps, prevState) {
    const scale = this._getScale();

    if (prevState.scale !== scale) {
      this._setScale(scale);
    }
  }
  componentWillUnmount() {
    if (this._timerId) {
      clearInterval(this._timerId);
    }

    window.removeEventListener('resize', this._setScale, false);
  }

  _getScale = () => {
    const { minScale } = this.props;
    const scale = this._wrapRef.current.offsetWidth / this._elementRef.current.offsetWidth;

    if (scale > 1) {
      return 1;
    }

    return scale < minScale ? minScale : scale;
  };
  _setScale = (scale = this._getScale()) => {
    this._timerId = setTimeout(() => this.setState({ scale }), 250);
  };

  render() {
    const { children, className, maxWidth, big } = this.props;

    return (
      <div
        className={classNames('text-scale', className, { big })}
        style={{ maxWidth: `${maxWidth}%` }}
        ref={this._wrapRef}
      >
        <span style={{ transform: `scale(${this.state.scale})` }} ref={this._elementRef}>
          {children}
        </span>
      </div>
    );
  }
}

export { TextScale };
