import React, { useMemo, useEffect, useState } from 'react';
import { formatEthAddress } from 'app/utils';
import { useEnsName } from 'wagmi';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from 'react-bootstrap/Spinner';


export function Address(props) {

  const ens = useEnsName({
    chainId: 'eip155:1',  // Ethereum mainnet
    address: props.address,
    enabled: Boolean(props.address),
    staleTime: 'Infinity'
  });

  const displayAddress = useMemo(() => {
    if (!props.address) {
      return "Unknown";
    } else if (ens.data) {
      return ens.data;
    } else {
      return formatEthAddress(props.address, props.short)
    }
  }, [props.address, ens.data, props.short])


  return (
    <>
      {(props.isLoading) ?
        <Spinner className={props.className} animation="border" variant="primary" size="sm"/>
      :
        <>
          {props.noTooltip ?
            <div className={props.className} style={props.style}>{displayAddress}</div>
            :
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className="text-xs">
                  {props.address}
                </Tooltip>
              }
            >
              <div className={props.className} style={props.style}>{displayAddress}</div>
            </OverlayTrigger>
          }
        </>
      }
    </>

  )
}
