import { Children, cloneElement, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import _ from 'lodash';
import axios from 'axios';

import { store } from 'app/store/reducers';

export function asset(path) {
  return `${process.env.REACT_APP_ASSETS_URL}assets/${path}`;
  // return `/assets/${path}`;
}

export function getEmoji(unicode) {
  return String.fromCodePoint(unicode);
}

export function getUserProfilePhoto(id = store.getState().user.userId) {
  return `${axios.defaults.baseURL}users/${id}/profileImage`;
}

export function getVehicleThumbnail({ make = '', model = '' }, side = 'side', full = true) {
  const hasThumbnail = make && model;
  const thumbnail = hasThumbnail
    ? `${make.trim().replace(/(\s)/g, '_')}_${model.trim().replace(/(\s|\/|\:)/g, '_')}-${side}.png`
    : 'default-car.svg';
  // console.log('thumbnail', thumbnail);
  return full ? asset(`images/${(hasThumbnail ? 'vehicles/' : '') + thumbnail}`) : thumbnail;
}

export function getVehicleSprite({ make = '', model = '' }, mobilesize = false) {
  const hasThumbnail = make && model;
  const size = mobilesize ? 'mobile' : 'desktop';
  const thumbnail = hasThumbnail
    ? `${make.trim().toLowerCase().replace(/(\s)/g, '_')}_${model.trim().toLowerCase().replace(/(\s|\/|\:)/g, '_')}-sprite-${size}.png`
    : 'default-car.svg';
  // console.log('thumbnail', thumbnail);
  return asset(`images/${(hasThumbnail ? 'sprite/' : '') + thumbnail}`);
}

export function getCarLogoImg(make = '') {
  const logoImg = make ? `${make.trim().replace(/(\s)/g, '_')}.png` : 'default-car.svg';
  return asset(`images/logos/${logoImg}`);
}

export function padNumber(val, pad = 2) {
  return new Array(pad).join('0').slice(pad * -1) + val;
}

export function reactChildrenMap(children, childProps = null, callback = () => {}) {
  return Children.map(children, (child, index) => {
    if (!child.props) {
      return child;
    }

    if (child.props.children) {
      reactChildrenMap(child.props.children, null, callback);
    } else {
      const callbackResult = callback(child, index);

      if (callbackResult) {
        return callbackResult;
      }
    }

    if (childProps) {
      return cloneElement(child, childProps);
    }

    return child;
  });
}

export function separateNumber(val = 0, separator = ' ') {
  return (val || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}
export function numberFormat(val = 0) {
  return new Intl.NumberFormat('en-us').format(val);
}
export function currencyFormat(val = 0) {
  return new Intl.NumberFormat('en-us',  {
  style: 'currency',
  currency: 'USD'})
  .format(val);
}
export function removeUnderscore(val = '', separator = ' ') {
  return val.replace(/_/g, separator);
}

export function showNotification(message, type = 'success', options = {}) {
  const notificationMessages = {
    UNA: `An unauthorized access ${getEmoji(0x1f614)}`,
    'VEH-2': `You can't buy this car, because you don't have enough money ${getEmoji(0x1f614)}`,
  };

  if (type === 'error' && (options.autoClose !== false)) {
    options.autoClose = 10000;
  }

  if (type === 'default') {
    toast(message, options);
  } else {
    toast[type](message, options);
  }
}

export function windowScrollTo(top = 0) {
  window.scrollTo({ top, behavior: 'smooth' });
}



// ---------------------------------------------------------------------------------------------------------------------



export function formatEthAddress(address, extraShortFormat) {
  let start = (extraShortFormat) ? address.substring(0,4) : address.substring(0,6);
  let end = address.slice(-5);
  return start.concat('...').concat(end);
}





// ---------------------------------------------------------------------------------------------------------------------

function createAppPropType(components, array, isRequired = false) {
  if (array) {
    return PropTypes.arrayOf((props, key, componentName, location, propFullName) =>
      checkChildren(props, key, componentName, propFullName, components, true),
    );
  }

  return (props, key, componentName) => checkChildren(props, key, componentName, null, components, false, isRequired);
}
const checkChildren = (props, key, componentName, propFullName, components, array, isRequired) => {
  const {
    type: { displayName },
  } = props[key];

  let message = `Invalid prop \`${array ? propFullName : key}\` supplied to \`${componentName}\`.`;

  if (isRequired && !props[key]) {
    return new Error(`${message} Child is required. Validation failed.`);
  }

  if (!isValidElement(props[key])) {
    return new Error(`${message} Child must be a React Component. Validation failed.`);
  }

  if (_.findIndex(components, (component) => component === displayName) === -1) {
    return new Error(`${message} Child must be one of this: <${components.join(' />, <')} />. Validation failed.`);
  }
};

export const AppPropTypes = {
  arrayOfComponents: (components) => createAppPropType(components, true),
  oneOfComponents: (components) => createAppPropType(components, false),
};
AppPropTypes.oneOfComponents.isRequired = (components) => createAppPropType(components, false, true);
