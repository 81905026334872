import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'app/components/elements';

import './model-car-fact.sass';

const ModelCarFact = ({ text }) => {
  return (
    <div className="model-car-fact">
      <div className="icons-inline">
        <Icon icon="information" white />
        <span>{text}</span>
      </div>
    </div>
  );
};

ModelCarFact.propTypes = {
  text: PropTypes.string,
};

export { ModelCarFact };
