import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CircleFlag } from 'react-circle-flags'

import "./flag.sass";

const Flag = ({ className, flag }) => (
  <CircleFlag countryCode={flag} className="flag" />
);

Flag.displayName = 'Flag';

Flag.propTypes = {
  className: PropTypes.string,
  flag: PropTypes.string.isRequired
};

export { Flag }
