import React from "react";
import PropTypes from "prop-types";

import "./progress-bar.sass";

const ProgressBar = ({ total, value }) => (
  <div className="progress-bar">
    <div style={{ width: (value / (total / 100)) + '%' }}/>
  </div>
);

ProgressBar.propTypes = {
  total: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export { ProgressBar }
