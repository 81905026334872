import React from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import './owner-car-label.sass';
import { UserAvatar } from 'app/components/user';

const OwnerCarLabel = ({ user, text, title }) => {
  return (
    <div className="owner-car-label__wrap">
      <div className="owner-car-label__avatar">
        <UserAvatar avatar={`${axios.defaults.baseURL}users/${user.userId}/profileImage`} />
      </div>
      <div className="owner-car-label__info">
        <p className="owner-car-label__text">{title}</p>
        <p className="owner-car-label__date">{text} </p>
      </div>
    </div>
  );
};

OwnerCarLabel.propTypes = {
  user: PropTypes.object,
  text: PropTypes.string,
  title: PropTypes.string,
};

export { OwnerCarLabel };
