import React from 'react';
import PropTypes from 'prop-types';

import {
  SectionTitle,
  OwnerCarLabel,
  // Icon,
} from 'app/components/elements';

import './trade-car.sass';
import { CarPreview } from 'app/components/car';
import { Icon } from 'app/components/elements';

const TradeCar = ({ car, user, price, offeredCar, textForLabel, titleForLabel }) => {
  return (
    <div className="trade-car">
      <SectionTitle white small className={`${offeredCar && 'offeredCarTitle'}`}>
        {car.make} {car.model}
        {price && (
          <span className="trade-car__price">
            + <Icon viewBox="0 0 20 20" icon="priceDollar" /> {price}
          </span>
        )}
      </SectionTitle>

      <OwnerCarLabel user={user} text={textForLabel} title={titleForLabel} />
      <CarPreview vehicleData={car} />
    </div>
  );
};

TradeCar.propTypes = {
  car: PropTypes.object,
  user: PropTypes.object,
  price: PropTypes.string,
  offeredCar: PropTypes.bool,
  textForLabel: PropTypes.string,
  titleForLabel: PropTypes.string,
};

export { TradeCar };
