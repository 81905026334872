import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { changeModals, updateModal } from "app/store/actions/modalsActions";

import { Modal } from "..";

class ModalContainer extends React.Component {
  
  componentWillUpdate({ modalsData: { modals: { length } } }) {
    document.body.classList[(length ? 'add' : 'remove')]('overflow-hidden');
  }
  
  _modalRemove = index => {
    const { changeModals, modalsData } = this.props;
    
    const newModalsData = { ...modalsData };
  
    newModalsData.modals.splice(index, 1);
    
    changeModals(newModalsData);
  };

  _renderModals = () => _.map(
    this.props.modalsData.modals,
    (modal, index) => (
      <Modal
        key={index}
        modal={modal}
        onRemove={() => this._modalRemove(index)}
      />
    )
  );
  
  render() {
    
    return (
      <div className="modal-container">
        {!!this.props.modalsData.modals.length && this._renderModals()}
      </div>
    );
  }
}

ModalContainer = connect(
  ({ modals }) => ({ modalsData: modals }),
  dispatch => ({
    changeModals: data => dispatch(changeModals(data)),
    updateModal: data => dispatch(updateModal(data))
  })
  // { changeModals }
)(ModalContainer);

export { ModalContainer }
