import axios from 'axios';
import {
  LOG_IN,
  LOG_IN_FACEBOOK,
  LOG_IN_TWITTER,
  LOG_IN_JWT,
  VERIFY_CODE,
  LOG_OUT,
  GET_USER_BALANCE,
  GET_USER_PROFILE_PHOTO,
  GET_USER,
  SET_USER,
  CREATE_ACCOUNT,
  UPDATE_USER,
  DELETE_USER_PROFILE_PHOTO
} from 'app/store/actions/userActions';

const userData = {
  auth: false,
  userId: null,
  id: null, //tmp
  email: null,
  expireDate: null,
  nickname: null, //tmp
  /*data: {
    avatar: null,
  },*/
  metaMaskKey: null,
  watchList: null,
  balance: 0,
  isSettingsOpen: false,
  garageVisibility: '',
};


export function user(state = userData, action = {}) {
  let parseJwt;
  let id;
  let newData;
  //console.log('user action!', action);
  switch (action.type) {

    case CREATE_ACCOUNT:
      return state;

    case LOG_IN:
      parseJwt = action.payload.jwt ? JSON.parse(window.atob(action.payload.jwt.split('.')[1])) : { exp: null, sub: null };
      id = action.payload.userId || parseJwt.userId;
      newData = {
        auth: (action.payload.jwt && action.payload.verified),
        userId: action.payload.userId,
        id,
        //nickname,
        email: parseJwt.sub,
        expireDate: parseJwt.exp,
        profilePhoto: getUserProfilePhoto(id),
        jwt: action.payload.jwt,
      };
      //onSuccess({ ...userData, ...action.payload, ...newData });
      return { ...state, ...newData };


    case LOG_IN_FACEBOOK:
      parseJwt = action.payload.jwt ? JSON.parse(window.atob(action.payload.jwt.split('.')[1])) : { exp: null, sub: null };

      // console.log('parsedJwt', parsedJwt);

      id = action.payload.userId || parseJwt.userId;
      newData = {
        auth: (action.payload.jwt && action.payload.verified),
        userId: action.payload.userId,
        id,
        //nickname,
        email: parseJwt.sub,
        expireDate: parseJwt.exp,
        profilePhoto: getUserProfilePhoto(id),
        jwt: action.payload.jwt,
      };
      //onSuccess({ ...userData, ...action.payload, ...newData });
      return { ...state, ...newData };

    case LOG_IN_TWITTER:
      parseJwt = action.payload.jwt ? JSON.parse(window.atob(action.payload.jwt.split('.')[1])) : { exp: null, sub: null };

      // console.log('parsedJwt', parsedJwt);

      id = action.payload.userId || parseJwt.userId;
      newData = {
        auth: (action.payload.jwt && action.payload.verified),
        userId: action.payload.userId,
        id,
        //nickname,
        email: parseJwt.sub,
        expireDate: parseJwt.exp,
        profilePhoto: getUserProfilePhoto(id),
        jwt: action.payload.jwt,
      };
      //onSuccess({ ...userData, ...action.payload, ...newData });
      return { ...state, ...newData };

    case LOG_IN_JWT:
      parseJwt = action.payload.jwtToken ? JSON.parse(window.atob(action.payload.jwtToken.split('.')[1])) : { exp: null, sub: null };
      id = action.payload.id || parseJwt.userId;
      newData = {
        auth: (action.payload.jwtToken && action.payload.status === "VERIFIED"),
        userId: action.payload.id,
        id,
        //nickname,
        email: parseJwt.sub,
        expireDate: parseJwt.exp,
        //profilePhoto: getUserProfilePhoto(id),
        jwt: action.payload.jwtToken,
      };
      //onSuccess({ ...userData, ...action.payload, ...newData });
      return { ...state, ...newData };


    case VERIFY_CODE:
      const parsedJwt = action.payload.jwt ? JSON.parse(window.atob(action.payload.jwt.split('.')[1])) : { exp: null, sub: null };
      const verifiedState = {
        auth: (action.payload.jwt && action.payload.verified),
        expireDate: parsedJwt.exp,
        jwt: action.payload.jwt,
      };
      return { ...state, ...verifiedState };


    case LOG_OUT:
      return userData;


    case GET_USER:
      action.payload.profilePhoto = getUserProfilePhoto(action.payload.id);

      if (state.userId === action.payload.id) {
        return { ...state, ...action.payload };
      }

      return { ...state };


    case GET_USER_BALANCE:
      return { ...state, ...{ balance: action.payload.balance } };


    case GET_USER_PROFILE_PHOTO:
      if (state.userId === action.meta.id) {
        return { ...state, ...{ profilePhoto: action.payload } };
      }
      return { ...state };


    case SET_USER:
      return { ...state, ...action.payload };
    //case SHOW_SETTINGS:
    //case HIDE_SETTINGS:
    //  return { ...state, isSettingsOpen: action.payload };


    case UPDATE_USER:
      //nickname and email are sent as newNickname and newEmail so we update accordingly:
      if (action.meta.newNickname) {
        action.meta.nickname = action.meta.newNickname;
        delete action.meta.newNickname;
      }
      if (action.meta.newEmail) {
        action.meta.email = action.meta.newEmail;
        delete action.meta.newEmail;
      }

      return { ...state, ...action.meta };

    case DELETE_USER_PROFILE_PHOTO:
      //console.log('removing photo');
      return { ...state, ...{ profilePhoto: null } };

    default:
      return state;
  }
}

const getUserProfilePhoto = (id = null) => (id ? `${axios.defaults.baseURL}users/${id}/profileImage` : null);
