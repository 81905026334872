import React from 'react';
import PropTypes from 'prop-types';

import './radio.sass';

import { Icon } from 'app/components/elements';

class Radio extends React.Component {
  static propTypes = {
    checkbox: PropTypes.bool,
    checked: PropTypes.bool,
    label: PropTypes.node,
    listItem: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    className: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    value: '1',
    className: '',
  };

  constructor(props) {
    super(props);

    this.state = { isChecked: !!props.checked };
  }

  _inputOnChange = ({ target: { checked } }) => {
    const { listItem, name, onChange, value } = this.props;

    if (!listItem) {
      this.setState({ isChecked: checked });
    }

    onChange({ name, value, checked });
  };

  render() {
    const { checkbox, checked, label, listItem, name, value, className } = this.props;

    return (
      <label className={`radio ${className}`}>
        <input
          type={checkbox ? 'checkbox' : 'radio'}
          name={name}
          value={value}
          checked={checked}
          onChange={this._inputOnChange}
        />

        <span>
          <Icon
            icon={
              (listItem && checked) || (!listItem && this.state.isChecked)
                ? 'checkbox-active'
                : // ? checkbox
                  //   ? 'checkbox-active'
                  //   : 'radio-active'
                  'radio'
            }
          />

          {label}
        </span>
      </label>
    );
  }
}

export { Radio };
