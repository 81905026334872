import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import './pagination.sass';

import { Button } from 'react-bootstrap';

class Pagination extends React.Component {
  static propTypes = {
    activePage: PropTypes.number,
    maxPages: PropTypes.number,
    onChange: PropTypes.func,
    perPage: PropTypes.number,
    total: PropTypes.number.isRequired,
  };

  static defaultProps = {
    activePage: 1,
    maxPages: 10,
    onChange: () => {},
    perPage: 9,
  };

  constructor(props) {
    super(props);

    this.state = { activePage: props.activePage };
  }

  componentDidUpdate(prevProps) {
    if (this.props.activePage !== prevProps.activePage) {
      this.setState({
        activePage: this.props.activePage,
      });
    }
  }

  _setPage = (page, e) => {
    if (e) {
      e.preventDefault();
    }

    if (page !== this.state.activePage) {
      this.setState({ activePage: page });

      this.props.onChange(page);
    }
  };

  _getPagination = () => {
    const {
      props: { perPage, total },
      state: { activePage },
    } = this;

    const pages = Math.ceil(total / perPage);

    const delta = 2;
    const left = activePage - delta;
    const right = activePage + delta + 1;
    const range = [];

    for (let i = 1; i <= pages; i++) {
      if (i === 1 || i === pages || (i >= left && i < right)) {
        range.push(i);
      }
    }

    let l;
    const rangeWithDots = [];
    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push(null);
        }
      }

      rangeWithDots.push(i);

      l = i;
    }

    return rangeWithDots;
  };

  _renderItems = () => {
    const range = this._getPagination();
    return _.map(range, (item, index) => (
      <li key={index} className={classNames({ active: this.state.activePage === item })}>
        {item ? (
          <a href={`?page=${item}`} onClick={(e) => this._setPage(item, e)}>
            {item}
          </a>
        ) : (
          <span>...</span>
        )}
      </li>
    ));
  };

  render() {
    const {
      props: { total, perPage },
      state: { activePage },
    } = this;

    return (
      <div className="pagination">
        <div className="pagination__prev">
          <Button variant="transparent" disabled={activePage === 1} onClick={() => this._setPage(activePage - 1)}>
            Previous
          </Button>
        </div>

        <ul className="pagination__list">{this._renderItems()}</ul>

        <div className="pagination__next">
          <Button
            variant="transparent"
            disabled={activePage === Math.ceil(total / perPage)}
            onClick={() => this._setPage(activePage + 1)}
          >
            Next
          </Button>
        </div>
      </div>
    );
  }
}

export { Pagination };
