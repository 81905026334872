import axios from 'axios';
import _ from 'lodash';

export const GET_ONGOIND_PRE_SALE = 'GET_ONGOIND_PRE_SALE';
export const RESERVATIONS_PRE_SALE_CAR = 'RESERVATIONS_PRE_SALE_CAR';
export const GET_RELEASE_ALL_RESERVATIONS_CAR = 'GET_RELEASE_ALL_RESERVATIONS_CAR';
export const GET_RELEASE_1 = 'GET_RELEASE_1';
export const GET_RELEASE_2 = 'GET_RELEASE_2';
export const GET_RELEASE_3 = 'GET_RELEASE_3';
export const GET_RELEASE_4 = 'GET_RELEASE_4';
export const GET_ALL_PRE_SALE = 'GET_ALL_PRE_SALE';

const defaultVehiclesParams = {
  size: 6,
  page: 0,
  sortField: 'model',
  sortType: 'ASC',
};

export const getPreSaleDate = async () => ({
  type: GET_ONGOIND_PRE_SALE,
  payload: await axios.get('/preSales/ongoing', {
    hideError: true,
  }),
});

export const getReleasesCars = async (type = GET_RELEASE_1, params = {}) => {
  if (params instanceof URLSearchParams) {
    _.forEach(defaultVehiclesParams, (param, key) => {
      if (!params.has(key)) {
        params.append(key, param);
      }
    });
  } else {
    params = { ...defaultVehiclesParams, ...params };
  }

  const arrLink = {
    GET_RELEASE_1: '/preSales/72fed798-1eda-4b97-9070-caaebaf4f818/availableVehicles',
    GET_RELEASE_2: '/preSales/9c2cd53a-3955-4b6e-a06d-34fc8b58ce3a/availableVehicles',
    GET_RELEASE_3: '/preSales/091d7c76-349f-4b0e-b7ee-3f9e909fcc47/availableVehicles',
    GET_RELEASE_4: '/preSales/ongoing/availableVehicles',
    GET_RELEASE_ALL_RESERVATIONS_CAR: '/preSales/reservations',
  };

  return {
    type,
    payload: arrLink[type]
      ? await axios.get(arrLink[type], {
          params,
          hideError: true,
        })
      : Promise.resolve(),
    meta: params,
  };
};

export const reservationsPreSaleCars = async (vehicleId) => ({
  type: RESERVATIONS_PRE_SALE_CAR,
  payload: await axios.post('/preSales/reservations', { vehicleId }),
});
