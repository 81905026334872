import React, { useState, useEffect } from 'react';
//import { Form } from 'app/components/form';
import { Row, Col, Button } from 'react-bootstrap';
import AuthCode from 'react-auth-code-input';

import './verify.sass';

export function Verify2FA(props) {
  //const [requires2FA, setRequires2FA] = useState(false);
  const [verificationCode, setVerificationCode] = useState();
  //const [verified, setVerified] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [invalidFormat, setInvalidFormat] = useState('');

  // const [verificationCodeInput, setVerificationCodeInput] = useState({
  //   one: null,
  //   two: null,
  //   three: null,
  //   four: null,
  //   five: null,
  //   six: null
  // });

  useEffect(() => {
    if (verificationCode) {
      setDisableButton(isNaN(verificationCode) || (verificationCode.length !== 6));
      setInvalidFormat(isNaN(verificationCode) ? 'Invalid format. Please enter only numbers.' : '');
    }
  }, [verificationCode])

  return (
    <div>
      <p>Two Factor Authentication code is required:</p>

        <AuthCode allowedCharacters='numeric' containerClassName="miniHolder" inputClassName="miniInput" onChange={(value) => setVerificationCode(value)} />
{/*      <Input
        name="verificationCode"
        type="number"
        value={verificationCode}
        className="2FAinput"
        style={{}}
        maxLength={6}
        onChange={(value) => setVerificationCode(value)}
      />*/}
      {/*<Row className="justify-content-center">
        <Col xs={"auto"}>
          <Input
            type="number"
            className="miniInput"
            maxLength={1}
            value={verificationCodeInput.one}
            onChange={(value) => {
              setVerificationCodeInput({...verificationCodeInput, one: value});

            }}
          />
        </Col>
        <Col xs={"auto"}>
          <Input
            type="number"
            className="miniInput"
            maxLength={1}
            value={verificationCodeInput.two}
            onChange={(value) => setVerificationCodeInput({...verificationCodeInput, two: value})}
          />
        </Col>
        <Col xs={"auto"}>
          <Input
            type="number"
            className="miniInput"
            maxLength={1}
            value={verificationCodeInput.three}
            onChange={(value) => setVerificationCodeInput({...verificationCodeInput, three: value})}
          />
        </Col>
        <Col xs={"auto"}>
          <Input
            type="number"
            className="miniInput"
            maxLength={1}
            value={verificationCodeInput.four}
            onChange={(value) => setVerificationCodeInput({...verificationCodeInput, four: value})}
          />
        </Col>
        <Col xs={"auto"}>
          <Input
            type="number"
            className="miniInput"
            maxLength={1}
            value={verificationCodeInput.five}
            onChange={(value) => setVerificationCodeInput({...verificationCodeInput, five: value})}
          />
        </Col>
        <Col xs={"auto"}>
          <Input
            type="number"
            className="miniInput"
            maxLength={1}
            value={verificationCodeInput.six}
            onChange={(value) => setVerificationCodeInput({...verificationCodeInput, six: value})}
          />
        </Col>
      </Row>*/}


      {(invalidFormat || props.error2FA) &&
        <p className="text-red text-sm mt-4">{invalidFormat} {props.error2FA}</p>
      }


      <Button className="my-4 block" type="submit" disabled={disableButton} onClick={() => props.verify(verificationCode)}>
        Verify
      </Button>

    </div>
  )
}
