import React, { useEffect, useState, useRef } from 'react';
import useBreakpoint from 'app/hooks/useBreakpoint';
import useWindowSize from 'app/hooks/useWindowSize';
import { isMobile } from "react-device-detect";
import { Loader } from '../../loader/loader';
import classNames from "classnames";
import { animated, useSpring } from '@react-spring/web'

import './car-preview.sass';
import { Image as ImageComponent } from '../../elements';
import { asset, getVehicleThumbnail, getVehicleSprite } from 'app/utils';

import { SpriteAnimator, useSprite } from 'react-sprite-animator'
import Spritesheet from 'react-responsive-spritesheet';

import {Row, Col} from 'react-bootstrap'

function Car360Sprite(props) {
  const [mainImageLoaded, setMainImageLoaded] = useState(false);
  const breakpoint = useBreakpoint();
  const size = useWindowSize();
  const [loadingSprite, setLoadingSprite] = useState(false);
  const [frameWidth, setFrameWidth] = useState(0);
  const [frameHeight, setFrameHeight] = useState(0);
  const [spriteSize, setSpriteSize] = useState({ width: 0, height: 0 });
  const [spriteImage, setSpriteImage] = useState();
  const [spriteFrames, setSpriteFrames] = useState(63);
  const containerRef = useRef();


  // Offsetting upwards due to excess margins around car images
  const [dynamicStyle, setDynamicStyle] = useState({});



  useEffect(() => {
    if (containerRef.current) {
      const newWidth = Math.min(containerRef.current.clientWidth, frameWidth);
      const newHeight = (newWidth * frameHeight) / frameWidth;
      setSpriteSize({ width: newWidth, height: newHeight })
    }
  }, [size, frameWidth, frameHeight, containerRef])


  //Before starting to load the sprite, we wait for mainImageLoaded to be true
  useEffect(() => {
    if (props.vehicleData.assets && mainImageLoaded) {
      setLoadingSprite(true);
      let newSprite = new Image();
      //newSprite.src = require("app/assets/sprite-desktop.png");
      newSprite.src = (isMobile) ? props.vehicleData.assets.sprite_mobile : props.vehicleData.assets.sprite_desktop;
      newSprite.onload = function(e) {
        let frames;

        if (e.target.width === 1200 || e.target.width === 600) {
          // The newer generated sprites have a width of 1200px and 64 frames
          frames = 64;
        } else {
          // The old ones had a width of 1100px and 63 frames
          frames = 63;
        }
        setSpriteFrames(frames);

        setLoadingSprite(false);
        setSpriteImage(newSprite)
        setFrameHeight(e.target.height / frames);
        setFrameWidth(e.target.width);
        props.setSpriteLoaded(true);
      };
      newSprite.onerror = newSprite.onabort = function() { setSpriteImage(null) };
    }
  }, [props.vehicleData, mainImageLoaded, isMobile])

  const animationStyles = useSpring({
    x: (!props.toolbarCollapsed && breakpoint !== 'xs' && breakpoint !== 'sm' && breakpoint !== 'md' && breakpoint !== 'lg') ? -400 : 0,
  })


  if (!props.vehicleData.assets) return null;
  return (
    <animated.div className="row"
      ref={containerRef}
      style={animationStyles}
    >
      <Col className="mx-auto keepZindex gx-0" style={{minHeight: /*spriteSize.height ? spriteSize.height * 0.7 : */100, maxWidth: 1200, zIndex: 0, overflow: 'hidden'}}>

        <div className="car-preview-offset">
          {(!props.draggerStarted) &&
            <ImageComponent
              src={props.vehicleData.assets.front_side_right ? props.vehicleData.assets.front_side_right : props.vehicleData.assets.front_side}
              webpSrc={!props.vehicleData.assets.front_side_right ? props.vehicleData.assets.front_side_webp : undefined}
              fallbackSrc={asset('images/default-car.svg')}
              onLoad={() => setMainImageLoaded(true)}
              noLazyLoad
            />
          }
          {(spriteImage) &&
            <div id="spriteContainer" className={props.draggerStarted ? 'visible' : 'hidden'}>
              <SpriteAnimator
                sprite={spriteImage.src}
                width={spriteSize.width}
                height={spriteSize.height}
                frame={props.dragger360value}
                frameCount={spriteFrames}
                wrapAfter={1}
                shouldAnimate={false}
              />
            </div>
          }
        </div>
        {/*(loadingSprite) &&
          <div style={{position: 'absolute', marginTop: (breakpoint === 'xs') ? -45 : -55, left: '50%', transform: 'translate(-50%, 0)', zIndex: 0}} className="d-flex justify-content-center pt-4">
            Loading 360 model &nbsp;
            <Loader show={true} className="loader" style={{marginTop: -2}}/>
          </div>
        */}
      </Col>
    </animated.div>
  );

}

export { Car360Sprite };
