import React from "react";
import PropTypes from "prop-types";

import { windowScrollTo } from "app/utils";

class LinkAnchor extends React.Component {
  
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    offset: PropTypes.number,
    to: PropTypes.string,
  };
  
  _onClick = e => {
    e.preventDefault();
    
    const { offset, to } = this.props;
    
    const target = document.querySelector(to);
    
    if (!target) {
      return false;
    }
  
    const rect = target.getBoundingClientRect();
    
    windowScrollTo((rect.top - (offset || 0)));
  };
  
  render() {
    const { children, className, to } = this.props;
    
    return (
      <a
        href={to}
        className={className}
        onClick={this._onClick}
      >
        {children}
      </a>
    );
  }
}

export { LinkAnchor }
