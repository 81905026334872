export const loadState = () => {
  try {
    const serializedState = localStorage ? localStorage.getItem('motobloq') : null;

    if (serializedState === null) {
      return undefined;
    } else {
      return JSON.parse(serializedState);
    }
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    if (localStorage) {
      localStorage.setItem('motobloq', serializedState);
    }
  } catch (err) {
    console.log(err);
  }
};
