// https://dev.to/aduranil/how-to-use-websockets-with-redux-a-step-by-step-guide-to-writing-understanding-connecting-socket-middleware-to-your-project-km3


export const wsConnect = (host, cb) => ({ type: 'WS_CONNECT', host, cb });

export const wsConnecting = (host) => ({ type: 'WS_CONNECTING', host });
export const wsConnected = (host) => ({ type: 'WS_CONNECTED', host });
export const wsDisconnect = (host) => ({ type: 'WS_DISCONNECT', host });
export const wsDisconnected = (host) => ({ type: 'WS_DISCONNECTED', host });

export const wsDepositPending = (host, vehicleId) => ({ type: 'WS_DEPOSIT_PENDING', host });
