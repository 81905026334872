import axios from 'axios';

export const GET_VEHICLE_LOADING = 'GET_VEHICLE_LOADING';
export const GET_VEHICLE = 'GET_VEHICLE';
export const GET_VEHICLE_HISTORY = 'GET_VEHICLE_HISTORY';
export const BUY_VEHICLE = 'BUY_VEHICLE';
export const SELL_VEHICLE = 'SELL_VEHICLE';
export const ADD_TO_AUCTION = 'ADD_TO_AUCTION';
export const SHOW_ORDER_CONGRATULATIONS = 'SHOW_ORDER_CONGRATULATIONS';
export const HIDE_ORDER_CONGRATULATIONS = 'HIDE_ORDER_CONGRATULATIONS';

export const GET_CAR_PRE_SALE_INFO = 'GET_CAR_PRE_SALE_INFO';

export const getVehicle = async ({ vehicleId = '', onError }) => {
  return {
    type: GET_VEHICLE,
    payload: await axios.get(`/vehicles/${vehicleId}`),
    meta: { onError },
  };
};

// export const getVehicleData = async ({ vehicleId = '', onError }) => {
//   return {
//     type: GET_VEHICLE,
//     payload: await axios.get(`/vehicles/${vehicleId}`),
//     meta: { onError },
//   };
// };
//
// export const getVehicle = async ({ vehicleId = '', onError }) => {
//   //getVehicleData(vehicleId, onError)
//   return (dispatch) => {
//     console.log('dispatch', dispatch);
//     dispatch(getVehicleData(vehicleId, onError));
//     return {
//       type: GET_VEHICLE_LOADING,
//     };
//   };
// };


export const getVehicleByMakeModelProduction = async ({make, model, productionNum}) => {
  //console.log('mkae modelm ,,,', make, model, productionNum, `/vehicles/${make}/${model}/${productionNum}`);
  return {
    type: GET_VEHICLE,
    payload: await axios.get(`/vehicles/${make}/${model}/${productionNum}`),
  };
};

export const buyVehicle = async (vehicleId) => ({
  type: BUY_VEHICLE,
  payload: await axios.post(`vehicles/${vehicleId}/purchases`),
});
export const sellVehicle = async (vehicleId, price) => ({
  type: SELL_VEHICLE,
  payload: await axios.post(`vehicles/${vehicleId}/auction`, { price }),
});
export const withdrawalSellVehicle = async (vehicleId) => ({
  type: SELL_VEHICLE,
  payload: await axios.delete(`vehicles/${vehicleId}/auction`),
});

export const getVehicleHistory = async (vehicleId = '') => {
  return {
    type: GET_VEHICLE_HISTORY,
    payload: await axios.all([axios.get(`/vehicles/${vehicleId}`), axios.get(`vehicles/${vehicleId}/purchases`)]),
  };
};

export const showOrderCongratulations = (vehicleId) => ({
  type: SHOW_ORDER_CONGRATULATIONS,
  payload: vehicleId,
});

export const hideOrderCongratulations = () => ({
  type: HIDE_ORDER_CONGRATULATIONS,
  payload: null,
});

// Car Pre Sale //
export const getCarPreSaleInfo = async (carId) => {
  return {
    type: GET_CAR_PRE_SALE_INFO,
    payload: await axios.get(`/preSales/vehicles/${carId}`),
  };
};
