export const CHANGE_MODALS = 'CHANGE_MODALS';
export const SHOW_MODAL = 'SHOW_MODAL';
export const UPDATE_MODAL = 'UPDATE_MODAL';

export const changeModals = data => ({
  type: CHANGE_MODALS,
  payload: data,
});

export const showModal = data => ({
  type: SHOW_MODAL,
  payload: data,
});

export const updateModal = (index, data) => ({
  type: UPDATE_MODAL,
  payload: { index, data },
});
