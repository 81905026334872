import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./loader.sass";

const Loader = ({ className, fixed, show, white, large, style }) => (
  <div
    className={classNames(
      'loader',
      {
        fixed,
        show,
        white,
        large,
      },
      (!show && 'd-none'),
      className
    )}
    style={style}
  >
    <svg
      focusable="false"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 100 100"
    >
      <circle
        cx="50%"
        cy="50%"
        r="45"
      />
    </svg>
  </div>
);

Loader.propTypes = {
  className: PropTypes.string,
  fixed: PropTypes.bool,
  show: PropTypes.bool,
  white: PropTypes.bool
};

export { Loader }
