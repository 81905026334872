import React, { useState, useMemo } from 'react';
import { Row, Col, Card, Modal, Button, Image } from 'react-bootstrap';
import moment from 'moment';

function TicketItem(props) {
  const [showModal, setShowModal] = useState(false);

  const cardStyle = useMemo(() => {
    if (props.data.eventName == "MotoBloq NASCAR Exp") {
      return {
        background: '#EF3E00',
        color: '#fff'
      }
    } else {
      return {
        background: '#FFF',
        color: '#000'
      }
    }
  }, [props.data.eventName])

  return (
    <>
      <Card onClick={() => setShowModal(true)} className="text-center hover-pointer" style={cardStyle}>
        <Card.Body style={{padding: 0}}>

          <Row style={{padding: 24}}>
            <Col xs={7}>
              <Image src={require('app/assets/motobloq-wordmark.png')} />
            </Col>
            <Col xs={5} style={{textAlign: 'right'}}>
              <div className="text-xs text-blue">Date</div>
              <div className="text-xs">{moment(props.data.eventDateTime).format('MMM Do, YYYY')}</div>
            </Col>
          </Row>

          <Image src={props.data.eventImageUrl}/>

          <div style={{padding: 24}}>
            <h4 className="text-xbold" style={{color: 'inherit'}}>{props.data.eventName}</h4>

            <div className="my-3 text-sm" style={{color: 'inherit'}}>{props.data.eventLocation}</div>

            <Image rounded src={props.data.qrCodeBase64} width={200} />

            {(props.data.ticketId) &&
              <div className="mt-3 text-xs text-bold" style={{color: '#000'}}><span className="text-muted">UID:</span> {props.data.ticketId}</div>
            }
          </div>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" dialogClassName="100w" centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.data.eventName}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Image rounded src={props.data.qrCodeBase64} width={300}/>
          {(props.data.ticketId) &&
            <div className="mt-3 text-xs text-bold text-center" style={{color: '#000'}}>UID: {props.data.ticketId}</div>
          }
        </Modal.Body>

        <Modal.Footer>
          <Button variant="dark">Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export { TicketItem };
