import axios from 'axios';
import _ from 'lodash';

export const GET_ALL_VEHICLES = 'GET_ALL_VEHICLES';

export const GET_NEW_SHOWROOM = 'GET_NEW_SHOWROOM';
export const GET_NEWEST = 'GET_NEWEST';
export const GET_TRADE = 'GET_TRADE';
export const GET_FEATURED = 'GET_FEATURED';
export const GET_MARKETPLACE = 'GET_MARKETPLACE';
export const GET_RESERVED = 'GET_RESERVED';

export const getAllVehiclesList = (params) => getVehiclesData(GET_ALL_VEHICLES, params);

export const getShowroomList = (params = { inShowroom: true }) => getVehiclesData(GET_NEW_SHOWROOM, params);

const defaultVehiclesParams = {
  size: 9,
  page: 0,
  sortField: 'model,make',
  sortType: 'ASC',
};

export const getVehiclesData = async (type, params = {}, watchList = false) => {

  if (params instanceof URLSearchParams) {
    // User client went on direct url with params preset already
    _.forEach(defaultVehiclesParams, (param, key) => {
      if (!params.has(key)) {
        params.append(key, param);
      }
    });
  } else {
    switch (type) {
      case GET_ALL_VEHICLES:
        params.inShowroom = true;
        break;
      case GET_NEW_SHOWROOM:
        params.inShowroom = true;
        params.sortField = 'releaseDate,model,make'
        params.sortType = 'DESC'
        break;
      case GET_FEATURED:
        params.featured = true;
        params.inAuction = false;
        params.inShowroom = true;
        break;
      case GET_MARKETPLACE:
        params.inAuction = true;
        break;
      default:
        params.inShowroom = true;
    }

    params = { ...defaultVehiclesParams, ...params };
  }

  return {
    type,
    payload: await axios.get('/vehicles', { params }),
    meta: params,
  };
};
