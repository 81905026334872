import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';



// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Mousewheel, Navigation } from "swiper";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

import './car-slides.sass';


import classNames from 'classnames';
import useWindowSize from '../../hooks/useWindowSize'
import { CartCarItem } from 'app/components';
import { isMobile } from 'react-device-detect';




function CarSlides(props) {
  const [renderSlider, setRenderSlider] = useState(false);
  const [numSlides, setNumSlides] = useState(3);
  const [spaceBetween, setSpaceBetween] = useState(24);
  //const [slidesOffset, setSlidesOffset] = useState(0.3);

  const size = useWindowSize();


  useEffect(() => {
    if (size.width) {
      if (size.width <= 576) {
        setNumSlides(1);
        setSpaceBetween(24);
        //setSlidesOffset(0.3);
      } else if (size.width <= 1200) {
        setNumSlides(2);
        setSpaceBetween(24);
        //setSlidesOffset(0);
      } else {
        setNumSlides(3);
        setSpaceBetween(24);
        //setSlidesOffset(0.5);
      }
      setRenderSlider(true);
    }
  }, [size])


  if (renderSlider) {
    return (
      <div className={"mb-5 container"}>
        <div className="my-4">
          <div className={"d-inline-flex align-items-end"}>
            <h5 className="me-3">{props.title}</h5>
            <Link to={"/showroom/"+props.tabsName} className="text-sm text-bold">View All ({props.data.totalElements})</Link>
          </div>
          <br/>
          <p className={"text-muted text-sm"}>{props.description}</p>
        </div>
        <Swiper
          initialSlide={0}
          spaceBetween={spaceBetween}
          shortSwipes={false}
          threshold={10}
          mousewheel={{
            forceToAxis: true,
            thresholdDelta: 10,
          }}
          slidesPerView={numSlides + 0.5}
          slidesPerGroup={Math.max(1, numSlides-1)}
          navigation={!isMobile}
          centeredSlides={true}
          centeredSlidesBounds={!isMobile}
          modules={[Mousewheel, Navigation]}
          className={classNames("mySwiper navigation-light", {ignoreContainerPadding: isMobile})}>

          {props.data.content.map((car, index) => {
            return (
              <SwiperSlide key={index}>
                <CartCarItem data={car} addBG={true} category={props.tabsName}/>
              </SwiperSlide>
            )
          })}

        </Swiper>
      </div>
    )
  } else {
    return null;
  }

}

export { CarSlides };
