import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./user-header-cars.sass";

import { CarPreview } from "app/components/car";

import axios from 'axios';

import { getVehicle } from 'app/store/actions/vehicleActions';

function UserHeaderCars(props) {

  const [car1data, setCar1data] = useState();
  const [car2data, setCar2data] = useState();
  const [displayRandom1, setDisplayRandom1] = useState(false);
  const [displayRandom2, setDisplayRandom2] = useState(false);


  useEffect(() => {
    const doAsyncFn = async () => {
      try {
        const response = await axios.get('/vehicles', { params: { ownerId : props.userID, size: 2, page: 0 } });
        // This response only has side car view
        // To get front-side view of cars we must fetch the car specifically.
        if (response.content.length > 0) {
          const responseCar1 = await getVehicle({ vehicleId: response.content[0].id });
          setCar1data(responseCar1.payload);
        } else {
          setDisplayRandom1(true);
        }
        if (response.content.length > 1) {
          const responseCar2 = await getVehicle({ vehicleId: response.content[1].id });
          setCar2data(responseCar2.payload);
        } else {
          setDisplayRandom2(true);
        }
      } catch (e) {
        console.error(e);
      }
    }
    if (props.userID) doAsyncFn();
  }, [props.userID]);




  return (
    <section
      className={classNames(
      'user-header-cars',
    )}
    >
      <div className="container d-flex align-items-bottom p-0" style={{ minHeight: 200 }}>

        {(props.twoCars) ?
          <div className="row align-items-end gx-0" style={{paddingTop: 60}}>
            <div className="col-6">
              <CarPreview vehicleData={car1data} showRandom={displayRandom1} style={{position: 'relative', minWidth: '150%', right: '20%', minHeight: 200}}/>
            </div>
            <div className="col-6">
              <CarPreview vehicleData={car2data} showRandom={displayRandom2} style={{position: 'relative', minWidth: '150%', right: '10%', minHeight: 200}}/>
            </div>
          </div>
        :
          <CarPreview vehicleData={car1data} showRandom={displayRandom1} style={{minHeight: 200}}/>
        }

      </div>
    </section>
  );

}


UserHeaderCars.propTypes = {
  userID: PropTypes.string,
  twoCars: PropTypes.bool
};

export { UserHeaderCars }
