import { createBrowserHistory } from 'history';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import promiseMiddleware from 'redux-promise';
//import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createRouterReducer, createRouterMiddleware } from '@lagunovsky/redux-react-router';

import { loadState, saveState } from '../local-storage';


import wsMiddleware from '../middleware/wsMiddleware';
import { redirectMiddleware } from '../middleware/middleware';


import { config } from './configReducer';
import { modals } from './modalsReducer';
import { user } from './userReducer';
import { vehicle } from './vehicleReducer';
import { vehicles } from './vehiclesReducer';
import { preSale } from './preSaleReducer';
import { garage } from './garageReducer';
import { trade } from './tradeReducer';
import { websocket } from './wsReducer';

const persistedState = loadState();
const development = process.env.NODE_ENV === 'development';

const createRootReducer = (history) =>
  combineReducers({
    router: createRouterReducer(history),
    config,
    modals,
    user,
    vehicle,
    vehicles,
    preSale,
    garage,
    trade,
    websocket,
  });

const history = createBrowserHistory();

const composeEnhancers =
  development && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const store = createStore(
  createRootReducer(history),
  persistedState,
  composeEnhancers(applyMiddleware(createRouterMiddleware(history), promiseMiddleware, redirectMiddleware, thunk, wsMiddleware)),
);

store.subscribe(() =>
  saveState({
    config: store.getState().config,
    user: store.getState().user,
  }),
);

export { store, history };
