import { GET_TRADE_USERS_CARS, GET_TRADE_CAR, CLEAR_TRADE_CAR } from 'app/store/actions/tradeActions';

const tradeData = {
  error: false,
  tradeUsersCars: {
    title: 'Trade',
    tabsName: 'trade',
    items: [
      {
        id: 'b6d2883a-f2eb-45c7-8735-87892f058fb4',
        make: 'Bentley ',
        model: 'EXP_100',
        owner: 'Cody Fisher',
        ownerId: '7',
        year: 1969,
        countryOfOrigin: 'USA',
        countryCode: 'US',
        description: 'Mustang',
        engine: '4.9 L Boss V8 4-barrel',
        horsePower: 290,
        maxSpeedPerMph: 128,
        productionPerYear: 30000,
        color: 'RED',
        type: 'MUSCLE',
        transferabilityStatus: 'RESERVABLE',
        isAvailable: true,
        auction: true,
        estimatedSalePrice: 157,
      },
      {
        id: '1',
        make: 'Jaguar',
        model: 'C-X75',
        owner: 'izabellarodrigues',
        ownerId: '3',
        year: 1997,
        countryOfOrigin: 'England',
        countryCode: 'GB',
        description:
          "The McLaren F1 was conceived by Gordon Murray to be the ultimate roadcar and by all accounts he succeeded, having been acclaimed the 'greatest car ever created' by multiple sources. On March 31, 1998 a prototype F1 (XP5) set the Guinness World Record for fastest production car at 240.1 mph which it held until 2005.  The McLaren still holds the record for the fastest naturally  aspirated production car.  An F1 also won LeMans outright in 1995.  Of the 106 cars produced from 1992-98 only 64 were road versions of which a mere 7 were federalize for the USA.  Current values for a US spec F1 road car are in excess of $15,000,000. USD.  Did you know the McLaren F1 has 3 seats with the driver in the center and slightly ahead of the passengers?",
        engine: '6.1 L V12',
        horsePower: 602,
        maxSpeedPerMph: 240.1,
        productionPerYear: 11,
        color: 'ORANGE',
        type: 'SUPERCAR',
        transferabilityStatus: 'RESERVABLE',
        estimatedSalePrice: 157,
        isAvailable: true,
      },
      {
        id: '2',
        make: 'Subaru',
        model: 'WRX_STI',
        owner: 'Dianne Russell',
        ownerId: '4',
        year: 1997,
        countryOfOrigin: 'England',
        countryCode: 'GB',
        description:
          "The McLaren F1 was conceived by Gordon Murray to be the ultimate roadcar and by all accounts he succeeded, having been acclaimed the 'greatest car ever created' by multiple sources. On March 31, 1998 a prototype F1 (XP5) set the Guinness World Record for fastest production car at 240.1 mph which it held until 2005.  The McLaren still holds the record for the fastest naturally  aspirated production car.  An F1 also won LeMans outright in 1995.  Of the 106 cars produced from 1992-98 only 64 were road versions of which a mere 7 were federalize for the USA.  Current values for a US spec F1 road car are in excess of $15,000,000. USD.  Did you know the McLaren F1 has 3 seats with the driver in the center and slightly ahead of the passengers?",
        engine: '6.1 L V12',
        horsePower: 602,
        maxSpeedPerMph: 240.1,
        productionPerYear: 11,
        color: 'ORANGE',
        type: 'SUPERCAR',
        transferabilityStatus: 'RESERVABLE',
        estimatedSalePrice: 157,
        isAvailable: true,
      },
    ],
    badge: 3,
    tabsTemplate: 'carTrade',
    actionType: GET_TRADE_USERS_CARS,
  },

  tradeCar: {
    id: '2',
    make: 'Subaru',
    model: 'WRX_STI',
    userId: '8',
    ownerName: 'izabellarodrigues',
    year: 1997,
    countryOfOrigin: 'England',
    countryCode: 'GB',
    description:
      "The McLaren F1 was conceived by Gordon Murray to be the ultimate roadcar and by all accounts he succeeded, having been acclaimed the 'greatest car ever created' by multiple sources. On March 31, 1998 a prototype F1 (XP5) set the Guinness World Record for fastest production car at 240.1 mph which it held until 2005.  The McLaren still holds the record for the fastest naturally  aspirated production car.  An F1 also won LeMans outright in 1995.  Of the 106 cars produced from 1992-98 only 64 were road versions of which a mere 7 were federalize for the USA.  Current values for a US spec F1 road car are in excess of $15,000,000. USD.  Did you know the McLaren F1 has 3 seats with the driver in the center and slightly ahead of the passengers?",
    engine: '6.1 L V12',
    horsePower: 602,
    maxSpeedPerMph: 240.1,
    productionPerYear: 11,
    color: 'ORANGE',
    type: 'SUPERCAR',
    transferabilityStatus: 'RESERVABLE',
    estimatedSalePrice: 157,
    isAvailable: true,
  },
  tradeUserCar: {
    userId: '8',
    userName: 'izabellarodrigues',
  },
};

export function trade(state = tradeData, action = {}) {
  switch (action.type) {
    case GET_TRADE_USERS_CARS:
      return {
        ...state,
        tradeUsersCars: {
          ...state.tradeUsersCars,
          items: action.payload.content,
          badge: action.payload.totalElements,
        },
      };

    case GET_TRADE_CAR:
      return {
        ...state,
        tradeUserCar: {
          ...state.tradeUserCar,
          ...action.payload,
        },
      };

    case CLEAR_TRADE_CAR:
      return {
        ...state,
        tradeUserCar: {
          userId: '8',
          userName: 'izabellarodrigues',
        },
      };

    default:
      return state;
  }
}
