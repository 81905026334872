import React from 'react';
import { Route, Routes, BrowserRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import { padNumber } from 'app/utils';

// import RequireAuth from './RequireAuth';
// import RequireNoAuth from './RequireNoAuth';

import { RequireAuth, RequireNoAuth, RequireAdmin } from './';
//import { Footer, Header, OrderCongratulations, ScrollToTop } from 'app/containers';
import Layout from 'app/containers/layout';
import Toasts from 'app/containers/toasts';

import { useGoogleAnalytics } from 'app/hooks/useGoogleAnalytics'

import { useSelector } from 'react-redux'

import {
  CreateAccount,
  ConfirmRegistraion,
  ConfirmPaypalEmail,
  FaqPage,
  PressPage,
  ForgotPassword,
  Garage,
  Home,
  Login,
  ModelOverview,
  ModelOverviewProvenance,
  NotFound,
  OrderConfirmation,
  CongratulationsOrder,
  PrivacyPolicyPage,
  ResetPassword,
  Showroom,
  SupportedCurrenciesPage,
  SupportPage,
  TermsAndConditionsPage,
  UserGarage,
  Trade,
  TradeOffer,
  RedeemConfirm,
  RedeemCongratulations,
  Settings,
  PasswordConfirmation,
  ModelAR,
  ModelViewer,
  Wallet,
  ListForSale,
  ShortURL,
  VerifyTicket,
  Bridge,
  AdminPanel,
  BridgeFaq,
  OAuth2RedirectHandler
} from './lazy';

export const ROUTES = {

  home: '/',

  adminPanel: '/admin-panel',
  adminPanelTab: '/admin-panel/:tab',

  showroom: '/showroom',
  showroomSearch: '/showroom/:tabsName',

  thankRegistering: '/thank-registering',
  preSale: '/pre-sale',

  createAccount: '/create-account',
  confirmRegistration: '/confirmRegistration',
  confirmPaypalEmail: '/confirm-paypal-email',

  //confirmRegistration: '/confirmRegistration/:userId/:code',
  forgotPassword: '/forgot-password',
  passwordConfirmation: '/profileRequestAccept/:code',
  login: '/login',
  resetPassword: '/reset-password/:code',

  settings: '/settings',
  wallet: '/wallet',

  garage: '/garage',
  verifyTicket: '/verify-ticket',
  garageTabs: '/garage/:tabsName/',
  garageTabsPagination: '/garage/:tabsName/page/:pageIndex',

  bridge: '/bridge/:id',

  listForSale: '/sell/:id',
  modelOverview: '/model/:id',
  modelOverviewNamedRouteProdNum: '/model/:make/:model/:productionNum',
  modelOverviewNamedRoute: '/model/:make/:model',

  modelAR: '/modelAR/:id',
  modelViewer: '/model-viewer/:id',
  modelViewerNamedRouteProdNum: '/model-viewer/:make/:model/:productionNum',
  modelOverviewProvenance: '/model/:id/provenance',
  order: '/order/:vehicleId',
  orderCongratulations: '/order/:vehicleId/congratulations',



  shortURL: '/s/:shortUrl',
  userGarage: '/user-garage/:id',

  trade: '/trade/:vehicleId/',
  tradeTo: '/trade/:vehicleId/to/:selectTradeCarId',
  tradeOffer: '/trade-offer',

  redeemCongratulations: '/redeem/:campaign/congratulations',
  redeemConfirm: '/redeem/:campaign',

  pressPage: '/press',
  faqPage: '/faq',
  bridgeFaq: 'bridge-faq',
  privacyPolicyPage: '/privacy-policy',
  supportPage: '/support',
  supportedCurrenciesPage: '/supported-currencies',
  termsAndConditionsPage: '/terms-and-conditions',


  privatesale: '/privatesale/:privateSaleCode',

  oauthFacebook: '/oauth2/authorization/facebook',

  notFoundPage: '/404'
};

export function route(key, variables = {}, vehicle) {
  let route = ROUTES[key] || '';

  _.forEach(variables, (val, key) => {
    route = route.replace(new RegExp(`(:${key}\\??)`, 'g'), val);
  });

  route = route.replace(/(:\w+\?)/g, '');

  if (/(\/)$/g.test(route)) {
    route = route.slice(0, -1);
  }

  return route;
}



function AppRouter() {
  const user = useSelector((state) => state.user);
  useGoogleAnalytics();

  return (
      <Routes>

        {(false && process.env.REACT_APP_ENV === 'dev' && !user.auth) ?
          <Route path={"*"} element={<Login />} />
        :
          <Route element={<Toasts/>}>
            <Route element={<Layout/>}>

              <Route index path={ROUTES.home} element={<Home/>} />

              <Route path={ROUTES.showroom} element={<Showroom/>} />
              <Route path={ROUTES.showroomSearch} element={<Showroom/>} />

              <Route path={ROUTES.modelOverview} element={<ModelOverview/>} />
              <Route path={ROUTES.modelOverviewNamedRouteProdNum} element={<ModelOverview/>} />
              <Route path={ROUTES.modelOverviewNamedRoute} element={<ModelOverview/>} />

              <Route path={ROUTES.modelOverviewProvenance} element={<RequireAuth><ModelOverviewProvenance/></RequireAuth>} />


              <Route path={ROUTES.pressPage} element={<PressPage/>} />
              <Route path={ROUTES.faqPage} element={<FaqPage/>} />
              <Route path={ROUTES.bridgeFaq} element={<BridgeFaq/>} />
              <Route path={ROUTES.privacyPolicyPage} element={<PrivacyPolicyPage/>} />
              <Route path={ROUTES.supportPage} element={<SupportPage/>} />
              <Route path={ROUTES.supportedCurrenciesPage} element={<SupportedCurrenciesPage/>} />
              <Route path={ROUTES.termsAndConditionsPage} element={<TermsAndConditionsPage/>} />




              <Route path={ROUTES.login} element={<Login/>} />
              <Route path={ROUTES.createAccount} element={<RequireNoAuth><CreateAccount/></RequireNoAuth>} />
              <Route path={ROUTES.confirmRegistration} element={<RequireNoAuth><ConfirmRegistraion/></RequireNoAuth>} />
              <Route path={ROUTES.forgotPassword} element={<RequireNoAuth><ForgotPassword/></RequireNoAuth>} />
              <Route path={ROUTES.resetPassword} element={<RequireNoAuth><ResetPassword/></RequireNoAuth>} />


              <Route path={ROUTES.thankRegistering} element={Home} />

              <Route path={ROUTES.settings+'/*'} element={<RequireAuth><Settings/></RequireAuth>} />
              <Route path={ROUTES.passwordConfirmation} element={<RequireAuth><PasswordConfirmation/></RequireAuth>} />
              <Route path={ROUTES.wallet} element={<RequireAuth><Wallet/></RequireAuth>} />


              <Route path={ROUTES.garageTabsPagination} element={<RequireAuth><Garage/></RequireAuth>} />
              <Route path={ROUTES.garageTabs} element={<RequireAuth><Garage/></RequireAuth>} />
              <Route path={ROUTES.garage} element={<RequireAuth><Garage/></RequireAuth>} />

              <Route path={ROUTES.userGarage} element={<UserGarage/>} />

              <Route path={ROUTES.bridge} element={<RequireAuth><Bridge/></RequireAuth>} />

              <Route path={ROUTES.listForSale} element={<RequireAuth><ListForSale/></RequireAuth>} />
              <Route path={ROUTES.orderCongratulations} element={<RequireAuth><CongratulationsOrder/></RequireAuth>} />
              <Route path={ROUTES.order} element={<RequireAuth><OrderConfirmation/></RequireAuth>} />
              <Route path={ROUTES.confirmPaypalEmail} element={<RequireAuth><ConfirmPaypalEmail/></RequireAuth>} />


              <Route path={ROUTES.tradeTo} element={<RequireAuth><Trade/></RequireAuth>} />
              <Route path={ROUTES.trade} element={<RequireAuth><Trade/></RequireAuth>} />
              <Route path={ROUTES.tradeOffer} element={<RequireAuth><TradeOffer/></RequireAuth>} />


              <Route index path={ROUTES.adminPanel} element={<RequireAdmin><AdminPanel/></RequireAdmin>} />
              <Route index path={ROUTES.adminPanelTab} element={<RequireAdmin><AdminPanel/></RequireAdmin>} />

              // 1st step to redeem user does not need to be logged in
              <Route path={ROUTES.redeemConfirm} element={<RedeemConfirm/>} />
              // 2nd step user will be forced to login. Request to redeem car is automatically sent to BE
              <Route path={ROUTES.redeemCongratulations} element={<RequireAuth><RedeemCongratulations/></RequireAuth>} />


              <Route path={ROUTES.oauthFacebook} element={<OAuth2RedirectHandler/>} />

              <Route path={ROUTES.shortURL} element={<ShortURL/>} />

              <Route path={ROUTES.notFoundPage} element={<NotFound/>} />

              <Route path="*" element={<NotFound/>} />

            </Route>




            {/* HERE ARE ROUTES WHERE WE DON'T WANT HEADER AND FOOTER */}
            <Route path={ROUTES.verifyTicket} element={<VerifyTicket/>} />
            <Route path={ROUTES.modelAR} element={<RequireAuth><ModelAR/></RequireAuth>} />
            <Route path={ROUTES.modelViewer} element={<ModelViewer/>} />
            <Route path={ROUTES.modelViewerNamedRouteProdNum} element={<ModelViewer/>} />


          </Route>
        }
      </Routes>
  );
};


export { AppRouter };
