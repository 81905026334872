import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Loader } from 'app/components';
import { useSelector } from 'react-redux'


function Toasts(props) {
  const showLoader = useSelector(state => state.config.showLoader);
  return (
    <>
      <Outlet />
      <ToastContainer position="bottom-left" autoClose={5000} closeOnClick={false} pauseOnVisibilityChange draggable pauseOnHover theme="light" />
      <Loader fixed show={showLoader} />
    </>
  );
}


export default Toasts;
