const websocketInitialState = {
  wsConnected: false,
  wsHost: null
};

export function websocket(state = websocketInitialState, action = {}) {
  //console.log('WEBSOCKET ACTION!!', action);
  switch (action.type) {
    case 'WS_CONNECTED':
      return { ...state, wsConnected: true, wsHost: action.host };
    case 'WS_DISCONNECTED':
      return { ...state, wsConnected: false, wsHost: null };
    default:
      return state;
  }
};
