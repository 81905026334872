import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import './input.sass';

import { Icon } from 'app/components/elements';

class Input extends React.Component {
  static propTypes = {
    buttons: PropTypes.array,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onClear: PropTypes.func,
    onChange: PropTypes.func,
    password: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.oneOf(['email', 'password', 'text', 'number']),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    onClear: () => {},
    onChange: () => {},
    value: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      notEmpty: props.value,
      inputValue: props.value,
      showPassword: false,
      errorShow: false,
      errorMessage: '',
    };

    if (props.type === 'password') {
      this.state.showPassword = false;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ inputValue: this.props.value, notEmpty: true });
    }
  }

  setError = (message = null) => {
    const { errorMessage } = this.state;

    if (message !== errorMessage && errorMessage !== '') {
      this.setState({
        hasSuccess: false,
        hasError: true,
        errorShow: false,
      });

      setTimeout(
        () =>
          this.setState({
            errorShow: true,
            errorMessage: message,
          }),
        150,
      );
    } else {
      this.setState({
        hasSuccess: false,
        hasError: true,
        errorShow: true,
        errorMessage: message,
      });
    }
  };
  setSuccess = () => {
    this.setState({
      hasSuccess: true,
      hasError: false,
      errorShow: false,
      errorMessage: '',
    });
  };

  _inputOnChange = ({ target: { value } }, onChange) => {
    const { name, placeholder } = this.props;

    if (name === 'number' && value && !/^(\d+)$/g.test(value)) {
      return;
    }

    if (name === 'price' && value && (/^00/g.test(value) || !/^[0-9]+[.,]?[0-9]*$/g.test(value))) {
      return;
    }

    this.setState({
      notEmpty: value && placeholder,
      inputValue: value,
    });

    onChange(value);
  };

  _showPasswordOnClick = () => {
    if (this.props.disabled) {
      return;
    }

    this.setState({ showPassword: !this.state.showPassword });
  };

  _inputClearOnClick = () => {
    const { disabled, name, onClear } = this.props;

    if (disabled) {
      return;
    }

    this.setState({
      notEmpty: false,
      inputValue: '',
      showPassword: false,
    });

    onClear(name);
  };

  render() {
    const {
      props: { buttons, className, disabled, name, password, placeholder, onChange, displayOnly, maxLength, large },
      state: {
        //  errorMessage, errorShow,
        hasError,
        hasSuccess,
        inputValue,
        notEmpty,
        showPassword,
      },
    } = this;

    return (
      <label
        className={classNames(
          'input-container',
          {
            success: hasSuccess,
            error: hasError,
            disabled,
          },
          className,
        )}
        style={this.props.style}
      >
        <div className="input-wrap d-flex align-items-end">

          <input
            type={password && !showPassword ? 'password' : 'text'}
            className={classNames('input', {
              'no-placeholder': !placeholder,
              'not-empty': notEmpty,
              'large text-bold': large,
            })}
            name={name}
            value={inputValue || ''}
            autoComplete="off"
            onChange={(e) => this._inputOnChange(e, onChange)}
            disabled={(disabled || displayOnly)}
            maxLength={maxLength}
          />


          {placeholder && <span className="input__placeholder">{placeholder}</span>}

          {name === 'price' && <span style={{position: 'absolute', right: 0, bottom: 0}} className={classNames('input__placeholder-suffix text-bold', {"large": large})}>$USD</span>}


          <div className="input__buttons">
            {(hasSuccess || hasError) && <div className="input__status-dot" />}
            {(password && !displayOnly) && (
              <button
                type="button"
                className={classNames('input__show-password', { active: showPassword })}
                onClick={this._showPasswordOnClick}
                disabled={disabled}
                tabIndex="-1"
              >
                <Icon icon="view" />
              </button>
            )}

            {(notEmpty && !displayOnly && hasSuccess) && (
              <button
                type="button"
                className="input__clear"
                onClick={this._inputClearOnClick}
                disabled={disabled}
                tabIndex="-1"
              >
                <Icon icon="clear" />
              </button>
            )}

            {buttons &&
              _.map(buttons, (button, index) =>
                React.cloneElement(button, {
                  key: index,
                  tabIndex: -1,
                  disabled,
                }),
              )}

            {(name === 'search') &&
              (<button type="submit" ><img src={require("../../../assets/icon-search.png")} width={30} /></button>)
            }
          </div>
        </div>

        {/* <div className="input__error">
          <div className={classNames('input__error__message', { show: errorShow })}>{errorMessage}</div>
        </div> */}
      </label>
    );
  }
}

export { Input };
