import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { showNotification } from "app/utils";

class CopyToClipboard extends React.Component {
  
  static propTypes = {
    children: PropTypes.node.isRequired,
    copy: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    linkStyle: PropTypes.bool,
  };
  
  _elementOnClick = () => {
    const { copy } = this.props;

    if (!copy) {
      return;
    }
  
    const fakeElem = document.createElement('textarea');
    fakeElem.style.position = 'absolute';
    fakeElem.style.left = fakeElem.style.top = '-9999px';
    fakeElem.style.fontSize = '12pt';
    fakeElem.style.border = fakeElem.style.padding = fakeElem.style.margin = '0';
    fakeElem.value = copy;
  
    document.body.appendChild(fakeElem);
  
    fakeElem.select();
  
    document.execCommand('Copy');
    
    fakeElem.remove();

    showNotification('Copied!', 'info');
  };
  
  render() {
    const { children, linkStyle} = this.props;
  
    return (
      <span className={classNames({ 'link': linkStyle })} onClick={this._elementOnClick}>
        {children}
      </span>
    );
  }
}

export { CopyToClipboard }
