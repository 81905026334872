import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

function LimitedContainer(props) {
  return (
    <Container className={props.className} style={{display: 'flex', flex: 1}}>
      <Row className="justify-content-center align-self-center" style={{flex: 1}}>
        <Col style={{maxWidth: props.maxWidth ? props.maxWidth : 800}}>
          { props.children }
        </Col>
      </Row>
    </Container>
  )
}

export { LimitedContainer }
