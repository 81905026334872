import React from 'react';


//import { ConnectedRouter } from 'connected-react-router';
// import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { store, history } from 'app/store/reducers';

import { BrowserRouter } from 'react-router-dom';

//import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';

import { windowScrollTo } from 'app/utils';
import { AppRouter } from 'app/routes';


import { QueryClient, QueryClientProvider } from '@tanstack/react-query';


import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider, lightTheme } from '@rainbow-me/rainbowkit';
import { publicProvider } from 'wagmi/providers/public';


import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet, goerli, sepolia } from "wagmi/chains";



// - - - - - - Rainbow Kit - - - - - - //

const chainsToUse = (process.env.REACT_APP_ENV == "prod") ? [ mainnet ] : [ goerli, sepolia ];

const { chains, provider, webSocketProvider } = configureChains(
  chainsToUse,
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'MotoBloq',
  projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
  chains,
});

const wagmiConfig = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider,
});




const queryClient = new QueryClient();



function AppProviders() {
  return (
    <>
      <WagmiConfig client={wagmiConfig}>
        <RainbowKitProvider chains={chains} theme={lightTheme({
            accentColor: '#00AFFA',
        })}>
          <Provider store={store}>
            {/*<ReduxRouter history={history} store={store} children={<AppRouter />} />*/}
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <AppRouter />
              </BrowserRouter>
            </QueryClientProvider>
          </Provider>
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );

}

export default AppProviders;
