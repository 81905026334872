import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap'

import '../modal/modal.sass';

const modalRoot = document.getElementById('modal-root');

class ModalSimple extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    const { modalClose, showCloseIcon, title, children, show, big, small, message, className } = this.props;
    return ReactDOM.createPortal(
      <div>
        <Modal
          className="modal"
          show={show}
          onHide={modalClose}
        >

              <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>

              <Modal.Body>{children}</Modal.Body>

              {/* {(footer && <div className="modal__footer">{footer}</div>)} */}

        </Modal>
      </div>,
      this.el,
    );
  }
}

ModalSimple.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  modalClose: PropTypes.func,
  showCloseIcon: PropTypes.bool,
  className: PropTypes.string,
  big: PropTypes.bool,
  small: PropTypes.bool,
  message: PropTypes.bool,
};

export { ModalSimple };
