import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { numberFormat } from 'app/utils';
import { ProgressBar, Icon } from 'app/components/elements';
import classNames from "classnames";

import { timeToPretty } from '../../auctionUtils'

import "./attributes.sass";

function Attributes(props) {

  const [formattedAttributes, setFormattedAttributes] = useState([]);

  useEffect(() => {
    let formatted = [];
    props.items.forEach((item, i) => {

      switch (item) {

        case 'auctionTimeRemaining' :
          formatted.push({
            title: 'Time remaining',
            value: (
              <div className="icons-inline">
                <span>{timeToPretty(props.vehicleData.auctionDetails.timeLeft)}</span>
              </div>
            ),
          })
          break;
        case 'price' :
          formatted.push({
            title: 'Price',
            value: (
              <div className="icons-inline">
                <span>$</span>
                <span>{numberFormat(props.vehicleData.sellingPrice)}</span>
              </div>
            ),
          })
          break;
        case 'value' :
          formatted.push({
            title: 'Value',
            value: (
              <div className="icons-inline">
                <span>$</span>
                <span>{numberFormat(props.vehicleData.value)}</span>
              </div>
            ),
          })
          break;
        case 'productionNum' :
          formatted.push({
            title: 'Production',
            value: (
              <>
                #{props.vehicleData.productionNumber}
              </>
            ),
          })
          break;
        case 'remaining' :
          formatted.push({
            title: 'Remaining',
            value: (
              <div>
                <div className="text-xs">
                  {numberFormat(props.vehicleData.totalQty - props.vehicleData.circulation + (props.vehicleData.auctions ? props.vehicleData.auctions.length : 0))}
                  <span className="production-total">{" /" + numberFormat(props.vehicleData.totalQty)}</span>
                </div>
                <ProgressBar value={props.vehicleData.totalQty - props.vehicleData.circulation + (props.vehicleData.auctions ? props.vehicleData.auctions.length : 0)} total={props.vehicleData.totalQty} />
              </div>
            ),
          })
          break;
        case 'watchlist' :
          formatted.push({
            title: 'Watchlist',
            action: props.inWatchlist ? (
              <div className="icons-inline" onClick={() => props._removeFromWatchListAction()}>
                <Icon icon="heart" blue active />
              </div>
            ) : (
              <div className="icons-inline" onClick={() => props._addToWatchListAction()}>
                <Icon icon="heart" blue />
              </div>
            ),
          });
          break;
        default:
          return
      }

    });

    setFormattedAttributes(formatted);
  }, [props.items])


  return (
    <div className={`attributes ${props.theme} ${props.className}`}>
      {
        _.map(formattedAttributes, ({ title, value, action }, index) => {
          return (
          <div key={`${title}-${value}`} className={classNames(
            props.attrClassName,
            "attribute",
            {"me-3 me-sm-2 me-xl-3" : ((index+1) !== formattedAttributes.length)},
            )}
            >
            <div className={"attribute__title text-xs " + props.footerClass}>{title}</div>

            <div className={"attribute__value " + props.footerClass}>
              {value}

              {(
                action ?
                  <div className={"attribute__value__action " + props.footerClass}>
                    {action}
                  </div> :
                  null
              )}
            </div>
          </div>
        )})
      }
    </div>
  )


}


Attributes.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.string,
};

Attributes.defaultProps = {
  theme: 'dark'
};

export { Attributes }
