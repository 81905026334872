import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { route } from 'app/routes';
import { Attributes } from 'app/components';
import { Address } from 'app/components/web3';
import { useEthereumCarOwner } from 'app/hooks';
import { Image, TextScale, Icon } from 'app/components/elements';
import { Badge, Row, Col, Button, Placeholder } from 'react-bootstrap'
import './cart-car-item.sass';

import { UserAvatar } from 'app/components/user/user-avatar/user-avatar';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";

import { showNotification } from 'app/utils';

import { getWatchList, addToWatchList, removeFromWatchList } from 'app/store/actions/garageActions';
import { getOpenSeaURL, getEtherscanURL, getVehicleByID } from 'app/api';


function CartCarItem(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [inWatchlist, setInWatchlist] = useState(false);
  const [hasAuctions, setHasAuctions] = useState(false);
  const [imageLoadError, setImageLoadError] = useState(false);

  const ethereumCarOwner = useEthereumCarOwner(props.data?.id);

  useEffect(() => {
    if (props.data) {
      if (props.data.auctions && props.data.auctions.length > 0) setHasAuctions(true);
      if (props.garageData) {
        setInWatchlist(props.garageData.watchList.items.some(item => item.vehicleId === props.data.id));
      }
    }
  }, [props.garageData, props.data]);


  // Car data is probably being fetched
  // Default loader
  if (!props.data) {
    return (
      <div>
        <div className={classNames('cart-car-item__image mb-3', { empty: imageLoadError }, { addBG : props.addBG })}>
          <Image src={require('./blank-car.webp')} forceLoader style={{minHeight: 100}} />
        </div>
        <Placeholder as="p" animation="glow">
          <Placeholder xs={12} />
          <Placeholder xs={12} />
        </Placeholder>
      </div>
    )
  }


  const isGiveaway = (props.data?.releaseName === 'Giveaway');
  const isSoldOut = (props.data?.soldOut && props?.tabTitle !== 'Marketplace' && !isGiveaway);

  function _addToWatchList() {
    if (props.userData.auth) {
      props.addToWatchList(props.data.id).then(() => {
        props.getWatchList({ size: 100 });
        showNotification(`${props.data.make} ${props.data.model} was added to your watchlist ☺️`);
      });
    } else {
      navigate(
        {
        pathname: route('login'),
        state: {
          from: location.pathname
        }
      });
    }
  }


  const serialize = obj => Object.keys(obj)
                               .map(key => `${key}=${encodeURIComponent(obj[key])}`)
                               .join('&')



  function renderFooterInfo() {
    if (props.data.blockchain == "ETHEREUM") {
      return (
        <Row>
          <Col className="text-dark text-sm text-bold">
            <div>Ethereum</div>
            <Address address={ethereumCarOwner.data} isLoading={ethereumCarOwner.isLoading} isError={ethereumCarOwner.isError} className="text-sm" noTooltip />
          </Col>
          <Col xs={3} sm={4} className="text-sm text-bold text-end"><a href={getOpenSeaURL() + "/" + props.data.id} target="_blank">View on OpenSea</a></Col>
        </Row>
      );
    } else if (props.category == "user") {
      return (
        <Attributes theme={props.theme} vehicleData={props.data} items={['productionNum', 'remaining']} footerClass={props.footerClass}/>
      );
    } else if (props.category == "marketplace") {
      return (
        <Attributes theme={props.theme} vehicleData={props.data} items={['price', 'productionNum']} footerClass={props.footerClass}/>
      );
    } else if (props.category == "featured") {
      return (
        <Attributes theme={props.theme} vehicleData={props.data} items={['price', 'productionNum', 'remaining']} footerClass={props.footerClass}/>
      );
    } else if (props.data.soldOut && hasAuctions) {
      // Show secondary market listing options
      return (
        <div className="d-inline-flex align-items-center">
          <Image src={require('../../assets/icon-store.png')} className="me-3 ms-0" width={36} height={36} />
          <div className="d-block">
            <Link to={{
              pathname: route('showroomSearch', { tabsName: "marketplace" }),
              search: serialize({ search: props.data.make + ' ' + props.data.model })
            }} className="text-sm" >View Listings</Link>
            <div className="text-xs text-muted">Listed by {props.data.auctions.length} user{props.data.auctions.length > 1 && "s"} on the secondary marketplace</div>
          </div>
        </div>
      )
    } else if (inWatchlist && !hasAuctions && props.data.soldOut) {
      // We will email you when available on secondary market
      return (
        <div className="d-inline-flex align-items-center">
          <Image src={require('../../assets/icon-heart-red.png')} className="me-3 ms-0" width={36} height={36} />
          <div className="d-block">
            <div className="text-xs text-muted">We'll email you when this car is available!</div>
          </div>
        </div>
      )
    } else if (!inWatchlist && !hasAuctions && props.data.soldOut) {
      // Add it to your watchList
      return (
        <div className="d-inline-flex align-items-center">
          <Button className="circle btn-primary me-3 ms-0 small" onClick={() => _addToWatchList()}>
            <Icon icon="heart" white style={{width: 16}} />
          </Button>
          <div className="d-block">
            <div onClick={() => _addToWatchList()} className="text-sm text-blue hover-pointer" >Add to Watchlist</div>
            <div className="text-xs text-muted">Email me when it's available</div>
          </div>
        </div>
      )
    } else {
      // Default car info
      return (
        <Attributes theme={props.theme} vehicleData={props.data} items={['price', 'productionNum', 'remaining']} footerClass={props.footerClass}/>
      );
    }


  }




  return (
    <div className={classNames('link-block cart-car-item', props.className, {
      //'giveaway-car': isGiveaway,
      'cat-trade-template': props.tabsTemplate,
    })}>
      <Link
        to={route('modelOverview', { id: props.data.id || props.data.vehicleId }, props.data)}
        onClick={(e) => props.onClick(e, props.data.id)}
      >
        <div className={classNames('cart-car-item__image mb-3', { empty: imageLoadError }, { addBG : props.addBG })}>

          {isSoldOut &&
            <Badge bg="danger" className="tag"><span className="text-xbold">!</span> SOLD OUT</Badge>
          }

          {isGiveaway &&
            <Badge bg="warning" className="tag"> GIVEAWAY </Badge>
          }

          {/*props.data.dutchAuction &&
            <Badge bg="warning" className="tag"> AUCTION </Badge>
          */}

          <Image
            webpSrc={props.data?.assets?.side_webp}
            src={props.data?.assets?.side}
            fallbackSrc={require('./blank-car.webp')}
            alt={props.data.make}
            onError={() => setImageLoadError(true)}
            style={{minHeight: 100}}
            className={classNames('carImg', {
              'soldOutImg': isSoldOut,
              'giveawayImg': isGiveaway,
            })}
          />

          <div className="cart-car-item__image__overlay">
            View Details
          </div>

        </div>

        <TextScale className={"cart-car-item__title " + props.footerClass}>{`${props.data.year} ${props.data.make} ${props.data.model}`}</TextScale>
      </Link>

      {/*<Row className="cart-car-item__footer align-items-center gx-0">
        {renderCarFooter()}
      </Row>*/}

      <Row className="cart-car-item__footer align-items-center gx-0">
        <Col xs={10}>
          {renderFooterInfo()}
        </Col>
        <Col xs={2}>
          {(props.data.blockchain == "ETHEREUM") ?
            <>
              <Image src={require('app/assets/wallet-logos/ethereum.png')} width={40} />
            </>
            :
            <>
              {(props.data.ownerId) ?
                <Link to={route('userGarage', { id: props.data.ownerId })}>
                  <UserAvatar userID={props.data.ownerId} className="owner-photo small" />
                </Link>
              :
                <UserAvatar userID={'motobloqLogo'} className="owner-photo small" />
              }
            </>
          }
        </Col>
      </Row>

    </div>
  );
}


CartCarItem = connect(
    ({ user, garage }) => ({
      userData: user,
      garageData: garage
    }),
    {
      addToWatchList,
      getWatchList,
      removeFromWatchList,
    },
  )(CartCarItem);

CartCarItem.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.string,
  onClick: PropTypes.func,
  tabsTemplate: PropTypes.string,
  category: PropTypes.string,
};

CartCarItem.defaultProps = {
  theme: 'dark',
  onClick: () => {}
};

export { CartCarItem };
