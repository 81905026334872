import _ from 'lodash';

import { GET_ALL_VEHICLES, GET_NEW_SHOWROOM, GET_NEW_TO_SHOWROOM, GET_FEATURED, GET_RESERVED, GET_MARKETPLACE } from 'app/store/actions/vehiclesActions';

const vehiclesList = {
  error: false,
  allVehiclesList: {
    title: 'All Cars',
    items: [],
    badge: 0,
    actionType: GET_ALL_VEHICLES,
    typeOfRelease: 'curent',
    tabsName: 'all',
  },

  showroomList: {
    title: 'New In',
    //description: 'The newest arrivals from the production factory.',
    items: [],
    badge: 0,
    actionType: GET_NEW_SHOWROOM,
    tabsName: 'new',
  },
  marketplaceList: {
    title: 'Secondary Market',
    description: 'The Scondary Market showcases all cars listed for sale by other users at the price specified by them.',
    items: [],
    badge: 0,
    actionType: GET_MARKETPLACE,
    tabsName: 'marketplace',
  },
  // newestList: {
  //   title: 'New to Showroom',
  //   items: [],
  //   badge: 0,
  //   actionType: GET_NEWEST,
  //   tabsName: 'newest',
  // },
  featuredList: {
    title: 'Featured',
    //description: 'The hottest cars on Motobloq right now.',
    items: [],
    badge: 0,
    actionType: GET_FEATURED,
    tabsName: 'featured',
  },
  reservedList: {
    title: 'Reserved',
    items: [],
    badge: 0,
    actionType: GET_RESERVED,
    typeOfRelease: 'curent',
    tabsName: 'reserved',
  },

  page: 0,
  size: 10,
};

export function vehicles(state = vehiclesList, action = {}) {
  switch (action.type) {
    case GET_ALL_VEHICLES:
      return getData(state, action);
    case GET_NEW_SHOWROOM:
      return getData(state, action, 'showroomList');
    // case GET_NEWEST:
    //   return getData(state, action, 'newestList');
    case GET_MARKETPLACE:
      return getData(state, action, 'marketplaceList');
    case GET_FEATURED:
      return getData(state, action, 'featuredList');
    case GET_RESERVED:
      return getData(state, action, 'reservedList');

    default:
      return state;
  }
}

const getData = (state, action, list = 'allVehiclesList') => {
  const newState = { ...state };

  if (action.error || !action.payload) {
    action.payload = [];
  }

  newState[list] = {
    ...state[list],
    items: (action.payload.content || []),
    badge: (action.payload.totalElements || 0),
  };

  return { ...newState, ...action.meta };
};
