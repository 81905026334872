import _ from 'lodash';
import {
  GET_USER_COLLECTION,
  GET_WATCHLIST,
  GET_BUYING,
  //  GET_TRADE,
  GET_ETHEREUM,
  GET_SELLING,
  ADD_TO_WATCHLIST,
  // GET_RECOMMENDATIONS
} from 'app/store/actions/garageActions';

const garageList = {
  //purchasesProcessing: 0,
  error: false,
  collectionList: {
    title: 'Owned',
    items: [],
    badge: 0,
    actionType: GET_USER_COLLECTION,
    typeOfRelease: 'curent',
    tabsName: 'collection',
    tabsTemplate: 'carTemplateBlock',
  },
  // inProgressList: {
  //   title: 'Owned',
  //   items: [],
  //   badge: 0,
  //   actionType: GET_USER_COLLECTION,
  //   typeOfRelease: 'curent',
  //   tabsName: 'collection',
  //   tabsTemplate: 'carTemplateBlock',
  // },
  watchList: {
    title: 'Watchlist',
    items: [],
    badge: 0,
    actionType: GET_WATCHLIST,
    tabsName: 'watchlist',
    //tabsTemplate: 'carTemplateBlock',
  },
  buying: {
    title: 'Buying',
    items: [],
    tabsName: 'buying',
    badge: 0,
    actionType: GET_BUYING,
    tabsTemplate: 'carTemplateRow',
  },
  selling: {
    title: 'Selling',
    tabsName: 'selling',
    items: [],
    badge: 0,
    actionType: GET_SELLING,
    tabsTemplate: 'carTemplateRow',
  },
  ethereum: {
    title: 'Ethereum',
    items: [],
    tabsName: 'ethereum',
    badge: 0,
    actionType: GET_ETHEREUM,
    tabsTemplate: 'carTemplateBlock',
  },
  // trade: {
  //   title: 'Trade',
  //   tabsName: 'trade',
  //   items: [],
  //   badge: 3,
  //   tabsTemplate: 'carTrade',
  //   actionType: GET_TRADE,
  // },
  // recommendations: {
  //   title: 'Recommendations',
  //   tabsName: 'recommendations',
  //   items: [],
  //   badge: 0,
  //   actionType: GET_RECOMMENDATIONS,
  // },

  page: 0,
  size: 10,
};

export function garage(state = garageList, action = {}) {
  switch (action.type) {
    // case 'ADD_CAR_PROCESSING':
    //   return { ...state, purchasesProcessing: state.purchasesProcessing + 1 }


    case GET_USER_COLLECTION:
      return getDataGarage(state, action, 'collectionList');

    case GET_WATCHLIST:
      return getDataGarage(state, action, 'watchList');

    case GET_SELLING:
      return getDataGarage(state, action, 'selling');

    case GET_BUYING:
      return getDataGarage(state, action, 'buying');

    case GET_ETHEREUM:
      return getDataGarage(state, action, 'ethereum');

    case ADD_TO_WATCHLIST:
      //return getDataGarage(state, action, 'watchList');
      //newState[list] = {
      //console.log("action.payload", action.payload);
      //console.log("action", action);
      //console.log("state", state);
      return {
        ...state,
        watchList: {
          ...state.watchList,
          items: ['asdf']
        }
      }

    default:
      return state;
  }
}

const getDataGarage = (state, action, list = 'collectionList') => {
  const newState = { ...state };

  if (action.error || !action.payload) {
    action.payload = [];
  }

  let items = (action.payload.content || []),
      badge = (action.payload.totalElements || 0);

  newState[list] = {
    ...state[list],
    items,
    badge,
  };

  return { ...newState, ...action.meta };
};
