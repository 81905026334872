import Loadable from 'react-loadable';

import { PageLoader } from './';

export const appLoadable = (loader) =>
  Loadable({
    loader,
    loading: PageLoader,
  });

export const CreateAccount = appLoadable(() => import('app/pages/create-account/create-account-wrap'));
export const ConfirmRegistraion = appLoadable(() => import('app/pages/confirm-registration/confirm-registration'));
export const ConfirmPaypalEmail = appLoadable(() => import('app/pages/confirm-paypal-email/confirm-paypal-email'));


export const FaqPage = appLoadable(() => import('app/pages/faq-page/faq-page'));
export const BridgeFaq = appLoadable(() => import('app/pages/bridge-faq/bridge-faq'));

export const PressPage = appLoadable(() => import('app/pages/press-page/press-page'));
export const ForgotPassword = appLoadable(() => import('app/pages/forgot-password/forgot-password'));

export const Settings = appLoadable(() => import('app/pages/settings/settings'));
export const PasswordConfirmation = appLoadable(() => import('app/pages/password-confirmation/password-confirmation'));

export const Garage = appLoadable(() => import('app/pages/garage/garage'));
export const Home = appLoadable(() => import('app/pages/home/home'));
export const Login = appLoadable(() => import('app/pages/login/login'));
export const ModelOverview = appLoadable(() => import('app/pages/model-overview/model-overview'));
export const ModelOverviewProvenance = appLoadable(() => import('app/pages/model-overview/model-overview-provenance'));
export const ModelAR = appLoadable(() => import('app/pages/model-ar/model-ar'));
export const ModelViewer = appLoadable(() => import('app/pages/model-viewer/model-viewer'));


export const Bridge = appLoadable(() => import('app/pages/bridge/bridge'));

export const NotFound = appLoadable(() => import('app/pages/not-found/not-found'));
export const PrivacyPolicyPage = appLoadable(() => import('app/pages/privacy-policy-page/privacy-policy-page'));
export const ResetPassword = appLoadable(() => import('app/pages/reset-password/reset-password'));
export const Showroom = appLoadable(() => import('app/pages/showroom/showroom'));
export const SupportedCurrenciesPage = appLoadable(() =>
  import('app/pages/supported-currencies-page/supported-currencies-page'),
);
export const SupportPage = appLoadable(() => import('app/pages/support-page/support-page'));
export const TermsAndConditionsPage = appLoadable(() =>
  import('app/pages/terms-and-conditions/terms-and-conditions'),
);

export const ShortURL = appLoadable(() => import('app/pages/short-url/short-url'));
export const UserGarage = appLoadable(() => import('app/pages/user-garage/user-garage'));
export const ListForSale = appLoadable(() => import('app/pages/list-for-sale/list-for-sale'));

export const Wallet = appLoadable(() => import('app/pages/wallet/wallet'));
export const VerifyTicket = appLoadable(() => import('app/pages/verify-ticket/verify-ticket'));


export const Trade = appLoadable(() => import('app/pages/trade/trade'));
export const TradeOffer = appLoadable(() => import('app/pages/trade-offer/trade-offer'));


export const RedeemCongratulations = appLoadable(() => import('app/pages/redeem-congratulations/redeem-congratulations'));
export const RedeemConfirm = appLoadable(() => import('app/pages/redeem-confirm/redeem-confirm'));


export const OrderConfirmation = appLoadable(() => import('app/pages/order-confirmation/order-confirmation'));
export const CongratulationsOrder = appLoadable(() => import('app/pages/order-congratulations/order-congratulations'));

export const AdminPanel = appLoadable(() => import('app/pages/admin-panel/admin-panel'));

export const OAuth2RedirectHandler = appLoadable(() => import('app/pages/oauth2/oauth2'));
