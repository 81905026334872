import React, {useState, useEffect, useRef, useCallback} from 'react';
import * as ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './image.sass';
import { Loader } from 'app/components/loader/loader'
import { useIntersection } from 'app/hooks/useIntersection';

import { Image } from 'react-bootstrap';



// This is our custom image component. Features are:
// - Lazy Loading (isInView)
// - Cancel downloading when page changed
// - WebP support with webpSrc
// - fallbackSrc if image load error
// - Optional loading spinner while loading

function ImageComp(props) {

  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);
  const [srcToLoad, setSrcToLoad] = useState(null);
  const imgRef = useRef();
  const containerRef = useRef();

  const [isInView, setIsInView] = useState(props.noLazyLoad ? true : false);


  //determine what to load
  useEffect(() => {
    if (props.src) {
      setSrcToLoad(props.src);
    } else if (props.fallbackSrc) {
      setSrcToLoad(props.fallbackSrc);
    }
  }, []);



  useIntersection(containerRef, () => {
    setIsInView(true);
  });


  // If the component unmounted we stop downloading the image by changing src
  useEffect(() => {
    let localImgRef = null;
    if (imgRef.current) localImgRef = imgRef.current;
    return () => {
      if (localImgRef && (!localImgRef.complete || localImgRef.naturalHeight === 0)) {
        // We only cancel download img if it did not complete
        localImgRef.src = '';
      }
    }
  }, []);



  const _imgOnLoad = () => {
    setLoading(false);
    props.onLoad();
  };


  // Show fallbackSrc image
  const _imgOnError = () => {
    setLoadError(true);
    setLoading(false);

    if (props.fallbackSrc) {
      setSrcToLoad(props.fallbackSrc);
    }

    props.onError();
  };


  return (
    <div
      ref={containerRef}
      className={classNames(
        'd-flex flex-column justify-content-end super-image',
        props.className,
      )}
      style={props.style}
    >

      {((props.forceLoader || props.noLoader !== true && loading)) && <div className="loaderCenter"><Loader show large /></div>}

      <picture style={{height: '100%', maxHeight: 'inherit'}}>
        <source srcSet={isInView ? props.webpSrc : undefined} type={"image/webp"} />
        <source srcSet={isInView ? srcToLoad : undefined} />
        <Image fluid={true} style={{maxHeight: 'inherit'}} src={isInView ? srcToLoad : undefined} ref={imgRef} alt={props.alt} width={props.width} height={props.height} onLoad={() => _imgOnLoad()} onError={() => _imgOnError()} />
      </picture>

    </div>
  );

}


ImageComp.defaultProps = {
    onLoad: () => {},
    onError: () => {},
}

export { ImageComp as Image };
