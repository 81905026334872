import React from "react";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';

function DropdownContent(props) {
  if (isMobile) {
    return (
      <Modal show className={classNames(
        "dropdownMobileModal mobile",
        props.className
      )} dialogClassName="m-0">
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    )
  }


  return(
    <div className={classNames(
      "dropdown-content",
      props.className,
      {"mobileTop": isMobile && (props.positionMobile === 'top')},
      {"mobileBottom": isMobile && (props.positionMobile === 'bottom')},
      {"mobile": isMobile},
    )} style={props.style, { width: props.width ? props.width : 200 }}>
      <div className="dropdown-content__arrow"/>
      <div className="dropdown-content__wrap justify-content-center rounded-3">
        {props.children}
      </div>
    </div>
  )
};

DropdownContent.displayName = 'DropdownContent';

DropdownContent.propTypes = {
  children: PropTypes.node.isRequired
};

export { DropdownContent }
