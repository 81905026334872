import React from 'react';


import AppProviders from './AppProviders';
import ModelViewer from 'app/pages/model-viewer/model-viewer';



function App() {
  return (<AppProviders/>);
}

export default App;
