import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import './modal.sass';

import { Icon } from 'app/components/elements';
import { Button } from 'react-bootstrap';

class Modal extends React.Component {
  static propTypes = {
    modal: PropTypes.object.isRequired,
    onRemove: PropTypes.func,
  };

  static defaultProps = {
    onRemove: () => {},
  };

  constructor(props) {
    super(props);

    this.state = { show: true };

    props.modal.current.setClose(this._close);
  }

  _getElements = () => {
    const elements = {};

    let { children } = this.props.modal.current.props;

    if (!(children instanceof Array)) {
      children = [children];
    }

    _.forEach(children, (child) => {
      switch (child.type.displayName) {
        case 'ModalBody':
          elements.body = child;
          break;
        case 'ModalFooter':
          elements.footer = child;
          break;
        default:
          break;
      }
    });

    return elements;
  };

  _close = () => {
    const {
      modal: {
        current: {
          props: { onBeforeClose = () => true, onClose = () => {} },
        },
      },
      onRemove,
    } = this.props;

    if (onBeforeClose()) {
      this.setState({ show: false });

      setTimeout(() => {
        onRemove();
        onClose();
      }, 150);
    }
  };

  render() {
    const { message, small, className, title } = this.props.modal.current.props;

    const { body, footer } = this._getElements();

    return (
      <div
        className={classNames(
          'modal',
          {
            show: this.state.show,
            message,
            small,
          },
          className,
        )}
      >
        <div className="modal__overlay" onClick={this._close} />

        <div className="modal__wrap">
          <div className="modal__content">
            <div className="modal__header">
              <div className="modal__title">{title}</div>

              <Button noStyling className="modal__close" onClick={this._close}>
                <Icon icon="clear" />
              </Button>
            </div>

            <div className="modal__body">{body}</div>

            {footer && <div className="modal__footer">{footer}</div>}
          </div>
        </div>
      </div>
    );
  }
}

export { Modal };
