import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Card } from 'react-bootstrap';
import { TicketItem } from './ticket-item'
function MyTickets(props) {

  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    const doAsyncFn = async () => {
      //fetch tickets
      try {
        const response = await axios.get('/users/me/tickets');
        setTickets(response)
      } catch (e) {
        console.log(e);
      }
    }
    doAsyncFn();
  }, [])

  return (
    <div id="MyTickets">
      {(tickets.length === 0) && <h2 className="text-center text-muted my-5">No Tickets</h2>}
      <Row>
        {tickets.map((ticket, index) => (
          <Col key={index} xs={12} sm={6} lg={4} style={{marginBottom: 24}}>
            <TicketItem data={ticket}/>
          </Col>
        ))
        }
      </Row>
    </div>
  )
}

export { MyTickets };
