import React from 'react';
import './divider.sass';
import classNames from 'classnames';


const Divider = (props) => {
  return (
    <div className={classNames("divider", props.className)}>
      <hr/>
    </div>
  )
}


export { Divider };
