import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./header-background-editor.sass";

import { Icon } from "app/components/elements";
import { Button } from 'react-bootstrap';

import { RadiosInline } from "app/components/form";

class HeaderBackgroundEditor extends React.Component {

  static propTypes = {
    carCountDisabled: PropTypes.bool,
    carCountDisabledMessage: PropTypes.string,
    data: PropTypes.object,
    onSave: PropTypes.func,
  };

  static defaultProps = {
    onSave: () => {}
  };

  constructor(props) {
    super(props);

    this.data = props.data;

    this.state = { editorOpen: false, parametersChanged: false };
  }

  _editorOnChange = ({ name, value }) => {
    switch (name) {
      case 'car-count':
        this.data.carCount = parseInt(value);
        break;
      case 'header-background':
        this.data.headerWhite = (value === '#fff');
        break;
      default:
        break;
    }
    this.setState({ parametersChanged: true });
  };

  _editorToggleOnClick = () => {
    const { editorOpen, parametersChanged } = this.state;

    if (editorOpen && parametersChanged) {
      this.props.onSave(this.data);
    }

    this.setState({ editorOpen: !editorOpen, parametersChanged: false });
  };

  render() {
    const { carCountDisabled, carCountDisabledMessage, data: { carCount, headerWhite } } = this.props;
    const { editorOpen } = this.state;

    const carCountItems = [{ checked: (carCount === 1) }];

    if (!carCountDisabled) {
      carCountItems.push({ checked: (carCount === 2) })
    }

    return (
      <div className={classNames(
        'header-background-editor',
        { open: editorOpen }
      )}>
        <div className="header-background-editor__content">
          <div className="header-background-editor__content__wrap">
            <div className="header-background-editor__content__item">
              <RadiosInline
                name="car-count"
                items={carCountItems}
                disabled={carCountDisabled}
                disabledMessage={carCountDisabledMessage}
                onChange={this._editorOnChange}
              />
            </div>
            <div className="header-background-editor__content__item">
              <RadiosInline
                name="header-background"
                items={[
                  {
                    value: '#fff',
                    checked: headerWhite,
                    label: <Icon icon="confirm"/>
                  },
                  {
                    value: '#20262f',
                    checked: !headerWhite,
                    label: <Icon icon="confirm" white/>
                  }
                ]}
                colors
                onChange={this._editorOnChange}
              />
            </div>
          </div>
        </div>

        <Button
          variant="dark"
          className="circle header-background-editor__toggle"
          onClick={this._editorToggleOnClick}
        >
          <Icon
            icon={(editorOpen ? 'confirm' : 'edit')}
            white
          />
        </Button>
      </div>
    );
  }
}

export { HeaderBackgroundEditor }
