import React from "react";

class ModalContent extends React.Component {
  
  static displayName = 'ModalContent';
  
  /*componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
      this.update();
    }
  }*/
  
  close = () => {};
  //update = () => {};

  setClose = newClose => (this.close = newClose);
  //setUpdate = newUpdate => (this.update = newUpdate);
  
  render() {
    
    return null;
  };
}

export { ModalContent }
