import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import _ from 'lodash';
import axios from 'axios';

import './sass/main.sass';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { showNotification } from 'app/utils';
import { store } from 'app/store/reducers';
import { logOut } from 'app/store/actions/userActions';
import { changeConfig } from 'app/store/actions/configActions';

import ModelViewer from 'app/pages/model-viewer/model-viewer';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use((config) => {

  const { user: { jwt } = {} } = store.getState();

  if (!!jwt) {
    config.headers['Authorization'] = `Bearer ${jwt}`;
  }

  loaderToggle();
  return config;
});

axios.interceptors.response.use(
  (response) => {
    loaderToggle(false);
    let payload = response.data;
    //payload.httpStatus = response.status;
    return payload;
    //console.log('resp ax', response);
  },
  (error) => {
    const {
      response: { data: { errorCode = '', errorMessage = 'Something went wrong.' } = {}, status = 200 } = {},
      config: { hideError },
    } = error;

    //console.log('errrr ax', error);
    // if (error.response && error.response.data && error.response.data.location) {
    //   window.location = error.response.data.location
    // }

    loaderToggle(false);

    if (status === 401) {
      store.dispatch(logOut());
    }

    if (status !== 200 && !hideError) {
      showNotification(
        errorMessage,
        'error',
      );
    }

    return Promise.reject(error);
  },
);

const loaderToggle = _.debounce((showLoader = true) => store.dispatch(changeConfig({ showLoader })), 350, {
  leading: true,
  trailing: true,
});

// ReactDOM.render(<App />, document.getElementById('root'));

const root = createRoot(document.getElementById('root')); // createRoot(container!) if you use TypeScript
if (window.location.pathname.startsWith("/model-viewer/")) {
  root.render(<ModelViewer />);
} else {
  root.render(<App />);

}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
