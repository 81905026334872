import React from "react";
import PropTypes from "prop-types";

import "./section-header.sass";

const SectionHeader = ({ children }) => (
  <div className="section-header">
    {children}
  </div>
);

SectionHeader.propTypes = {
  children: PropTypes.node.isRequired
};

export { SectionHeader }
