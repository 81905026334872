import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import './textarea.sass';

import { Icon } from 'app/components/elements';

class Textarea extends React.Component {
  static propTypes = {
    buttons: PropTypes.array,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onClear: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.oneOf(['email', 'password', 'text', 'number']),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    onClear: () => {},
    value: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      notEmpty: props.value && props.placeholder,
      inputValue: props.value,
      errorShow: false,
      errorMessage: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ inputValue: this.props.value, notEmpty: true });
    }
  }

  setError = (message = null) => {
    const { errorMessage } = this.state;

    if (message !== errorMessage && errorMessage !== '') {
      this.setState({
        hasSuccess: false,
        hasError: true,
        errorShow: false,
      });

      setTimeout(
        () =>
          this.setState({
            errorShow: true,
            errorMessage: message,
          }),
        150,
      );
    } else {
      this.setState({
        hasSuccess: false,
        hasError: true,
        errorShow: true,
        errorMessage: message,
      });
    }
  };
  setSuccess = () => {
    this.setState({
      hasSuccess: true,
      hasError: false,
      errorShow: false,
      errorMessage: '',
    });
  };

  _inputOnChange = ({ target: { value } }) => {
    const { name, placeholder } = this.props;

    if (name === 'number' && value && !/^(\d+)$/g.test(value)) {
      return;
    }

    this.setState({
      notEmpty: value && placeholder,
      inputValue: value,
    });
  };

  _inputClearOnClick = () => {
    const { disabled, name, onClear } = this.props;

    if (disabled) {
      return;
    }

    this.setState({
      notEmpty: false,
      inputValue: '',
    });

    onClear(name);
  };

  render() {
    const {
      props: { buttons, className, disabled, name, placeholder },
      state: {
        //  errorMessage, errorShow,
        hasError,
        hasSuccess,
        inputValue,
        notEmpty,
      },
    } = this;

    return (
      <label
        className={classNames(
          'input-container',
          {
            success: hasSuccess,
            error: hasError,
            disabled,
          },
          className,
        )}
      >
        <div className="input-wrap">
          <textarea
            rows="4"
            className={classNames('textarea', {
              'no-placeholder': !placeholder,
              'not-empty': notEmpty,
            })}
            name={name}
            value={inputValue || ''}
            autoComplete="off"
            onChange={this._inputOnChange}
            disabled={disabled}
          />

          {placeholder && <span className="input__placeholder">{placeholder}</span>}

          <div className="input__buttons">
            {(hasSuccess || hasError) && <div className="input__status-dot" />}

            {notEmpty && (
              <button
                type="button"
                className="input__clear"
                onClick={this._inputClearOnClick}
                disabled={disabled}
                tabIndex="-1"
              >
                <Icon icon="clear" />
              </button>
            )}

            {buttons &&
              _.map(buttons, (button, index) =>
                React.cloneElement(button, {
                  key: index,
                  tabIndex: -1,
                  disabled,
                }),
              )}
          </div>
        </div>

        {/* <div className="input__error">
          <div className={classNames('input__error__message', { show: errorShow })}>{errorMessage}</div>
        </div> */}
      </label>
    );
  }
}

export { Textarea };
