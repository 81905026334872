// https://dev.to/aduranil/how-to-use-websockets-with-redux-a-step-by-step-guide-to-writing-understanding-connecting-socket-middleware-to-your-project-km3

import React from 'react';
import * as actions from '../actions/websocketActions';
import { getVehicle } from '../actions/vehicleActions';
import { showNotification } from 'app/utils';
import { Link } from 'react-router-dom';
import { route } from 'app/routes';

import { toast } from 'react-toastify';




const socketMiddleware = () => {


  // - - - - - - Buying CAR from MotoBloq - - - - - - - -

  const processingTansferMsg = (
    <div>
      <span className="text-dark text-sm text-bold">Your car is on its way</span>
      <br/>
      <span className="text-xs">Blockchain transaction processing...</span>
    </div>
  );

  const doneTransferMsg = (
    <div className="text-center">
      <img src={require('app/assets/checkmark.png')} width="48" />
      <p className="text-dark text-bold my-3">Your car has arrived!</p>
      <Link to={route('garage')} className="text-sm">View Garage</Link>
    </div>
  );





  // - - - - - - Bridging Car to Other Chain - - - - - - - -

  const processingBridgeMsg = (
    <div>
      <span className="text-dark text-sm text-bold">Your car is on its way</span>
      <br/>
      <span className="text-xs">Bridging to Ethereum...</span>
    </div>
  );

  const doneBridgeMsg = (
    <div className="text-center">
      <img src={require('app/assets/checkmark.png')} width="48" />
      <p className="text-dark text-bold my-3">Your car is now on Ethereum!</p>
      <Link to={route('garage')} className="text-sm">View on OpenSea</Link>
    </div>
  );

  const errorBridgeMsg = (
    <div className="text-center">
      <p className="text-dark text-bold my-3">Something went wrong bridging your car.</p>
      <p>Please contact <a href="mailto:support@motobloq.com?subject=Failed Bridge Transger">support@motobloq.com</a> for help.</p>
    </div>
  )

  const processingDepositMsg = (
    <div>
      <span className="text-dark text-sm text-bold">Confirming deposit...</span>
    </div>
  );

  const doneDepositMsg = (
    <div className="text-center">
      <img src={require('app/assets/checkmark.png')} width="48" />
      <p className="text-dark text-bold my-3">Deposit recieved!</p>
    </div>
  );





  // Promises stored using key vehicleId
  let promises = {};
  let pings = {};


  const onOpen = store => (event) => {
    // console.log('opening websocket', event.target.url);
    // console.log('opening websocket event', event);
    // store.dispatch({ type: 'WS_CONNECTED', host: "asdf" });
    return store.dispatch(actions.wsConnected(event.target.url));
  };


  const onClose = store => (event) => {
    // console.log('closing websocket', event.target.url);
    // console.log('closing websocket event', event);
    return store.dispatch(actions.wsDisconnected(event.target.url));
  };

  const onError = store => (event) => {
    // console.log('Socket error for', event.target.url);
    // console.log('error websocket event', event);
    return store.dispatch(actions.wsDisconnected(event.target.url));
  };




  const onMessage = (store, cb) => (event) => {
    const payload = JSON.parse(event.data);
    //console.log('event server message', payload);

    // console.log('promises', promises);
    // console.log('receiving server message', payload);

    // We only show the notifications to the appropriate user
    const currentUser = store.getState().user;
    if (payload.userId === currentUser.id) {

      // call cb function for notification updates to the connected component
      if (cb) cb(payload.notificationType);



      switch (payload.notificationType) {

        // - - - - - - Buying CAR from MotoBloq - - - - - - - -
        case 'VEHICLE_TRANSFER_IN_PROGRESS':
          let newPromiseMoto = () => new Promise((resolve, reject) => {
            promises[event.target.url] = {
              promiseResolve: resolve,
              promiseReject: reject,
              isPending: true,
            };
          });

          setTimeout(() => {
            toast.promise(newPromiseMoto,
              {
                pending: { render(){ return processingTansferMsg } },
                success: { render(){ return doneTransferMsg }, icon: false },
                error: 'Something went wrong...'
              }
          )}, 1000);

          break;

        case 'VEHICLE_TRANSFER_EXECUTED':
          if (promises[event.target.url]) {
            promises[event.target.url].promiseResolve();
            promises[event.target.url].isPending = false;

            // Close the WS
            store.dispatch(actions.wsDisconnect(event.target.url));
          }
          break;





        // - - - - - - Bridging Car to Other Chain - - - - - - - -

        case 'ERC721_TRANSFER_DEPOSIT_MADE':

          // Complete the toast notification promise
          promises[event.target.url+'-waiting-for-deposit'].promiseResolve();

          // We leave the ws open as we expect to recieve more messages
          // ERC721_TRANSFER_IN_PROGRESS and ERC721_TRANSFER_COMPLETED

          break;

        case 'ERC721_TRANSFER_IN_PROGRESS':
          let newPromiseBrdige = () => new Promise((resolve, reject) => {
            promises[event.target.url] = {
              promiseResolve: resolve,
              promiseReject: reject,
              isPending: true,
            };
          });


          setTimeout(() => {
            toast.promise(newPromiseBrdige,
              {
                pending: { render(){ return processingBridgeMsg } },
                success: { render(){ return doneBridgeMsg }, icon: false },
                error: { render(){ return errorBridgeMsg }, autoClose: false },
              }
          )}, 1000);
          break;


        case 'ERC721_TRANSFER_COMPLETED':

          // Complete the toast notification promise
          promises[event.target.url].promiseResolve();
          promises[event.target.url].isPending = false;

          // Close the WS
          store.dispatch(actions.wsDisconnect(event.target.url));

          break;

        case 'ERC721_TRANSFER_FAILED':

          // Complete the toast notification promise by REJECT (Error)
          promises[event.target.url].promiseReject();
          promises[event.target.url].isPending = false;

          // Close the WS
          store.dispatch(actions.wsDisconnect(event.target.url));

          break;


        default:
          break;
      }

    }


  };






  let sockets = {};

  // the middleware part of this function
  return store => next => action => {

    switch (action.type) {
      case 'WS_CONNECT':

        if (sockets[action.host]) {
          //sockets[action.host].close();
          // console.log('websocket already connected on', action.host);
        } else {
          // console.log('Initialzing connection..', action.host);
          // connect to the remote host
          sockets[action.host] = new WebSocket(action.host);

          // websocket handlers
          sockets[action.host].onmessage = onMessage(store, action.cb);
          sockets[action.host].onclose = onClose(store);
          sockets[action.host].onopen = onOpen(store);
          sockets[action.host].onerror = onError(store);

        }

        break;

      case 'WS_CONNECTED':
        // console.log('connected', action.host);
        // Ping to keep ws alive - it was automatically closing ater 1 minute
        let myInterval = setInterval(() => {
          if (sockets[action.host]) {
            sockets[action.host].send(JSON.stringify({ command: 'NEW_MESSAGE', message: 'PING' }));
            //console.log('ping');
          }
        }, 5000)
        pings[action.host] = myInterval;

        break;

      case 'WS_DISCONNECTED':
        // console.log('disconnected', action.host);
        // Stop ws ping
        clearInterval(pings[action.host]);

        break;

      case 'WS_DEPOSIT_PENDING':
        // Assumes the WS is already connected (from opening bridge page)
        if (sockets[action.host]) {
          //console.log('action.host', action.host);
          let newPromiseDeposit = () => new Promise((resolve, reject) => {
            promises[action.host+'-waiting-for-deposit'] = {
              promiseResolve: resolve,
              promiseReject: reject,
            };
          });
          toast.promise(newPromiseDeposit, {
            pending: { render(){ return processingDepositMsg } },
            success: { render(){ return doneDepositMsg }, icon: false },
            error: 'Something went wrong...'
          })
        }
        break;


      case 'WS_DISCONNECT':
        // Safely disconnect the websocket. if there is a promise pending we don't close it as the promise will close it.
        if (sockets[action.host]) {
          if (promises[action.host] && promises[action.host].isPending == true) {
            return;
          } else {
            // console.log('disconeecting ws', action.host);
            sockets[action.host].close();
            sockets[action.host] = null;
          }
        }
        break;

      case 'NEW_MESSAGE':
        // console.log('sending a message', action.msg);
        sockets[action.host].send(JSON.stringify({ command: 'NEW_MESSAGE', message: action.msg }));
        break;

      default:
        // ignore all other actions as they are irrelivant to the websocket
        // console.log('the next action:', action);
        return next(action);
    }

    return next(action);
  };
};

export default socketMiddleware();
