import React from 'react';
import PropTypes from 'prop-types';

import exchange from './exchange.svg';

import './trade-transfer-car-wrap.sass';

const TradeTransferCarWrap = ({ firstCar, secondCar }) => {
  return (
    <div className="trade-transfer-cars-wrap row">
      <div className="col-5 col-md-12">{firstCar}</div>
      <div className="trade__cars-icon">
        <img src={exchange} alt="" />
      </div>
      <div className="col-5 col-md-12">{secondCar}</div>
    </div>
  );
};

TradeTransferCarWrap.propTypes = {
  secondCar: PropTypes.node,
  firstCar: PropTypes.node,
};

export { TradeTransferCarWrap };
