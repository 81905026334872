import axios from 'axios';
import _ from 'lodash';

export const GET_TRADE_USERS_CARS = 'GET_TRADE_USERS_CARS';
export const GET_TRADE_CAR = 'GET_TRADE_CAR';
export const CLEAR_TRADE_CAR = 'CLEAR_TRADE_CAR';

const defaultVehiclesParams = {
  size: 6,
  page: 0,
  sortField: 'id',
  sortType: 'ASC',
};

export const getTradeUsersCars = async (params = {}, uid) => {
  console.log(`params`, params);
  if (params instanceof URLSearchParams) {
    _.forEach(defaultVehiclesParams, (param, key) => {
      if (!params.has(key)) {
        params.append(key, param);
      }
    });
  } else {
    params = { ...defaultVehiclesParams, ...params };
  }

  return {
    type: GET_TRADE_USERS_CARS,
    // payload: axios.get('/preSales/72fed798-1eda-4b97-9070-caaebaf4f818/availableVehicles', { params }),
    payload: await axios.get(`/preSales/${uid}/availableVehicles`, { params }),
    meta: params,
  };
};

export const getTradeCar = async (vehicleId = '') => {
  return {
    type: GET_TRADE_CAR,
    payload: await axios.get(`/preSales/vehicles/${vehicleId}`),
  };
};

export const clearTradeCar = (vehicleId = '') => {
  return {
    type: CLEAR_TRADE_CAR,
  };
};
