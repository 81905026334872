import React from 'react';
import _ from 'lodash';

import './footer.sass';
import classNames from 'classnames';

import { isMobile } from "react-device-detect";

import { route } from 'app/routes';
import { Logo } from 'app/components/elements';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

const Footer = () => {
  const linksBlocks = [
    {
      title: 'Company',
      links: [
        { link: 'https://concip.io/', label: 'About' },
        { inApp: true, link: route('pressPage'), label: 'Press' },
        { link: 'https://medium.com/@motobloq', label: 'Blog' },
      ],
    },
    {

      title: 'Support',
      links: [
        { inApp: true, link: route('faqPage'), label: 'FAQ' },
        // { link: '#', label: 'Updates' },
        { inApp: true, link: route('supportPage'), label: 'Support' },
        { inApp: true, link: route('termsAndConditionsPage'), label: 'Terms & Conditions' },
        { inApp: true, link: route('privacyPolicyPage'), label: 'Privacy Policy' },
      ],
    },
    {
      title: 'Social',
      horizontal: true,
      links: [
        { link: 'https://discord.gg/yazETGBSBh', label: <img src={require('app/assets/socials/discord.png')} height={22} className="social-icon" /> },
        { link: 'https://www.instagram.com/motobloq/', label: <img src={require('app/assets/socials/instagram.png')} height={27} className="social-icon" /> },
        { link: 'https://t.me/MotoBloq', label: <img src={require('app/assets/socials/telegram.png')} height={26} className="social-icon" /> },
        { link: 'https://twitter.com/MotoBloq', label: <img src={require('app/assets/socials/x-logo-black.png')} height={20} className="social-icon" /> },
        // { link: 'https://www.reddit.com/user/Concipio_mb', label: 'Reddit' },
      ],
    },
  ];

  return (
    <footer className="main-footer" id="main-footer">
      <div className="container">
        <Row className="row main-footer_content">
          <Col md={6}>
            <div className="main-footer__logo">

              <Logo className="logo-footer mb-3"/>

              <p className="text-sm">
                <span className="text-blue">&copy;</span> <strong>MotoBloq</strong> {new Date().getFullYear()}. All
                Right Reserved
              </p>
            </div>
          </Col>

          {_.map(linksBlocks, (block, i) => (
            <Col md={2} className="main-footer__menu-item" key={i}>
              <div className="main-footer__menu-title mt-xs-3 text-bold">{block.title}</div>

              {block.horizontal ?
                <Row className="align-items-center justify-content-center">
                  {_.map(block.links, ({ inApp, link, label }, j) => (
                      <Col xs="auto" sm={3} md={6} lg={3} key={j} className={classNames("text-sm pb-3")}>
                        <a href={link} target="_blank" rel="noopener noreferrer">{label}</a>
                      </Col>
                    ))
                  }
                </Row>
                :
                <ul className={classNames("main-footer__menu")}>
                  {_.map(block.links, ({ inApp, link, label }, j) => (
                      <li key={j} className={classNames("text-sm pb-2")}>
                        {inApp ?
                          <Link to={link}>{label}</Link>
                          :
                          <a href={link} target="_blank" rel="noopener noreferrer">{label}</a>
                        }
                      </li>
                    ))
                  }
                </ul>
              }



            </Col>
          ))}
        </Row>
      </div>
    </footer>
  );
};

export { Footer };
