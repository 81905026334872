import React from 'react';

import AlfaRomeoDiscoVolante from './images/Alfa-Romeo-Disco-Volante.png';
import AstonMartinV12ZagatoRed from './images/Aston-Martin-V12-Zagato-Red.png';
import AstonMartinVantageV12 from './images/Aston-Martin-Vantage-V12.png';
import AstonMartinVulcan from './images/Aston-Martin-Vulcan.png';
import Audi from './images/Audi.png';
import BlackMcLaren570SGT4 from './images/Black-McLaren-570S-GT4.png';
import BlueJaguarFTYPELuxury from './images/Blue-Jaguar-F-TYPE-Luxury.png';
import GreenLotus3Eleven from './images/Green-Lotus-3-Eleven.png';
import HondaEVSterElectric from './images/Honda-EV-Ster-Electric.png';
import InfinitiEssenceConcept from './images/Infiniti-Essence-Concept.png';
import JaguarFTYPELuxurySportsBlueCar from './images/Jaguar-F-TYPE-Luxury-Sports-Blue-Car.png';
import RedFerrari458Italia from './images/Red-Ferrari-458-Italia.png';
import RedLamborghiniHuracan from './images/Red-Lamborghini-Huracan.png';
import RedToyotaFT1 from './images/Red-Toyota-FT1.png';
import WhiteKoenigseggOne1Car from './images/White-Koenigsegg-One-1-Car.png';

/**
 * Component for showing random car previev in garage page.
 */

class CarRandom extends React.Component {
  cars = [
    {
      photo: AlfaRomeoDiscoVolante,
      name: 'Alfa Romeo Disco Volante',
    },
    {
      photo: AstonMartinV12ZagatoRed,
      name: 'Aston Martin V12 Zagato',
    },
    {
      photo: AstonMartinVantageV12,
      name: 'Aston Martin Vantage V12',
    },
    {
      photo: AstonMartinVulcan,
      name: 'Aston Martin Vulcan',
    },
    {
      photo: Audi,
      name: 'Audi',
    },
    {
      photo: BlackMcLaren570SGT4,
      name: 'Black McLaren 570SGT4',
    },
    {
      photo: BlueJaguarFTYPELuxury,
      name: 'Blue Jaguar FTYPE Luxury',
    },
    {
      photo: GreenLotus3Eleven,
      name: 'Green Lotus 3 Eleven',
    },
    {
      photo: HondaEVSterElectric,
      name: 'Honda EV Ster Electric',
    },
    {
      photo: InfinitiEssenceConcept,
      name: 'Infiniti Essence Concept',
    },
    {
      photo: JaguarFTYPELuxurySportsBlueCar,
      name: 'Jaguar FTYPE Luxury Sports',
    },
    {
      photo: RedFerrari458Italia,
      name: 'Ferrari 458 Italia',
    },
    {
      photo: RedLamborghiniHuracan,
      name: 'Lamborghini Huracan',
    },
    {
      photo: RedToyotaFT1,
      name: 'Toyota FT1',
    },
    {
      photo: WhiteKoenigseggOne1Car,
      name: 'White Koenigsegg One',
    },
  ];

  car = null;

  constructor(props) {
    super(props);

    this.car = this._getCar();
  }

  _getCar = (id = null) => {
    const car = this.cars[id || Math.round(Math.random() * (this.cars.length - 1))];
    car.id = this.cars.indexOf(car);

    car.price = (Math.random() * 2).toFixed(3);
    car.circulation = [0, Math.round(Math.random() * 300)];
    car.circulation[0] = Math.round(Math.random() * car.circulation[1]);

    return car;
  };

  render() {
    return <img src={this.car.photo} alt={this.car.name} />;
  }
}

export { CarRandom };
