import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom';


function RequireAuth({ children }) {
  const user = useSelector((state) => state.user);
  const location = useLocation();


  return (user.auth === true) ? children : <Navigate to="/login" state={{ from: location.pathname }} replace />;
}

export { RequireAuth };
