import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import './chart-tab.sass';

import { Icon } from 'app/components/elements';
import { Attributes } from 'app/components';
import { ChartElement } from '..';

class ChartTab extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    noXAxis: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }

  _getChartInfo = () => {
    const arr = _.map(this.props.data, (item) => item.value);

    const min = _.min(arr);
    const max = _.max(arr);

    return {
      chartMin: Math.round(min),
      chartMax: Math.round(max),
      chartChange: min === 0 ? 100 : ( Math.round( ( (max - min) / min ) * 10000) / 100 ) ,
    };
  };

  render() {
    const { data, noXAxis, tabs } = this.props;
    const { activeTab } = this.state;

    const { chartMin, chartMax, chartChange } = this._getChartInfo();

    return (
      <React.Fragment>
        <div className="chart__header">
          <Attributes
            theme="grey-blue"
            items={[
              {
                title: 'Lowest',
                value: (
                  <div className="icons-inline">
                    <Icon icon="priceDollar" viewBox="0 0 20 20" className="no-fill" />

                    <span>{chartMin}</span>
                  </div>
                ),
              },
              {
                title: 'Highest',
                value: (
                  <div className="icons-inline">
                    <Icon icon="priceDollar" viewBox="0 0 20 20" className="no-fill" />

                    <span>{chartMax}</span>
                  </div>
                ),
              },
              {
                title: 'Change (24h)',
                value: (
                  <div className="icons-inline">
                    <span className={chartChange > 0 ? 'text-green' : 'text-red'}>
                      {chartChange}

                      <strong>%</strong>
                    </span>
                  </div>
                ),
              },
            ]}
          />
          <ul className="chart-tabs__header">
            {_.map(tabs, ({ title }, index) => (
              <li
                key={index}
                className={classNames({ active: activeTab === index })}
                // onClick={(e) => this._tabOnClick(e, index)}
              >
                {title}
              </li>
            ))}
          </ul>
        </div>

        <ChartElement data={data} noXAxis={noXAxis} />
      </React.Fragment>
    );
  }
}

export { ChartTab };
