import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import _ from 'lodash';
import { getCarLogoImg } from 'app/utils';
import { Image } from 'app/components/elements';

import './icon.sass';

class IconLogo extends React.Component {
  static icons = {
    activity: (
      <path
        className="st0"
        d="M29.1,14.7h-4.2l-2.4-5.6c-0.3-0.4-0.8-0.9-1.2-0.9c-0.6,0-1,0.3-1.3,0.8l-5,10.7L11.1,5.1
          c0-0.6-0.6-1.1-1.2-1.1S8.7,4.4,8.4,4.8l-4.2,9.7H0.9C0.5,14.5,0,15,0,15.6v0.9c0,0.5,0.5,0.9,0.9,0.9h4.2c0.6,0,1-0.3,1.2-0.9
          l3-6.9L13,24c0,0.4,0.5,0.8,0.8,1.1c0.3,0.1,0.5,0.1,0.8,0.1c0.6,0,1.1-0.3,1.4-0.8l5.3-11.4l1.5,3.6c0.3,0.4,0.8,0.8,1.3,0.8h5.1
          c0.5,0,0.9-0.5,0.9-0.9v-0.9C30,15.2,29.6,14.7,29.1,14.7z"
      />
    ),
    alpine: (
      <path
        className="st0"
        d="M23.6,17.2L27.1,7h-3L11.9,17.2H6.7l1.9-4l-8.6,6h9.5L5,23h3.8l4.4-3.8h6.6
          L18.5,23h3.1l1.3-3.8h4.7l2.4-2H23.6z M20.5,17.2h-5l7.2-6.2"
      />
    ),
    'arrow-down': (
      <path
        className="st0"
        d="M10.6,14.9c0,0-1.9,0-4.1,0c-0.9,0-1.6,0.4-2.1,1.2c-0.4,0.7-0.3,1.6,0.2,2.4
          c2.5,3.3,6.2,7.9,8.4,10.7c0.6,0.4,1.2,0.7,2,0.7s1.3-0.3,1.8-0.9c2.3-2.8,5.9-7.5,8.4-10.7c0.6-0.7,0.6-1.6,0.3-2.4
          c-0.4-0.7-1.2-1.2-2.1-1.2c-2.1,0-4.1,0-4.1,0s0-8.4,0-12.5C19.4,1,18.3,0,17,0c-1.2,0-2.9,0-4.1,0c-1.2,0.1-2.3,1.2-2.3,2.4
          C10.6,6.4,10.6,14.9,10.6,14.9z"
      />
    ),
    bugatti: (
      <path
        className="st0"
        d="M23.1,22.6c1.8,0,2.9-1.3,2.9-2.9c0-1.6-1.1-2.9-2.9-2.9h-5.9v5.8H23.1z M23.1,12.5c1.6,0,2.6-1.2,2.6-2.6s-1-2.6-2.6-2.6
          h-5.9v5.1H23.1z M8.9,16.8v1.4H5.3v-7.2h3.6v1.4h3.5V7.4H3.6v1.8H0l0-1.8V3.7h23.1c3.6,0,6.6,2.9,6.6,6.6c0,1.6-0.6,3.1-1.6,4.3
          c1.2,1.2,2,2.9,2,4.8c0,3.8-3.1,6.9-6.9,6.9H0l0-3.6v-1.8h3.6v1.8h8.8v-5.8L8.9,16.8L8.9,16.8z"
      />
    ),
    'calendar-check': (
      <path
        className="st0"
        d="M22.9,16.1l-8.6,8.6c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2l-4.8-4.8c-0.1-0.1-0.2-0.2-0.2-0.4
          c0-0.1,0.1-0.3,0.2-0.4l0.8-0.8c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.3,0.1,0.4,0.2l3.7,3.7l7.4-7.4c0.1-0.1,0.2-0.2,0.4-0.2
          c0.1,0,0.3,0.1,0.4,0.2l0.8,0.8c0.1,0.1,0.2,0.2,0.2,0.4C23,15.9,23,16,22.9,16.1z M3.2,27.9h23.6V10.7H3.2V27.9z M9.6,7.5V2.7
          c0-0.2-0.1-0.3-0.2-0.4C9.4,2.2,9.3,2.1,9.1,2.1H8c-0.2,0-0.3,0.1-0.4,0.2C7.6,2.4,7.5,2.5,7.5,2.7v4.8c0,0.2,0.1,0.3,0.2,0.4
          C7.8,8,7.9,8,8,8h1.1C9.3,8,9.4,8,9.5,7.9C9.6,7.8,9.6,7.7,9.6,7.5z M22.5,7.5V2.7c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2
          h-1.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.4v4.8c0,0.2,0.1,0.3,0.2,0.4C20.6,8,20.7,8,20.9,8H22c0.2,0,0.3-0.1,0.4-0.2
          C22.4,7.8,22.5,7.7,22.5,7.5z M28.9,6.4v21.4c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6H3.2c-0.6,0-1.1-0.2-1.5-0.6
          c-0.4-0.4-0.6-0.9-0.6-1.5V6.4c0-0.6,0.2-1.1,0.6-1.5s0.9-0.6,1.5-0.6h2.1V2.7c0-0.7,0.3-1.4,0.8-1.9S7.3,0,8,0h1.1
          c0.7,0,1.4,0.3,1.9,0.8s0.8,1.2,0.8,1.9v1.6h6.4V2.7c0-0.7,0.3-1.4,0.8-1.9C19.5,0.3,20.2,0,20.9,0H22c0.7,0,1.4,0.3,1.9,0.8
          c0.5,0.5,0.8,1.2,0.8,1.9v1.6h2.1c0.6,0,1.1,0.2,1.5,0.6C28.7,5.3,28.9,5.8,28.9,6.4z"
      />
    ),
    camera: (
      <g>
        <path
          className="st0"
          d="M28.7,6.9h-7.4L20,4.3h-8.1l-1.4,2.6H6.7C6.7,6.5,6.5,6,5.9,6H3.3C2.6,6,2.5,6.4,2.5,6.9H1.3
            C0.6,6.9,0,7.4,0,8.2v14.7c0,0.7,0.6,1.3,1.3,1.3h9.8c1.3,0.9,3.1,1.5,4.9,1.5c2,0,3.7-0.6,5-1.5h7.7c0.7,0,1.3-0.5,1.3-1.3V8.2
            C30,7.5,29.6,6.9,28.7,6.9z M5.1,19.7c0,1.2-2.1,1.2-2.1,0v-7.9c0-1.4,2.1-1.4,2.1,0V19.7z M13.2,5.7H18l0.8,1.2h-6.2L13.2,5.7z
             M16,24.1c-3.9,0-7-3.3-7-7.2c0-3.9,3.2-7,7-7c3.9,0,7.2,3.1,7.2,7C23.2,20.9,19.9,24.1,16,24.1z M27.2,10.2h-3.8
            c-1.4,0-1.4-1.8,0-1.8h3.8C28.4,8.4,28.4,10.2,27.2,10.2z"
        />
        <path
          className="st0"
          d="M16,11.5c-2.8,0-5.4,2.4-5.4,5.4s2.5,5.4,5.4,5.4c3,0,5.4-2.4,5.4-5.4C21.4,14.1,19,11.5,16,11.5z M16,20.5
            c-1.9,0-3.4-1.6-3.4-3.6s1.5-3.5,3.4-3.5c2,0,3.6,1.5,3.6,3.5S17.9,20.5,16,20.5z"
        />
      </g>
    ),
    'checkbox-active': (
      <g>
        <path
          className="st0"
          d="M26.3,5.1c-1.2-0.3-2.4,0-3.3,0.9l0,0c-2.1-1.8-5-3-8-3C8.4,3,3,8.4,3,15s5.4,12,12,12
            s12-5.4,12-12c0-0.9-0.2-1.8-0.3-2.7l1.4-1.3c0.5-0.4,0.8-1.1,0.9-1.7C29.6,11.1,30,13,30,15c0,8.3-6.8,15-15,15S0,23.3,0,15
            S6.8,0,15,0C19.5,0,23.5,1.9,26.3,5.1z"
        />

        <path
          className="st1"
          d="M15,20.5c-0.4,0-0.8-0.1-1.1-0.4l-5.5-5.5c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0l4.4,4.4l9.4-9.4
            c0.6-0.6,1.5-0.6,2.1,0c0.6,0.6,0.6,1.5,0,2.1L16.1,20.1C15.8,20.4,15.4,20.5,15,20.5z"
        />
      </g>
    ),
    chevrolet: (
      <path
        className="st0"
        d="M20.4,12v-1.8h-5.4H9.6V12H2.9L0,17.9h9.6v1.9h5.3h5.4v-1.9H27l3-5.9L20.4,12L20.4,12L20.4,12z M25.8,16.1h-7v1.8h-3.9h-3.6
        v-1.8H2.9L4,13.7h7.3V12h3.6h3.9v1.7H27L25.8,16.1L25.8,16.1L25.8,16.1z"
      />
    ),
    circulation: (
      <path
        className="st0"
        d="M24.9,18c-0.8,0-1.5,0.2-2.1,0.5L18,10.4c1.3-0.9,2.1-2.4,2.1-4.2c0-2.9-2.3-5.1-5.1-5.1
          S9.9,3.3,9.9,6.1c0,1.8,0.9,3.3,2.1,4.2l-4.6,8.1C6.6,18.3,5.9,18,5.1,18C2.3,18,0,20.4,0,23.3s2.3,5.1,5.1,5.1
          c2.7,0,4.9-2.1,5.1-4.6h9.5c0.3,2.6,2.4,4.6,5.1,4.6c2.8,0,5.1-2.3,5.1-5.1S27.8,18,24.9,18z M11,6.1c0-2.2,1.8-4.2,4.2-4.2
          s4.2,1.8,4.2,4.2s-2.1,4.2-4.4,4.2S11,8.4,11,6.1z M12.9,11c0.6,0.1,1.3,0.4,2.1,0.4s1.5-0.1,2.1-0.4l4.7,8.1
          c-1.2,0.9-2,2.1-2.1,3.6h-9.3c-0.1-1.5-0.9-2.9-2.1-3.6L12.9,11z M5.1,27.3c-2.3,0-4.2-1.8-4.2-4.2c0-2.3,1.8-4.2,4.2-4.2
          s4.2,1.8,4.2,4.2S7.5,27.3,5.1,27.3z M24.9,27.3c-2.3,0-4.2-1.8-4.2-4.2c0-2.3,1.8-4.2,4.2-4.2c2.3,0,4.2,1.8,4.2,4.2
          C28.9,25.5,27.2,27.3,24.9,27.3z M5.1,26.4c-1.8,0-3.2-1.5-3.2-3.2c0-1.8,1.5-3.2,3.2-3.2s3.1,1.5,3.1,3.2
          C8.4,25.1,6.9,26.4,5.1,26.4z"
      />
    ),
    country: (
      <path
        className="st0"
        d="M15,0c2.7,0,5.2,0.7,7.5,2s4.1,3.2,5.5,5.5s2,4.8,2,7.5s-0.7,5.2-2,7.5s-3.2,4.1-5.5,5.5s-4.8,2-7.5,2s-5.2-0.7-7.5-2
	S3.4,24.8,2,22.5S0,17.7,0,15s0.7-5.2,2-7.5S5.2,3.4,7.5,2S12.3,0,15,0z M20.4,10.2c0,0-0.1,0.1-0.2,0.2s-0.2,0.2-0.3,0.2
	c0,0,0.1,0,0.1-0.1s0.1-0.1,0.1-0.2s0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.5-0.2,1-0.2c0.4-0.1,0.8,0,1,0.2
	c0,0,0-0.1,0.2-0.3s0.2-0.2,0.3-0.2c0,0,0.1-0.1,0.3-0.1s0.3-0.1,0.3-0.1l0-0.4c-0.2,0-0.3,0-0.3-0.1s-0.1-0.2-0.1-0.4
	c0,0,0,0.1-0.1,0.2c0-0.1,0-0.1-0.1-0.2s-0.1,0-0.2,0s-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.3-0.1s-0.1-0.2-0.2-0.3s-0.1-0.3-0.1-0.3
	c0-0.1-0.1-0.1-0.2-0.2S21.7,7,21.7,7c0,0,0-0.1,0-0.1s0-0.1-0.1-0.1s0-0.1-0.1-0.1s-0.1,0-0.1,0s-0.1,0-0.1,0.1s-0.1,0.1-0.1,0.2
	S21.1,7,21.1,7c0,0-0.1,0-0.1,0s-0.1,0-0.1,0s0,0-0.1,0.1s-0.1,0.1-0.1,0.1c0,0-0.1,0-0.2,0.1s-0.1,0-0.2,0c0.2-0.1,0.2-0.1,0-0.2
	c-0.1-0.1-0.2-0.1-0.3-0.1c0.1-0.1,0.2-0.1,0.1-0.2S20.1,6.5,20,6.4h0.1c0-0.1-0.1-0.1-0.2-0.2s-0.2-0.1-0.3-0.2S19.4,6,19.3,5.9
	c-0.1-0.1-0.3-0.1-0.7-0.2s-0.6-0.1-0.6,0c-0.1,0.1-0.1,0.1-0.1,0.2s0,0.1,0.1,0.3s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3
	S17.8,6.9,17.8,7c0,0.1,0.1,0.2,0.3,0.3s0.2,0.3,0.2,0.4c0,0.1-0.1,0.2-0.3,0.3s-0.3,0.2-0.3,0.2c-0.1,0.1-0.1,0.2,0,0.4
	s0.1,0.2,0.2,0.3c0,0,0,0.1,0,0.1s0,0.1-0.1,0.1s-0.1,0.1-0.1,0.1s-0.1,0-0.1,0.1l-0.1,0c-0.1,0.1-0.3,0-0.4-0.1s-0.2-0.3-0.3-0.5
	c-0.1-0.3-0.2-0.5-0.3-0.6c-0.3-0.1-0.5-0.1-0.6,0c-0.1-0.2-0.3-0.3-0.8-0.5c-0.3-0.1-0.7-0.1-1.1-0.1c0.1,0,0.1-0.1,0-0.3
	C14,7,13.8,6.9,13.7,7c0-0.1,0.1-0.2,0.1-0.3s0-0.2,0-0.3c0-0.2,0.1-0.3,0.2-0.4c0,0,0.1-0.1,0.1-0.2s0.1-0.2,0.2-0.3s0-0.1,0-0.1
	c0.5,0.1,0.8,0,1-0.2c0.1-0.1,0.1-0.2,0.2-0.3s0.2-0.3,0.2-0.3C15.9,4.4,16,4.4,16,4.4s0.2,0,0.3,0.1s0.2,0.1,0.3,0.1
	c0.2,0,0.3-0.1,0.3-0.2s0-0.3-0.1-0.4c0.2,0,0.2-0.1,0.1-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.1-0.3,0-0.5,0.1
	c-0.1,0.1-0.1,0.1,0,0.2c0,0-0.1,0.1-0.2,0.2s-0.2,0.3-0.3,0.3s-0.2,0-0.3-0.1c0,0,0-0.1-0.1-0.3S15.1,3.6,15,3.6
	c-0.1,0-0.2,0.1-0.3,0.3c0-0.1,0-0.2-0.2-0.3S14.2,3.5,14,3.5c0.2-0.2,0.2-0.3-0.2-0.5c-0.1-0.1-0.2-0.1-0.4-0.1s-0.3,0-0.4,0.1
	C13,3,13,3.1,13,3.2s0,0.1,0.1,0.2s0.1,0.1,0.2,0.1s0.1,0.1,0.2,0.1s0.1,0,0.2,0.1c0.2,0.1,0.2,0.2,0.2,0.3c0,0-0.1,0-0.2,0.1
	S13.5,4,13.5,4S13.4,4,13.3,4.1c0,0.1,0,0.1,0,0.3s0,0.2,0,0.3c-0.1-0.1-0.1-0.2-0.2-0.3S13,4,13,3.9c0.1,0.1-0.1,0.2-0.5,0.1
	l-0.2,0c-0.1,0-0.2,0-0.3,0s-0.3,0-0.4,0S11.4,4,11.3,3.9c-0.1-0.1-0.1-0.2,0-0.4c0-0.1,0-0.1,0.1,0c-0.1,0-0.1-0.1-0.2-0.2
	S11,3.2,11,3.2C10.4,3.4,9.8,3.6,9.2,4c0.1,0,0.2,0,0.2,0c0.1,0,0.1-0.1,0.3-0.1s0.2-0.1,0.2-0.1c0.4-0.2,0.7-0.2,0.8-0.1l0.1-0.1
	c0.2,0.2,0.3,0.4,0.4,0.5c-0.1-0.1-0.3-0.1-0.6,0c-0.3,0.1-0.4,0.2-0.4,0.2c0.1,0.2,0.1,0.3,0.1,0.4c-0.1,0-0.1-0.1-0.2-0.2
	S9.8,4.2,9.7,4.1S9.5,4,9.4,4C9.2,4,9.1,4,9,4c-1.9,1-3.4,2.5-4.6,4.3c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0,0.1,0.1,0.1,0.2s0,0.2,0,0.2
	s0.1,0,0.2-0.1C5.1,9,5.2,9.1,5.1,9.2c0,0,0.3,0.2,0.9,0.5c0.2,0.2,0.4,0.4,0.4,0.4c0,0.1,0,0.3-0.2,0.4c0,0-0.1-0.1-0.2-0.2
	s-0.2-0.1-0.2-0.1c0,0.1,0,0.2,0,0.4s0.1,0.3,0.2,0.2c-0.1,0-0.2,0.1-0.2,0.3s0,0.4,0,0.7s0,0.4,0,0.5l0,0c0,0.2,0,0.4,0.1,0.7
	s0.3,0.4,0.4,0.4c-0.2,0,0,0.3,0.4,0.8c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0.1,0.1,0.2,0.1s0.2,0.1,0.3,0.2s0.1,0.1,0.2,0.2
	c0.1,0.1,0.1,0.2,0.2,0.4s0.2,0.4,0.3,0.5c0,0.1,0,0.2,0.2,0.4s0.2,0.3,0.2,0.4c0,0,0,0,0,0s0,0,0,0c0,0.1,0.1,0.2,0.3,0.3
	s0.3,0.2,0.3,0.3c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.2,0c0-0.3-0.1-0.7-0.5-1.2C8.6,16.2,8.4,16,8.4,16
	c0-0.1-0.1-0.2-0.1-0.3s-0.1-0.2-0.1-0.3c0,0,0.1,0,0.1,0s0.1,0,0.2,0.1s0.1,0.1,0.1,0.1s0.1,0,0,0.1c0,0.1,0,0.2,0,0.3
	s0.1,0.3,0.2,0.4s0.2,0.2,0.3,0.4s0.2,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.4s0.1,0.3,0,0.3c0.1,0,0.2,0.1,0.4,0.2s0.3,0.3,0.3,0.4
	c0.1,0.1,0.1,0.3,0.2,0.5s0.1,0.4,0.1,0.5c0,0.1,0.1,0.2,0.2,0.3s0.2,0.1,0.2,0.2l0.3,0.2l0.3,0.1c0.1,0,0.2,0.1,0.4,0.2
	s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.2-0.1,0.3-0.1c0.2,0,0.4,0.1,0.6,0.3s0.3,0.4,0.4,0.4
	c0.5,0.2,0.8,0.3,1.1,0.2c0,0,0,0.1,0,0.1s0.1,0.2,0.2,0.3s0.1,0.2,0.2,0.3s0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.3
	s0.3,0.2,0.4,0.3c0.1-0.1,0.1-0.1,0.1-0.2c0,0.1,0,0.2,0.1,0.4s0.2,0.2,0.4,0.2c0.2,0,0.3-0.2,0.3-0.6c-0.4,0.2-0.7,0.1-1-0.4
	c0,0,0,0,0-0.1s-0.1-0.1-0.1-0.2s0-0.1,0-0.2s0-0.1,0-0.1s0-0.1,0.1-0.1c0.1,0,0.2,0,0.2-0.1s0-0.1,0-0.2s-0.1-0.2-0.1-0.3
	c0-0.1-0.1-0.2-0.2-0.4s-0.2-0.3-0.2-0.3c-0.1,0.1-0.2,0.2-0.3,0.2s-0.2-0.1-0.3-0.2c0,0,0,0,0,0.1s0,0.1,0,0.1c-0.2,0-0.3,0-0.3,0
	c0,0,0-0.2,0-0.3s0-0.3,0.1-0.4c0-0.1,0-0.1,0.1-0.2s0.1-0.2,0.1-0.3s0.1-0.2,0.1-0.2s0-0.1-0.1-0.2s-0.2-0.1-0.3,0
	c-0.2,0-0.4,0.1-0.5,0.4c0,0,0,0.1-0.1,0.2s-0.1,0.2-0.1,0.2s-0.1,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.5,0s-0.4,0-0.5-0.1
	c-0.2-0.1-0.3-0.3-0.4-0.6s-0.2-0.5-0.2-0.7c0-0.1,0-0.3,0-0.5s0.1-0.4,0.1-0.5s0-0.3-0.1-0.5c0,0,0.1-0.1,0.2-0.2s0.1-0.2,0.2-0.2
	c0,0,0.1,0,0.1,0s0.1,0,0.1,0s0.1,0,0.1,0s0-0.1,0.1-0.1c0,0,0,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0.1,0,0.3,0,0.6,0s0.5,0,0.5,0
	c0.2,0.1,0.3,0.1,0.4,0c0,0,0-0.1,0-0.2s0-0.2,0-0.3c0.1,0.4,0.3,0.4,0.6,0.2c0,0,0.1,0.1,0.3,0.1s0.3,0.1,0.3,0.1
	c0,0,0.1,0.1,0.1,0.1s0.1,0.1,0.1,0.1s0.1,0,0.1,0s0.1-0.1,0.2-0.1c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.5,0.3,0.8,0.4,0.9
	c0.1,0,0.2,0.1,0.2,0s0.1-0.1,0.1-0.2s0-0.2,0-0.3s0-0.2,0-0.2l0-0.2v-0.4l0-0.2c-0.2,0-0.3-0.1-0.4-0.2s0-0.2,0-0.4
	s0.2-0.2,0.3-0.4c0,0,0.1,0,0.2-0.1s0.2-0.1,0.3-0.1s0.2-0.1,0.2-0.2c0.3-0.2,0.4-0.5,0.3-0.7c0.1,0,0.2-0.1,0.2-0.2
	c0,0,0,0-0.1-0.1s-0.1-0.1-0.1-0.1s-0.1,0-0.1,0c0.1-0.1,0.1-0.2,0-0.3c0.1,0,0.1-0.1,0.1-0.2s0.1-0.2,0.1-0.2
	c0.1,0.2,0.3,0.2,0.4,0c0.1-0.1,0.1-0.2,0-0.3c0.1-0.1,0.2-0.2,0.4-0.2s0.3-0.1,0.4-0.2c0.1,0,0.1,0,0.2,0s0-0.1,0-0.2
	s0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.3-0.2s0.2-0.1,0.3-0.1l0.3-0.2c0-0.1,0-0.1,0-0.1c0.2,0,0.4,0,0.6-0.2c0.1-0.1,0.1-0.3-0.1-0.4
	c0-0.1,0-0.1-0.1-0.2s-0.2-0.1-0.3-0.1c0,0,0.1,0,0.2,0s0.2,0,0.2,0c0.2-0.1,0.1-0.2-0.1-0.3C21,9.9,20.7,10,20.4,10.2z M17.2,27.3
	c2.7-0.5,5-1.7,6.9-3.7c0,0-0.1-0.1-0.2-0.1s-0.2,0-0.2-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c0-0.1,0-0.2,0-0.3s-0.1-0.1-0.2-0.2
	s-0.1-0.1-0.2-0.2s-0.2-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1s-0.1-0.1-0.1-0.1s-0.1,0-0.1-0.1s-0.1-0.1-0.2,0s-0.1,0-0.2,0l-0.1,0
	c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1s-0.1,0-0.1,0.1s0,0,0,0c-0.3-0.2-0.5-0.4-0.7-0.4c-0.1,0-0.1,0-0.2-0.1s-0.1-0.1-0.2-0.1
	s-0.1,0-0.2,0s-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.2-0.1,0.3s0,0.2,0,0.3c-0.1-0.1-0.1-0.2,0-0.3s0.1-0.3,0-0.4c0-0.1-0.1-0.1-0.2-0.1
	s-0.2,0-0.2,0.1s-0.1,0.1-0.2,0.2s-0.1,0.1-0.2,0.1s-0.1,0-0.2,0.1s-0.1,0.1-0.2,0.1c0,0.1-0.1,0.1-0.1,0.2s-0.1,0.2-0.1,0.2
	c0-0.1-0.1-0.1-0.2-0.1s-0.2-0.1-0.2-0.1c0,0.1,0.1,0.4,0.1,0.7s0.1,0.6,0.1,0.7c0.1,0.4,0,0.7-0.2,0.9c-0.4,0.3-0.5,0.6-0.6,0.8
	c-0.1,0.3,0,0.5,0.2,0.5c0,0.1-0.1,0.2-0.2,0.4s-0.1,0.3-0.1,0.4C17.1,27.1,17.1,27.2,17.2,27.3z"
      />
    ),
    clear: (
      <path
        className="st0"
        d="M15,0C6.8,0,0,6.8,0,15s6.8,15,15,15s15-6.8,15-15S23.3,0,15,0z M22.3,19.8l-2.7,2.7L15,17.7l-4.8,4.8
          l-2.7-2.7l4.8-4.8l-4.6-4.8l2.7-2.7l4.7,4.8l4.8-4.8l2.7,2.7L17.7,15L22.3,19.8z"
      />
    ),
    colour: (
      <path
        className="st0"
        d="M20,5.1c5.5,0,10,4.4,10,9.9s-4.5,10-10,10c-1.4,0-2.7-0.3-3.8-0.8c3-2,4.9-5.3,4.9-9.2
          s-2-7.2-4.9-9.2C17.3,5.4,18.6,5.1,20,5.1z M10.1,25.2C4.5,25.2,0,20.6,0,15.1C0,9.5,4.5,5,10.1,5s10.1,4.5,10.1,10.1
          C20.2,20.6,15.6,25.2,10.1,25.2z M12.2,8.8C12.3,8.8,12.3,8.8,12.2,8.8l5.8,5.6c0,0.2,0,0.4,0,0.6c0,0,0,0.1,0,0.1l-6-6
          C12.1,9,12.1,9,12.2,8.8C12.2,8.8,12.2,8.8,12.2,8.8z M10.1,6.3c-4.8,0-8.8,3.9-8.8,8.8s3.9,8.8,8.8,8.8s8.8-3.9,8.8-8.8
          C18.9,10.2,14.9,6.3,10.1,6.3z M17.6,17.6L11,10.9c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0-0.1,0.1-0.1l6.6,6.6
          C17.8,17.3,17.7,17.4,17.6,17.6z M16.7,19.5l-6.4-6.5c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0.1-0.3l6.6,6.6
          C16.9,19.2,16.8,19.4,16.7,19.5z M10.1,15.1l5.6,5.7c-0.1,0.1-0.2,0.2-0.3,0.3l-5.3-5.3c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2
          C10.1,15.4,10.1,15.2,10.1,15.1z M14.1,22c-0.2,0.1-0.3,0.2-0.4,0.2l-2.4-2.4c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.2
          c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1-0.1-0.2v0L14.1,22z M13.7,7.9l3.5,3.5
          C16.4,9.9,15.2,8.7,13.7,7.9z"
      />
    ),
    confirm: (
      <path
        className="st0"
        d="M10.9,24.5c-0.4,0-0.8-0.1-1.1-0.4l-8.9-8.8c-0.6-0.6-0.6-1.5,0-2.1s1.5-0.6,2.1,0l7.8,7.8l16-16
          c0.6-0.6,1.5-0.6,2.1,0s0.6,1.5,0,2.1L12,24.1C11.7,24.4,11.3,24.5,10.9,24.5z"
      />
    ),
    copy: (
      <path
        className="st0"
        d="M21.2,0H4.9C3.4,0,2.1,1.2,2.1,2.7v19.1h2.7V2.7h16.4V0z M25.3,5.5h-15c-1.5,0-2.7,1.2-2.7,2.7
          v19.1c0,1.5,1.2,2.7,2.7,2.7h15c1.5,0,2.7-1.2,2.7-2.7V8.2C28.1,6.7,26.8,5.5,25.3,5.5z M25.3,27.3h-15V8.2h15V27.3z"
      />
    ),
    dodge: (
      <g>
        <path
          className="st0"
          d="M27.1,0.7C25.9,0.4,20.1,0,15.1,0C9.9,0,4.1,0.4,2.9,0.7C2.3,0.8,0.4,1.3,0.4,3.4c0,2.8,0.1,16.7,1.5,18.7
            c2.5,3.4,12.4,7.7,12.9,7.9c0.1,0.1,0.3,0.1,0.4,0c0.4-0.2,10.8-4.5,12.9-7.9c1.4-2.2,1.5-18.5,1.5-18.6
            C29.6,1.3,27.7,0.8,27.1,0.7z M27.1,21.4c-1.7,2.8-10.6,6.7-12.1,7.4c-1.5-0.7-10.1-4.6-12.2-7.4c-0.9-1.3-1.3-11.7-1.3-18
            c0-0.9,0.4-1.3,1.6-1.6c1.1-0.2,6.7-0.7,11.9-0.7c5.1,0,10.7,0.4,11.8,0.7c1.1,0.3,1.6,0.7,1.6,1.6C28.5,6.6,28.1,19.9,27.1,21.4z"
        />
        <g>
          <path
            className="st0"
            d="M15.5,7.9c0.2-0.5,1.3-1.3,1.5-1.1c0-0.2,0.9-1,1.2-0.8c-0.1-0.2,1.1-1,1.4-0.8c0.1-0.3,1.8-0.5,2.1-0.2
              c0.2-0.2,1.7,0.1,1.7,0.5c0.3-0.2,1.8,1.4,1.8,2.2c0,0.3-1.4-1-2.3-0.7c-1.5,0.5-3.4,2.1-5.1,4.5c-0.5,0.7-1.6,0.9-1.9-0.2
              C15.8,11.2,15.3,8.4,15.5,7.9L15.5,7.9z"
          />
          <path
            className="st0"
            d="M15.3,22.8c-0.2-0.4-0.2-0.9,0-1.1c0.3-0.3,1.1,0,1.1-0.3c0.2-0.6-0.3-0.8-0.2-1.6c0-0.3,0.6-2.3,0.7-2.5
              c0.1-0.2,0.8-0.4,1-0.5c0.1-0.1,0.2-0.3,0.2-0.6c-0.9-0.3-1.1,0.6-1.2,0.5c-0.1,0,0.1-0.9,0.2-1c0.1-0.1,1.1-0.3,1.3-0.5
              c0.1-0.2,0.6-2,0-2.1c0,0,0-0.1,0-0.1c1.2,0.3,2.1-5.6,5.1-5.6c3,0,2.9,4.4,2.7,4.6c-0.3,0.3-0.8,0.2-0.9,0.5
              c-0.2,2.7-2.6,5.6-3.3,5.3c-0.4-0.2-0.1-1.5,0.1-1.5c-0.2-0.1,0-1.5,0.2-1.5c-0.2-0.1,0-1.4,0.2-1.4c-0.1-0.1-0.1-1-0.3-1.4
              c-0.2-0.3-0.9-0.5-1,0.3c0.5-0.2,0.5,1.3,0.3,1.5c-0.2,0.3-1.4,0.8-1.8,1.1c0,0.6-0.6,3.1-1,3.8c-0.4,0.7-0.9,1.1-1.1,1.7
              c-0.3,0.6-0.1,1.7-0.7,2.2C16.8,22.9,15.4,23,15.3,22.8L15.3,22.8z"
          />
          <path
            className="st0"
            d="M25.8,12.9c0.1-0.2,0.7-0.3,0.9-0.2c0.2,0.1,0.4,2.1,0.2,2.1c0.2,0.1-0.1,1.6-0.3,1.6c0.2,0.1-0.2,1.5-0.5,1.5
              c0.2,0.2-0.6,1.4-0.8,1.3c-0.1,1.2-3.7,1.8-3.6,0.8c0-0.5,0.6,0.2,3-3.6C25.7,14.7,25.5,13.3,25.8,12.9L25.8,12.9z"
          />
          <path
            className="st0"
            d="M14.6,7.9c-0.3-0.6-1.3-1.3-1.5-1.1c0-0.2-0.9-1-1.2-0.8c0.1-0.2-1.1-1-1.4-0.8c-0.1-0.3-1.8-0.5-2.1-0.2
              C8.3,4.7,6.8,5.1,6.7,5.5C6.5,5.2,4.9,6.8,4.9,7.7c0,0.3,1.4-1,2.3-0.7c1.5,0.5,3.4,2.1,5.1,4.5c0.5,0.7,1.6,0.9,1.9-0.2
              C14.2,11.3,14.8,8.5,14.6,7.9L14.6,7.9z"
          />
          <path
            className="st0"
            d="M14.7,22.9c0.2-0.4,0.2-0.9,0-1.1c-0.3-0.3-1.1,0-1.1-0.3c-0.2-0.6,0.3-0.8,0.2-1.6c0-0.3-0.6-2.3-0.7-2.5
              c-0.1-0.2-0.8-0.4-1-0.5c-0.1-0.1-0.2-0.3-0.2-0.6c0.9-0.3,1.1,0.6,1.2,0.5c0.1,0-0.1-0.9-0.2-1c-0.1-0.1-1.1-0.3-1.3-0.5
              c-0.1-0.2-0.6-2,0-2.1c0,0,0-0.1,0-0.1c-1.2,0.3-2.1-5.6-5.1-5.6c-3,0-2.9,4.4-2.7,4.6c0.3,0.3,0.8,0.2,0.9,0.5
              C4.9,15.4,7.3,18.3,8,18c0.4-0.2,0.1-1.5-0.1-1.5C8,16.4,7.8,15,7.6,15c0.2-0.1,0-1.4-0.2-1.4c0.1-0.1,0.1-1,0.3-1.4
              c0.2-0.3,0.9-0.5,1,0.3c-0.5-0.2-0.5,1.3-0.3,1.5c0.2,0.3,1.4,0.8,1.8,1.1c0,0.6,0.6,3.1,1,3.8c0.4,0.7,0.9,1.1,1.1,1.7
              c0.3,0.6,0.4,1.9,0.7,2.2C13.3,23,14.6,23.1,14.7,22.9L14.7,22.9z"
          />
          <path
            className="st0"
            d="M15,24.1c-0.9,0-1.8-0.7-1.6-0.8c0.3-0.3,0.9,0.2,1.6-0.1c0.7,0.3,1.2-0.2,1.6,0.1C16.9,23.4,16,24.1,15,24.1L15,24.1z"
          />
          <path
            className="st0"
            d="M4.3,12.9c-0.1-0.2-0.7-0.3-0.9-0.2c-0.2,0.1-0.4,2-0.2,2.1c-0.2,0.1,0.1,1.6,0.3,1.6c-0.2,0.1,0.2,1.5,0.5,1.5
              c-0.2,0.2,0.6,1.4,0.8,1.3C4.9,20.4,8.5,21,8.4,20c0-0.5-0.6,0.2-3-3.6C4.3,14.7,4.5,13.3,4.3,12.9L4.3,12.9z"
          />
        </g>
      </g>
    ),
    'dodge-hellcat': (
      <path
        className="st0"
        d="M30,14.5c0.3-1.2-1.5-4.1-2-4.7c-2.6-3.7-7-6.5-11.4-8.5l0,0c0,0.7,0.2,1.3,0.4,1.8l0,0c-1.5-0.3-2.5-0.8-3.7-1.3
          c-1.3-0.6-2.6-1-4-1.4C8.5,0.2,7.6,0,6.6,0c0,1,0.4,1.9,0.7,2.6C5.4,3,4.1,3.1,2.6,3.7C1.7,4.1,0.9,4.8,0.1,5.2L0,5.3
          c2.2-0.8,6-1.5,8.5-0.5c0.6,0.3,1.1,0.7,1.6,1C9.6,4.6,8.5,3.2,8.5,1.5C9.1,1.4,9.8,1.8,10.3,2c1.5,0.6,2.7,1.3,4.1,2
          c1.6,0.8,3.8,1.1,5.5,1.8c2.5,1,4.5,2.5,6,4.5c0.6,0.8,2.1,2.6,1.8,4c-1,0.6-3.3-0.2-4.1-0.5c-2.9-1.3-4-4.2-8.1-4.4l0,0
          c0.7,2.1,1.9,3.9,3.4,5.2c0.4,0.3,0.7,0.8,1.2,1c0,0,0,0,0.1,0.1c0.1-0.2,0-0.6,0-0.8c1.2-0.3,2.8,0,3.9,0.2
          c2.5,0.6,4.4,0.8,4.5,3.9c-2.2,0.1-3.8-0.3-5.3-1l0,0c0.1,0.9,1.8,2.2,2.3,2.9c0.2,0.2,0.4,0.3,0.5,0.7c-0.2,0.4-1.7,1-2.4,0.8
          c-0.4-0.1-0.8-0.5-1.1-0.7c-1.8-1.4-3.1-3.2-5.1-4.5c-0.6-0.4-1.7-1-2.9-0.8c-3,0.5-2.5,8.5-1.4,10.9c1.3-0.2,2.8,0,4,0.2
          c-0.7,1-3.5,1.8-5.4,1.5c-0.8-0.1-1.8,0-2.1-0.6c-0.1-0.4,0.5-1.1,0.6-1.5c0.5-1.1,0.8-2.2,1-3.6c0.6-4.1-2-7.5-3.7-9.4
          c0.1-0.8,1.9-0.9,2.8-1c2.8-0.5,5.1,1.3,6.8,2.2c-0.4-1.1-1.7-1.8-2.7-2.4c-3.5-2-7.2-2.1-10.6,0c-0.6,0.3-1.3,0.7-1.6,1.2
          c3,0.9,5.9,3.1,7,5.9C10.5,23,9.5,25.9,8,28c-0.3,0.4-0.6,1-1,1.2c-0.1,0.1-0.2,0.2-0.3,0.4C8,30.1,10.1,30,11.9,30
          c4.2,0,8.4-1,8.2-5.2c0,0,0,0-0.1,0c-0.3,0.5-0.7,0.9-1.3,1.3c-0.6-0.1-1.2-1.1-1-1.8c0.1-0.4,0.3-0.6,0.4-0.9
          c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.8,0.3-1.5,0.7-2C18,21,17.9,21,17.8,20.9c-0.1-0.3,0-0.7,0.1-1l0,0c-1.2,0.6-1.2,3.5-1,5.2
          c0,0.3,0.1,0.7,0.1,1c0.1,0.3,0.5,0.4,0.7,0.6c0,0,0,0,0.1,0c-0.4,0.1-1.7-0.3-2.2-0.4c0,0,0-0.1-0.1-0.1c0.5-0.6,1-1.2,1-2.4
          c-0.8,0.7-1.4,1.6-3,1.6c-0.3-0.3-0.4-1-0.4-1.6c0.3-0.4,1-0.6,1.1-1.1c-0.3-0.2-0.7-0.4-1-0.6c-0.2-1.2,0.6-1.5,1-2.1
          c-0.1-0.2-0.5-0.4-0.7-0.4c0.1-0.6,0.7-1.6,1.1-1.9c0.7-0.4,2.9,0.3,3.2,0.7c0.4,0.4,0.4,1.6,0.4,2.3c0.4-0.1,0.8-0.3,1-0.4
          c0-0.2,0-0.5,0-0.7c0.4,0.2,0.9,0.7,1,1.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.3,0,1.6,0.1,1.9c0.7-0.1,1-0.7,1.5-1
          c0.2,0.3,0.4,0.5,0.5,0.8c-0.4,0.8-0.6,1.9-0.9,2.9v0.1c1-0.1,2.1-1.5,2.6-2.2c0.8-0.2,2.3-0.1,3,0.1c0,0,0,0,0,0.1
          c-0.9,0.4-1.1,1-1,2.3c1.2-0.7,2.8-2.5,3.3-3.8c0.3-0.6,0.3-1.3,0.4-2.1c0.2-1.3-0.5-2.6-0.3-3.7C29.4,15.4,29.8,15.1,30,14.5z
           M18,3.3c-0.1-0.2-0.2-0.5-0.2-0.7C18.4,3,19.2,3.4,19.6,4C19.1,3.8,18.5,3.6,18,3.3z M18.6,11c0.9,0.4,1.4,1,2.1,1.5
          c0.2,0.2,0.5,0.4,0.7,0.6c0,0.1,0.1,0.1,0.1,0.2C20.1,13.3,18.7,12.4,18.6,11z M27.8,22.5c-0.3-0.2-0.4-0.5-0.6-0.9
          c0.4-0.3,1-0.7,1.3-1.2C28.7,20.9,28.1,22.3,27.8,22.5z"
      />
    ),
    down: (
      <path
        className="st0"
        d="M22.1,24.2c-0.8,0-1.3-0.7-1.3-1.3c0-0.7,0.7-1.2,1.3-1.2h3.5L14.5,11.3l-4,3.3c-0.3,0.3-0.6,0.5-1,0.4
          c-0.3,0-0.5-0.1-0.8-0.3L0.4,7.2c-0.5-0.4-0.6-1.3-0.1-1.8c0.4-0.5,1.3-0.5,1.8-0.1l7.5,6.8l4-3.3c0.4-0.5,1.3-0.6,1.8-0.1
          L27.5,20v-3.7c0-0.7,0.6-1.3,1.3-1.3c0.6,0,1.3,0.6,1.2,1.3v6.7c0,0.7-0.6,1.3-1.2,1.3H22.1z"
      />
    ),
    drag: (
      <path
        className="st0"
        d="M4.1,19.4c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1l-2.5-2.5h7v3.9
          c0,0.4,0.3,0.8,0.8,0.8h3.9v7l-2.5-2.5c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l3.9,3.8c0.1,0.1,0.3,0.2,0.5,0.2
          s0.4-0.1,0.5-0.2l3.9-3.8c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0l-2.5,2.5v-7h3.9c0.4,0,0.8-0.3,0.8-0.8v-3.9h7l-2.5,2.5
          c-0.3,0.3-0.3,0.8,0,1.1c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2l3.9-3.8c0.1-0.1,0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.5l-3.9-3.8
          c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l2.5,2.5h-7v-3.9c0-0.4-0.3-0.8-0.8-0.8h-3.9v-7l2.5,2.6c0.1,0.1,0.3,0.2,0.5,0.2
          s0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1l-3.9-3.8C15.3,0.1,15.2,0,15,0c-0.2,0-0.4,0.1-0.5,0.2l-3.9,3.8c-0.3,0.3-0.3,0.8,0,1.1
          c0.3,0.3,0.8,0.3,1.1,0l2.5-2.5v7h-3.9c-0.4,0-0.8,0.3-0.8,0.8v3.9h-7l2.6-2.5c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0
          l-3.9,3.8c-0.3,0.3-0.3,0.8,0,1.1L4.1,19.4z M11.1,11.1H19v7.8h-7.8V11.1z"
      />
    ),
    dropdown: (
      <path
        className="st0"
        d="M26.3,7.1c0.8-0.8,2.2-0.8,3,0s0.8,2.2,0,3L16.5,22.9c-0.8,0.8-2.2,0.8-3,0L0.6,10.1
          c-0.8-0.8-0.8-2.2,0-3s2.2-0.8,3,0L15,18.4L26.3,7.1z"
      />
    ),
    edit: (
      <path
        className="st0"
        d="M28.8,4.2l-2.9-2.9c-1.6-1.6-4.2-1.6-5.9,0l-18.3,18L0,29.8l10.5-1.8l18.3-18C30.4,8.5,30.4,5.9,28.8,4.2z M5.4,20.1
          l13-12.8l4.4,4.4l-13,12.8L5.4,20.1z M4.2,23.2l2.4,2.4l-2.9,0.5L4.2,23.2z M26.6,7.9L25,9.5l-4.4-4.4l1.6-1.6
          c0.4-0.4,1.1-0.4,1.5,0l2.9,2.9C27,6.8,27,7.5,26.6,7.9z"
      />
    ),
    engine: (
      <path
        className="st0"
        d="M8.5,4.9c-0.1,0-0.3,0.2-0.4,0.2L5.6,7.6c-1.7,0.2-3,1.5-3,3.3v3.3H1.3v-6C1.3,8,1,7.6,0.7,7.6
          S0,8,0,8.3v13c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6v-5.9h1.4v3.3c0,1.7,1.3,3.1,3,3.3L8,24.4c0.2,0.1,0.3,0.1,0.5,0.1h10.5
          c0.1,0,0.3-0.1,0.4-0.1l5-5.1H26v2c0,0.3,0.3,0.6,0.6,0.6h2.6c0.3,0,0.6-0.3,0.6-0.6V9.6c0-0.3-0.3-0.6-0.6-0.6h-2.6
          C26.3,9,26,9.3,26,9.6v1.9h-1.3V8.3c0-0.3-0.3-0.6-0.6-0.6h-2.4l-2.4-2.4c-0.1-0.1-0.3-0.1-0.4-0.1H8.5V4.9z M8.7,6.3l2.4,2.4
          c0.1,0.1,0.3,0.1,0.4,0.1s0.3,0,0.4-0.1c0.3-0.3,0.3-0.8,0-0.9l-1.5-1.5H12l2.4,2.4c0.3,0.2,0.4,0.2,0.6,0.2s0.3,0,0.5-0.1
          c0.3-0.3,0.3-0.8,0-0.9L14,6.3h1.5L18,8.7c0.2,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.5-0.1c0.3-0.3,0.3-0.8,0-0.9l-1.5-1.5h1.3
          l2.4,2.4c0.1,0.1,0.3,0.1,0.4,0.1h2v3.3v3.9c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6v-3.2h1.3v3.3c0,0.3,0.3,0.6,0.6,0.6
          c0.3,0,0.6-0.3,0.6-0.6v-4.1v-1.9h1.3v10.5h-1.3v-2c0-0.3-0.3-0.6-0.6-0.6h-2.6c-0.2,0-0.3,0.1-0.5,0.1l-5,5.1h-10l-1.2-1.5h8.8
          c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6H5.8c-1.1,0-1.9-0.9-1.9-1.9v-8c0-1,0.9-1.9,1.9-1.9h2.6C8.7,8.8,9,8.5,9,8.2
          S8.9,7.6,8.5,7.6h-1L8.7,6.3z M5.9,12.9c-0.3,0-0.6,0.1-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h10.5c0.3,0,0.6-0.3,0.6-0.6
          s-0.3-0.6-0.6-0.6H5.9z M5.9,15.5c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h10.5c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6H5.9z"
      />
    ),
    facebook: (
      <path
        className="st0"
        d="M16,30H1.7C0.7,30,0,29.3,0,28.3V1.7C0,0.7,0.7,0,1.7,0h26.7C29.3,0,30,0.7,30,1.7v26.7
          c0,0.9-0.7,1.7-1.7,1.7h-7.6V18.4h3.9l0.6-4.5h-4.5V11c0-1.3,0.4-2.2,2.2-2.2l2.4,0v-4c-0.4-0.1-1.8-0.2-3.5-0.2
          c-3.5,0-5.8,2.1-5.8,6v3.3h-3.9v4.5H16V30L16,30z"
      />
    ),
    google: (
      <path
        className="st0"
        d="M26.7,13.8v-3.3h-2.4v3.3h-3.4v2.4h3.4v3.4h2.4v-3.4H30v-2.4H26.7L26.7,13.8z M9.5,13.7v3.8
          c0,0,3.7,0,5.2,0c-0.8,2.5-2.1,3.8-5.2,3.8c-3.1,0-5.6-2.5-5.6-5.7s2.4-5.7,5.6-5.7c1.7,0,2.7,0.6,3.7,1.4
          c0.8-0.8,0.7-0.9,2.7-2.8C14.2,6.9,12,6,9.5,6C4.3,6,0,10.3,0,15.5c0,5.3,4.3,9.5,9.5,9.5c7.9,0,9.8-6.9,9.2-11.4H9.5L9.5,13.7z"
      />
    ),
    heart: (
      <g>
        <path
          className="st0"
          d="M27.9,9.1c0-1.1-0.1-2-0.4-2.8S27,4.9,26.6,4.4c-0.4-0.5-0.8-0.9-1.4-1.2c-0.5-0.3-1.1-0.5-1.6-0.6
            c-0.5-0.1-1.1-0.2-1.6-0.2S20.8,2.7,20.1,3c-0.7,0.3-1.3,0.7-1.8,1.3c-0.6,0.5-1,1-1.4,1.4c-0.4,0.4-0.7,0.8-1,1.2
            c-0.2,0.3-0.5,0.4-0.8,0.4s-0.6-0.1-0.8-0.4c-0.3-0.4-0.6-0.8-1-1.2s-0.9-0.9-1.4-1.4S10.5,3.3,9.9,3C9.2,2.7,8.6,2.5,8,2.5
            S6.9,2.6,6.4,2.7S5.3,3,4.8,3.3s-1,0.7-1.4,1.2S2.7,5.5,2.5,6.3S2.1,8.1,2.1,9.1c0,2.2,1,4.5,3.1,6.9L15,27l9.7-10.9
            C26.8,13.6,27.9,11.3,27.9,9.1z M30,9.1c0,2.9-1.3,5.8-3.8,8.8L15.7,29.6C15.5,29.9,15.3,30,15,30s-0.5-0.1-0.7-0.4L3.8,17.9
            c-0.1-0.1-0.3-0.3-0.5-0.5c-0.2-0.2-0.5-0.7-0.9-1.3s-0.8-1.3-1.1-1.9s-0.6-1.4-0.9-2.4S0,10,0,9.1C0,6.3,0.7,4,2.1,2.4S5.5,0,8,0
            c0.7,0,1.4,0.1,2.1,0.4c0.7,0.3,1.4,0.7,2,1.1s1.2,0.9,1.6,1.3c0.4,0.4,0.9,0.9,1.3,1.3c0.4-0.5,0.8-0.9,1.3-1.3
            c0.4-0.4,1-0.9,1.6-1.3s1.3-0.9,2-1.1S21.3,0,22,0c2.5,0,4.5,0.8,5.9,2.4S30,6.3,30,9.1z"
        />
        <path
          className="st0 st1"
          d="M15,30c-0.3,0-0.5-0.1-0.7-0.4L3.8,17.9c-0.1-0.1-0.3-0.3-0.5-0.5c-0.2-0.2-0.5-0.7-0.9-1.3s-0.8-1.3-1.1-1.9
            s-0.6-1.4-0.9-2.4S0,10,0,9.1C0,6.3,0.7,4,2.1,2.4S5.5,0,8,0c0.7,0,1.4,0.1,2.1,0.4c0.7,0.3,1.4,0.7,2,1.1s1.2,0.9,1.6,1.3
            c0.4,0.4,0.9,0.9,1.3,1.3c0.4-0.5,0.8-0.9,1.3-1.3c0.4-0.4,1-0.9,1.6-1.3s1.3-0.9,2-1.1S21.3,0,22,0c2.5,0,4.5,0.8,5.9,2.4
            S30,6.3,30,9.1c0,2.9-1.3,5.8-3.8,8.8L15.7,29.6C15.5,29.9,15.3,30,15,30z"
        />
      </g>
    ),
    history: (
      <path
        className="st0"
        d="M17,2.1C10,2.1,4.2,7.8,4.2,15H0l5.5,5.5l0.1,0.2l5.7-5.7H7.2c0-5.5,4.5-10,10-10s10,4.5,10,10
          s-4.5,10.1-10,10.1c-2.7,0-5.2-1.2-7-3l-2.1,2.1c2.4,2.4,5.5,3.8,9.1,3.8c7,0,12.8-5.7,12.8-12.9S24.2,2.1,17,2.1z M15.7,9.3v7.2
          l6.1,3.6l1-1.8l-4.9-3v-6H15.7z"
      />
    ),
    honda: (
      <g>
        <path
          className="st0"
          d="M21.9,4.9c-0.7,3.1-1,4.5-1.5,6.7c-0.5,2.1-1,4.1-1.7,5.1c-0.7,1-1.7,1.2-2.4,1.3C16,17.9,15.7,18,15,18
            s-1,0-1.3-0.1c-0.7-0.1-1.6-0.3-2.4-1.3c-0.7-1-1.1-2.9-1.7-5.1C9,9.3,8.7,7.9,8.1,4.9c0,0-0.7,0-1,0C6.7,4.9,6.3,5,6,5
            c0,0,0.4,6.3,0.6,9c0.2,2.8,0.5,7.6,0.8,11.1c0,0,0.6,0.1,1.5,0.1c1,0.1,1.5,0.1,1.5,0.1c0.4-1.6,0.9-3.8,1.5-4.7
            c0.5-0.8,1.2-0.8,1.6-0.9c0.6-0.1,1.2-0.1,1.4-0.1l0,0l0,0c0.3,0,0.8,0,1.4,0.1c0.4,0.1,1.1,0.1,1.6,0.9c0.6,0.9,1.1,3.1,1.5,4.7
            c0,0,0.4,0,1.4-0.1c1-0.1,1.5-0.1,1.5-0.1c0.3-3.6,0.6-8.3,0.8-11.1c0.2-2.7,0.6-9,0.6-9c-0.3,0-0.7-0.1-1.1-0.1
            C22.6,4.9,21.9,4.9,21.9,4.9"
        />
        <path
          className="st0"
          d="M29.8,8.6c-0.4-4-3.1-4.9-5.4-5.3c-1.2-0.2-3.3-0.4-4.6-0.4c-1.2-0.1-3.9-0.1-4.8-0.1c-0.8,0-3.6,0-4.8,0.1
            C8.8,3,6.7,3.2,5.6,3.4c-2.3,0.4-5,1.2-5.4,5.3C0,9.7,0,11.1,0,12.7c0,2.5,0.3,4.3,0.4,5.5c0.1,0.8,0.4,3.3,0.9,4.5
            c0.6,1.7,1.1,2.2,1.8,2.8c1.2,0.9,3.1,1.2,3.5,1.3c2.2,0.4,6.5,0.5,8.5,0.5c1.9,0,6.3-0.1,8.5-0.5c0.4-0.1,2.3-0.4,3.5-1.3
            c0.6-0.5,1.2-1,1.8-2.8c0.4-1.3,0.8-3.7,0.9-4.5c0.1-1.2,0.3-2.9,0.4-5.5C30,11.1,29.9,9.7,29.8,8.6 M28.4,15.3
            c-0.2,3.2-0.5,5.1-0.9,6.5c-0.4,1.2-0.8,2-1.4,2.6c-1.1,1-2.6,1.2-3.4,1.3c-1.9,0.3-5.1,0.4-7.6,0.4c-2.5,0-5.7-0.1-7.6-0.4
            c-0.9-0.1-2.4-0.3-3.4-1.3c-0.6-0.6-1.1-1.4-1.4-2.6c-0.4-1.4-0.7-3.3-0.9-6.5c-0.1-1.9-0.1-5.1,0.1-6.7C2,6.1,3.3,4.8,5.9,4.4
            C7.2,4.1,8.8,4,10.4,3.9c1.3-0.1,3.4-0.1,4.6-0.1c1.2,0,3.3,0,4.5,0.1C21.1,4,22.8,4.1,24,4.4c2.7,0.5,3.9,1.7,4.2,4.3
            C28.5,10.3,28.5,13.4,28.4,15.3"
        />
      </g>
    ),
    redbull: (
      <g>
        <path
          className="st0"
          d="M21.9,4.9c-0.7,3.1-1,4.5-1.5,6.7c-0.5,2.1-1,4.1-1.7,5.1c-0.7,1-1.7,1.2-2.4,1.3C16,17.9,15.7,18,15,18
            s-1,0-1.3-0.1c-0.7-0.1-1.6-0.3-2.4-1.3c-0.7-1-1.1-2.9-1.7-5.1C9,9.3,8.7,7.9,8.1,4.9c0,0-0.7,0-1,0C6.7,4.9,6.3,5,6,5
            c0,0,0.4,6.3,0.6,9c0.2,2.8,0.5,7.6,0.8,11.1c0,0,0.6,0.1,1.5,0.1c1,0.1,1.5,0.1,1.5,0.1c0.4-1.6,0.9-3.8,1.5-4.7
            c0.5-0.8,1.2-0.8,1.6-0.9c0.6-0.1,1.2-0.1,1.4-0.1l0,0l0,0c0.3,0,0.8,0,1.4,0.1c0.4,0.1,1.1,0.1,1.6,0.9c0.6,0.9,1.1,3.1,1.5,4.7
            c0,0,0.4,0,1.4-0.1c1-0.1,1.5-0.1,1.5-0.1c0.3-3.6,0.6-8.3,0.8-11.1c0.2-2.7,0.6-9,0.6-9c-0.3,0-0.7-0.1-1.1-0.1
            C22.6,4.9,21.9,4.9,21.9,4.9"
        />
        <path
          className="st0"
          d="M29.8,8.6c-0.4-4-3.1-4.9-5.4-5.3c-1.2-0.2-3.3-0.4-4.6-0.4c-1.2-0.1-3.9-0.1-4.8-0.1c-0.8,0-3.6,0-4.8,0.1
            C8.8,3,6.7,3.2,5.6,3.4c-2.3,0.4-5,1.2-5.4,5.3C0,9.7,0,11.1,0,12.7c0,2.5,0.3,4.3,0.4,5.5c0.1,0.8,0.4,3.3,0.9,4.5
            c0.6,1.7,1.1,2.2,1.8,2.8c1.2,0.9,3.1,1.2,3.5,1.3c2.2,0.4,6.5,0.5,8.5,0.5c1.9,0,6.3-0.1,8.5-0.5c0.4-0.1,2.3-0.4,3.5-1.3
            c0.6-0.5,1.2-1,1.8-2.8c0.4-1.3,0.8-3.7,0.9-4.5c0.1-1.2,0.3-2.9,0.4-5.5C30,11.1,29.9,9.7,29.8,8.6 M28.4,15.3
            c-0.2,3.2-0.5,5.1-0.9,6.5c-0.4,1.2-0.8,2-1.4,2.6c-1.1,1-2.6,1.2-3.4,1.3c-1.9,0.3-5.1,0.4-7.6,0.4c-2.5,0-5.7-0.1-7.6-0.4
            c-0.9-0.1-2.4-0.3-3.4-1.3c-0.6-0.6-1.1-1.4-1.4-2.6c-0.4-1.4-0.7-3.3-0.9-6.5c-0.1-1.9-0.1-5.1,0.1-6.7C2,6.1,3.3,4.8,5.9,4.4
            C7.2,4.1,8.8,4,10.4,3.9c1.3-0.1,3.4-0.1,4.6-0.1c1.2,0,3.3,0,4.5,0.1C21.1,4,22.8,4.1,24,4.4c2.7,0.5,3.9,1.7,4.2,4.3
            C28.5,10.3,28.5,13.4,28.4,15.3"
        />
      </g>
    ),
    horsepower: (
      <path
        className="st0"
        d="M19.1,0v4.4l9,13.3l-2.9,2.9c0,0-4.2-3.3-10-0.8c1.3-1.5,0.9-4.5,0.9-4.5s-0.5-2.5,1.1-3.7l1.4,0.8l0.3-0.5l1.8,0L20,10.5
          h-3.1c-6.3,4.5-5.7,11.2-5.7,11.2s3.4,3.4,9.4,3.6l1.6,4.8H1.9C1.9,30,1.4,11,19.1,0z"
      />
    ),
    information: (
      <path
        className="st0"
        d="M15,30C6.7,30,0,23.3,0,15S6.7,0,15,0s15,6.7,15,15S23.3,30,15,30z M15,22.5c-1.5,0-1.5-1.1-1.5-1.5v-7.5h3V21
          C16.5,21.4,16.5,22.5,15,22.5z M16.5,10.5h-3V9c0-0.3,0-1.5,1.5-1.5s1.5,1.2,1.5,1.5V10.5z M15,3C8.4,3,3,8.4,3,15s5.4,12,12,12
          s12-5.4,12-12S21.6,3,15,3z"
      />
    ),
    koenigsegg: (
      <g>
        <path
          className="st0"
          d="M25.6,6.4C21.2,2.3,15.1,0,15.1,0s-6,2.3-10.5,6.4L4.4,6.5v0.3c0,0.1,0.1,4.5,1.3,8.8c1.2,4.1,3.5,10,9.1,14.1L15,30
            l0.3-0.3c5.4-4.2,7.9-9.7,9.1-14.1c1.2-4.5,1.3-8.7,1.3-8.8V6.5V6.4z M14.1,1.4c-0.1,0-0.3,0-0.5,0.1V1.2C13.7,1,14,1,14.1,1
            c0.3,0,0.6,0.1,0.8,0.3V0.8h0.4v0.5c0.1-0.1,0.4-0.1,0.8-0.1c0.1,0,0.4,0,0.5,0.1v0.4c-0.1-0.1-0.3-0.1-0.5-0.1
            c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8c0.1,0,0.4,0,0.5-0.1v0.4c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.5,0-0.8-0.1v0.5h-0.4
            V3.3c-0.3,0.1-0.5,0.1-0.8,0.1c-0.1,0-0.3,0-0.5-0.1V2.9c0.1,0,0.3,0.1,0.5,0.1c0.4-0.1,0.8-0.4,0.8-0.9
            C14.9,1.8,14.5,1.4,14.1,1.4z M15.1,29C5.9,21.9,5.3,10.4,5.3,8.7C8.6,6.2,14,4.4,15,4.1c0.9,0.3,6.4,2.2,9.7,4.6
            C24.7,10.4,24,21.9,15.1,29z"
        />
        <path
          className="st0"
          d="M15,5.5l-3.6-0.1C9.8,5.9,8.5,6.7,6.9,7.6l2.8,0.1L15,5.5L15,5.5z M15,8.2L9.7,7.7L5.3,9.5c0,0.1,0,0.3,0,0.5l4.5,0.4
            L15,8.2L15,8.2z M15,11l-5.3-0.6l-4.2,1.4l0.1,0.6l4.1,0.5L15,11L15,11z M15,13.8l-5.3-0.9l-3.8,1.2L6.1,15l3.6,0.5L15,13.8
            L15,13.8z M15,16.7l-5.3-1.2l-3.2,0.9c0.1,0.4,0.3,0.7,0.4,1.1l2.8,0.5L15,16.7L15,16.7z"
        />
        <path
          className="st0"
          d="M15,19.5l-5.3-1.4l-2.4,0.6c0.3,0.5,0.5,1.2,0.8,1.7l1.7,0.4L15,19.5L15,19.5z M9.7,20.8L8.5,21c0.4,0.8,0.9,1.5,1.3,2.3
            l5.3-1.2L9.7,20.8z M9.9,23.3c0.5,0.8,0.9,1.6,1.5,2.2L15,25L9.9,23.3z M12.8,27.1c0.3,0.3,0.6,0.6,1,1l1.2-0.3L12.8,27.1z"
        />
        <path
          className="st0"
          d="M20.4,7.7l2.8-0.1c-1.5-0.9-3.3-1.7-4.7-2.3L15,5.5L20.4,7.7L20.4,7.7z M15,8.2l5.4,2.2l4.4-0.5c0-0.1,0-0.4,0-0.5
            l-4.4-1.7L15,8.2L15,8.2z M20.4,10.4L15,11l5.4,1.9l4.1-0.5c0-0.3,0.1-0.4,0.1-0.6L20.4,10.4L20.4,10.4z M15,13.8l5.4,1.7l3.5-0.5
            c0.1-0.3,0.1-0.6,0.3-0.9l-3.7-1.2L15,13.8L15,13.8z M15,16.7l5.4,1.4l2.7-0.5c0.1-0.4,0.3-0.8,0.4-1.2l-3.1-0.9L15,16.7L15,16.7z"
        />
        <path
          className="st0"
          d="M15,19.5l5.3,1.2l1.7-0.3l0.8-1.7l-2.3-0.6L15,19.5L15,19.5z M15,22.2l5.4,1.1c0.5-0.8,0.8-1.5,1.2-2.3l-1.4-0.3L15,22.2z
            M18.6,25.6c0.6-0.8,1.2-1.4,1.7-2.2L15,25L18.6,25.6z M16.3,28.1c0.4-0.3,0.6-0.6,1-1L15,27.8L16.3,28.1z"
        />
      </g>
    ),
    lamborghini: (
      <g>
        <path
          className="st0"
          d="M14.8,8.8c-0.1,0.1-0.2,0-0.1-0.1c0.1,0,0.1-0.2,0.2-0.2c0.1-0.1,0.2,0,0.2,0.1C15,8.7,14.9,8.8,14.8,8.8L14.8,8.8z"
        />
        <path
          className="st0"
          d="M18.6,13.6c0,0,0.3,0.2,0.3,0.3c0,0.1,0,0.4-0.2,0.4c-0.2,0-0.8,0-1.1,0.2c-0.2,0.1-0.4,0.1-0.5,0
            c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0-0.4,0.2-0.5,0.2c-0.1-0.1-0.1-0.2,0.1-0.3c0.3-0.1,0.1-0.2-0.1-0.2c-0.2,0-0.2,0-0.2,0
            s-0.2,0.1-0.3,0.1c-0.1,0.1-0.3,0.1-0.4,0c-0.1-0.1-0.1-0.4,0.2-0.4c0.3,0,0.6-0.2,0.5-0.3c-0.1-0.1,0.1-0.2,0.1,0
            c0.1,0.1,0.1,0.1,0.4,0.2c0.3,0.1,0.2-0.1,0.3,0c0.2,0.2,0.3,0.1,0.3,0c0-0.2-0.1-0.5-0.3-0.3c-0.2,0.1-0.3,0-0.3,0
            c0-0.1-0.1-0.2-0.2-0.1c-0.1,0-0.1-0.2,0-0.2c0.2,0,0.2-0.3,0.1-0.3c-0.1-0.1-0.2-0.2-0.4,0c-0.2,0.1-0.5,0-0.6,0
            c-0.1,0-0.7,0-0.9,0.2c-0.1,0.1-0.4,0-0.1-0.2c0.3-0.2,1.1-0.7,1.1-0.9c0-0.2-0.1-0.7-0.3-0.7c-0.2,0-0.7-0.1-0.8-0.2
            c-0.1-0.1-0.6-0.1-0.7,0.2c-0.1,0.3-0.2,0-0.2,0s-0.5-0.4-0.9-0.1c-0.4,0.4-0.2-0.2-0.1-0.3c0.1-0.1,0.3-0.3,0.6-0.3
            c0.3,0,0.2-0.3,0.7-0.3c0.5,0,0.4-0.2,0.4-0.3c0-0.1-0.1-0.4,0.1-0.5c0.2-0.1,0.3-0.2,0.3,0.1c0,0.2,0,0.5,0.1,0.5
            c0.1,0,0.1,0.3,0.1,0.3c0,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.1,0.3,0c0.1,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0.1,0.1,0.1,0.3,0.1,0.4
            c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.1,0.4,0.4,0.4c0.2,0,0.3,0.1,0.5,0.3c0.2,0.2,0.3,0.1,0.2-0.1c-0.1-0.2-0.5-0.4-0.3-0.6
            c0.2-0.2,0.2,0.1,0.4,0.3C17.3,11.7,18.4,13.3,18.6,13.6L18.6,13.6z"
        />
        <path
          className="st0"
          d="M15.4,23.4c0,0,0,0.4-0.2,0.2c-0.1-0.2-0.5-0.5-0.5-0.9c0-0.4-0.1-0.4-0.1-0.4s0.4,0.1,0.4,0.6c0,0,0.2-0.1,0.2,0.3
            C15.3,23.5,15.4,23.4,15.4,23.4L15.4,23.4z"
        />
        <path
          className="st0"
          d="M16.9,15c0,0,0.5-0.2,0.8,0c0.3,0.2,0.1,0.6,0.1,0.6s-0.7,0.3-0.5,0.7c0.1,0.4,0.9,0.7,2,0.7c1.1,0.1,0.1,0.4-0.2,0.4
            c-0.3,0-2,0.4-2.8-0.6C15.5,15.8,16.7,15.1,16.9,15L16.9,15z"
        />
        <path
          className="st0"
          d="M10.1,12.7c0,0-0.2,0.8,0,1.1c0.2,0.4,0.2,0.9,0.5,1.1c0.2,0.1,0.2,0.2,0.4,0.1c0.1-0.1,0.2,0,0.3,0.1
            c0.1,0.1,0.3,0.1,0.3,0.2c0,0.1,0,0.2-0.2,0.2s-0.3,0.1-0.3,0c-0.1-0.1-0.2-0.3-0.4-0.1c-0.1,0.2-0.2,0.5-0.4,0.3
            c-0.3-0.1-0.7-0.1-0.3-0.4c0,0-0.3-0.3-0.4-0.3c-0.1-0.1-0.2,0-0.2-0.4c0-0.3-0.2-0.6,0.3-0.9c0.4-0.3,0.2-0.3,0.2-0.4
            C9.7,13.2,9.9,12.4,10.1,12.7L10.1,12.7z"
        />
        <path
          className="st0"
          d="M14.2,21.4c0-0.3-0.4-0.4-0.5-0.6c0-0.2-0.1-0.4-0.2-0.4c-0.2,0-0.4-0.3-0.4-0.5c-0.1-0.2,0.1-0.7,0.2-1
            c0.1-0.4,0.1-0.5,0.3-0.6c0.2-0.1,0.2-0.3,0-0.5c-0.2-0.2,0-0.4,0.2-0.3c0.2,0,0.2,0,0.2-0.3c0-0.3-0.3-0.5-0.2-0.7
            c0.1-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.4-0.2-0.8c0-0.4-0.3,0-0.3,0.1c0,0.1-0.2,0.1-0.3,0.3c-0.2,0.1-0.4,0.2-0.4,0.3
            c0,0.2-0.1,0.4,0.1,0.4c0.2,0,0,0.2-0.2,0.2c-0.1,0-0.3-0.1-0.7-0.4c-0.4-0.3-0.2,0.2-0.1,0.4c0.1,0.1-0.6,0.5-0.8,0.2
            c-0.3-0.3-0.7-0.1-0.9-0.2c-0.2-0.1-0.3-0.7-0.3-0.7c-0.2,0.3-0.3,1-0.3,1.5c0,0.5-0.4,0.5-0.9,0.8c-0.5,0.3-0.5-0.6-0.4-0.8
            C8,17,8,16.9,7.8,17.1c-0.2,0.2-0.1,0.6-0.3,0.8c-0.2,0.2-0.2,0.2-0.1,0.3c0,0.1,0,1.1-0.1,1.3C7.2,19.6,7.1,20,7.1,20
            s0.1,0.4,0,0.6c-0.2,0.2,0,0.4,0,0.5c0,0.1-0.2,0.1-0.3,0c-0.1-0.1,0.1-0.7,0.1-1c0-0.3-0.2,0-0.2,0s0,0.4-0.1,0.7
            c-0.1,0.2,0.1,0.6,0.1,0.8c0,0.2,0,0.6,0,0.9c0,0.2,0.2,0.1,0.2,0.1s0-0.2-0.1-0.6c0-0.4,0.2-0.3,0.2-0.1c0,0.1,0.1,0.7,0,0.8
            c-0.1,0.1-0.2,0.2-0.2,0.2s0,0.2-0.1,0.4c-0.1,0.2,0,0.4,0.1,0.1C6.9,23.1,7,23.3,7,23.3s0.1,0.3,0.2,0.3c0.1,0,0.1,0.2,0.1,0.5
            c0,0.3-0.4,0.7-0.7,0.7c-0.3,0-0.3,0.4-0.3,0.4L6.3,26c0,0,0,0-0.1,0.2c-0.1,0.2-0.6,0.2-0.4,0.7c0.2,0.5-0.5,1.4-1,2.1
            C4.1,29.8,4.9,30,5.4,30s0.9-0.1,1.2-0.4c0.2-0.2,0.2-1.1,0.2-1.6c0-0.4-0.1-0.6,0-1.3c0.1-0.7,0.6-1.4,0.9-1.6
            c0.3-0.2,0.6-0.8,0.5-1.1c0-0.3,0.1-1.8,0.1-2c0-0.2,0.5-1.3,0.6-1.4c0.1-0.1,0-0.2,0.3-0.3c0.3-0.1,0.6,0.4,0.6,0.4
            c-0.2,0.3,0.2,0.3,0.2,0.6c0,0.3,0.3,0.5,0.5,0.5c0,0,0.4,0.1,0.5,0.5c0.1,0.4-0.3,1-0.5,1.4c-0.2,0.4,0.1,0.6,0.1,0.8
            s0,0.4,0.2,0.4c0.2,0.1,0,0.6,0,0.6c-0.4,0,0,0.1-0.5,0.6c-0.5,0.5-0.8,1.6-0.8,2c-0.1,0.4,0.3,0.4,0.3,0.2
            c0.1-0.2,0.2-0.1,0.2,0.1c0,0.2,0.1,0.3,0.4,0.3c0.3,0,0.9-0.5,1-0.5c0.1,0,0.4-0.4,0.3-0.7c-0.2-0.3,0-1.5,0-2
            c0-0.5,0.3-0.6,0.3-0.9c0-0.3,0.3-1.2,0.4-1.4c0-0.2,0.6-0.7,1-1c0.3-0.3,0,0.5,0,0.5s-0.4,0.7-0.5,0.8c-0.1,0.1-0.2,0.4-0.3,0.5
            c-0.1,0.1-0.2,0.6-0.2,0.7c0,0.2,0,0.3,0.3,0.4c0.3,0.1,0.3-0.1,0.3-0.4c0-0.3,0.3-0.2,0.5-0.2c0.3,0,0.3,0.3,0,0.4
            c-0.3,0.1-0.2,0.2-0.1,0.2c0.2,0,0.7-0.5,0.6-0.7c0-0.3,0.1-0.7,0.2-1c0.1-0.2,0-0.6,0-0.6s-0.1-0.5-0.1-0.7c0-0.2,0-0.5,0.3-0.4
            C14.7,22,14.3,21.6,14.2,21.4z M5.4,29.8c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1
            C5.6,29.8,5.5,29.8,5.4,29.8z M5.8,28.8c-0.4,0-0.7,0.1-0.8,0.7c0,0-0.2,0.2-0.3,0.2c-0.1-0.1-0.2-0.2,0-0.4
            c0.2-0.1,0.9-1.2,0.9-1.3c0.1-0.1,0.5-0.2,0.6,0.2C6.3,28.5,6.1,28.8,5.8,28.8z M10,27.8c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.1-0.1
            c0.1,0,0.1,0,0.1,0.1C10.1,27.8,10,27.8,10,27.8z M10.7,28.5c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1s0.1,0.1,0.1,0.1
            C10.8,28.4,10.7,28.5,10.7,28.5z M11.2,27.6c-0.3,0-0.5,0.3-0.7,0.3c-0.2,0-0.3-0.4-0.3-0.4s-0.2-0.1,0.1-0.4
            c0.3-0.3,0-0.5,0.4-0.7c0,0,0.3-0.4,0.4,0c0.2,0.3,0.3,0.4,0.3,0.7C11.3,27.2,11.4,27.5,11.2,27.6z M11.2,24.9
            c-0.1,0.1-0.3,0.2-0.3-0.1c0-0.3-0.1-0.8,0-1c0,0,0.4-0.5,0.5-0.1C11.6,24.2,11.3,24.8,11.2,24.9z M12.5,24.9
            c-0.1,0-0.1-0.1-0.1-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0.1,0.1,0.1C12.6,24.8,12.6,24.9,12.5,24.9z M13.7,24c0,0-0.2,0-0.3,0.2
            c-0.1,0.1-0.4,0.2-0.5,0.1c0,0-0.3-0.2,0-0.4c0.3-0.2,0.6-0.9,0.6-1c0-0.1,0.2-0.3,0.2,0C13.7,23.2,13.7,23.9,13.7,24z"
        />
        <path
          className="st0"
          d="M22.2,17c0.6,0.1,1.7,0,2-0.1c0.3,0,1.3-0.8,1.2-1c-0.1-0.2-0.5,0-0.8,0.1c-0.4,0.2-1.4-0.2-1.9-0.4
            c-0.5-0.2-0.5-0.6-0.4-0.8c0.1-0.2,0.1-1-0.2-1.5c-0.4-0.5-0.3-1.1-0.3-2.8c0-1.6-1.9-4.2-2.2-4.7c-0.4-0.4-0.3-0.4-0.1-1.2
            c0.2-0.8,0.3-1.4-1-2.3c-1.3-0.9-4.6-0.4-5-0.3c-0.4,0.1-2.6,0.4-3.1,0.4c-0.5,0-0.6-0.1-0.5-0.5c0.1-0.4,1.7-0.3,2.1-0.3
            c0.4,0,2.1-0.2,2.4-0.3c0.3,0,0.4-0.2,0-0.2c-0.4,0-0.7,0-0.7-0.2c0-0.2,1.2-0.3,1.6-0.3c0.4,0,0.9-0.3,0.9-0.3
            c-0.3-0.1-0.5,0.1-1.1,0.1c-0.6,0-1,0.1-1.1-0.1C14,0.2,13.9,0,13.7,0.1c-0.3,0.1-0.2-0.2-0.5-0.1c-0.3,0.2-0.3-0.2-0.5,0
            c-0.2,0.1-0.7,0.1-1,0.3C11.4,0.5,10.9,0.5,9.8,1C8.7,1.4,9,2.5,9.7,2.7c0.7,0.2,1.1,0.1,3.5-0.2c2.4-0.3,3.7,0,4.8,0.2
            c1.1,0.3,1,1.5,0.9,1.8c-0.1,0.3-0.3,0.3-1,0.5c-0.7,0.1-1.7-0.1-2-0.1c-0.3,0-1.1-0.5-1.9-0.5c-0.8,0-2.4,1.5-2.4,1.5
            s-0.3,0.3-1.1,0.8c-0.8,0.5-1.6,2-1.6,2.2c0,0.2-0.3,0.7-0.6,1.2c-0.3,0.5-0.2,1.1-0.3,1.3c-0.1,0.2-0.1,1-0.3,1.2
            c-0.1,0.2,0.1,1-0.2,1.4C7.1,14.6,7,15.8,7,16c0,0.3,0,1.7-0.2,2c-0.1,0.4,0.1,1.1,0.1,1.1s0-0.6,0-0.9c0-0.3,0.5-2,0.5-2
            c0.1-0.6-0.2-1.1,0-1.7c0-0.4,0.6-0.8,0.4-1.2c-0.2-0.4,0-0.5,0.1-0.5c0.2,0,0.2-0.3,0-0.3c-0.2,0,0-0.5,0.1-0.6
            c0.1-0.2,0.2-0.6,0.3-1.4c0.1-0.7,0.7-1,0.8-1.3c0-0.3,0.2-0.4,0.4-0.9c0.2-0.5,0.9-1.3,1.1-1.5c0.2-0.2,1.6-1.1,2.4-1.8
            c0.8-0.7,2-0.3,2.5-0.1c0.5,0.2,2.5,0.3,2.8,0.4c0.3,0,0.5-0.2,0.5-0.2s0.6-0.7,0.5-1.6c-0.1-0.9-1.1-1.1-2.2-1.4
            C16,2,12.5,2.5,12.1,2.5c-0.3,0-1.8,0.2-2,0.1C9.8,2.5,10,2.4,10.2,2.5c0.2,0.1,1.3,0,1.5,0c0.2,0,2.6-0.4,3.7-0.5
            c1.1-0.1,3,0.2,3.7,0.9c0.7,0.8,0.2,2.2,0.1,2.3c-0.1,0.2-0.1,0.2,0,0.3c0.1,0.1-0.1,0.2-0.4,0.1c-0.3-0.1-0.3,0-0.6,0.2
            C18,6,17.9,6.2,18,6.2c0.1,0,0.2,0.1,0.3,0.4c0,0.2,0.3,0.5,0.1,0.8c-0.2,0.2,0,0.4,0,0.6c0,0.1,0,0.3,0.2,0.3
            c0.2,0,0.1,0.3-0.1,0.4c-0.2,0.2-0.4-0.3-0.5-0.4c-0.1-0.2-0.2,0,0,0.2c0.2,0.2-0.3,0.2-0.4,0.3c-0.1,0.2-0.3,0.1-0.3,0
            c0-0.1-0.4-0.6-0.4-0.6c0.1-0.2-0.3-0.5-0.3-0.5c0.1-0.2-0.1-0.4-0.1-0.4c0-0.3-0.1-0.4-0.5-0.9c-0.4-0.4-0.3,0.1-0.3,0.3
            c0.1,0.2,0.8,1.5,1,1.7c0.2,0.2,0.5,0.6,0.5,1.4c0,0.8,0.4,1.1,0.6,1.3c0.2,0.2,0.5,0.5,0.7,1.3c0.2,0.8,0.6,1.3,0.8,1.5
            c0.2,0.2,1.3,0.7,1.5,0.7c0.2,0,0.6,0.2,0.7,0.3c0.1,0.1,0.5,0.4,0.5,0.6c0,0.2,0.1,0.2,0.2,0.2c0.1,0,0.4,0.3,1.2,0.5
            c0.8,0.2,1.3,0.1,1.7-0.1c0.3-0.2,0.2,0.2,0.2,0.2s-0.5,0.3-0.8,0.5c-0.3,0.2-1.2,0.2-1.9,0c-0.7-0.2-0.7,0-1.2,0
            c-0.5,0-0.3-0.4,0-0.3c0.2,0,0.4-0.2,0.4-0.5c0-0.3-0.3-0.4-0.3-0.4s-0.3,0.1-0.6-0.1c-0.3-0.2-0.5,0-0.8-0.2
            c-0.4-0.2-0.5,0.2-0.5,0.2c-0.2-0.5-0.5-0.2-0.9-0.3c-0.3,0-0.3,0.2-0.5,0.5c-0.1,0.3,0.5,0.2,0.7,0.2c0.1,0,0.8,0.2-0.2,0.3
            c-1.1,0.1-0.7-0.6-0.6-0.8c0-0.1,0.3-0.6-0.6-0.7c-0.9-0.1-1.4,0.9-1.4,0.9c-0.2-0.2-0.9-0.4-1.2-0.4c-0.3,0.1-0.4,0.2-0.7,0.2
            c-0.3,0-0.1,0.3-0.1,0.3s0.3,0.4,0.3,0.2c0-0.1,0.3-0.1,0.3,0.1c0,0.2,0.5,0.5,0.7,0.6c0.1,0.1,0.3,0.1,0.3-0.1
            c0-0.2,0-0.3,0.3,0.1c0.3,0.4-0.5,2.1-0.7,2.4c-0.2,0.3-0.2,0.6-0.1,0.8c0.2,0.1,0.1,0.5,0.1,0.5c-0.2-0.1-0.2,0.4-0.2,0.4
            c-0.5-0.2,0,0.5,0,0.7c0,0.2,0.3,0.1,0.3,0c-0.1-0.1,0.1-0.3,0.3-0.4c0.2-0.1,0.1-0.3,0.5-0.9c0.4-0.6,0.4-0.1,0.4-0.1
            s0,0.1-0.1,0.5c-0.1,0.4-0.1,0.9-0.1,0.9s0.1,0.4-0.1,0.7c-0.1,0.3,0,0.3,0,0.3s0.4,0.2,0.1,0.3c-0.3,0.1-0.1,0.3-0.1,0.3
            s0.4,0,0.5,0.1c0.2,0.2,0.1,0.2-0.3,0.1c-0.5-0.1-0.4,0.1-0.2,0.6c0.2,0.5-0.4,0.1-0.4,0.1c0.2,0.6,0.6,0.6,0.8,0.5
            c0.2-0.1,1.2-0.2,1.6-0.2c0.4,0,0.7-0.2,0.7-0.3c0-0.1,0.1-0.5,0.3-0.6c0.2-0.2,0-0.6,0-0.6c-0.6-0.7,0.6-2.7,0.6-2.7
            c0.8-0.5,1.4-1.5,1.5-1.7c0-0.3,0.2-0.1,0.2,0.1c0,0.2-0.3,0.5-0.3,0.5s0.4,0.5,0.6,0.6c0.3,0.1,0.6,1.2,0.6,1.5
            c-0.1,0.3-0.1,1,0.1,1.1c0.2,0.1,0.4,0.7,0.4,0.7c-0.1,0-0.2,0.6,0,0.8c0.2,0.3,0.1-0.2,0.2-0.2c0.1,0.1,0.2-0.1,0.4-0.1
            c0.2,0,0.3,0.4,0,0.4c-0.2,0,0,0.2,0.2,0.3c0.2,0,0.5-0.1,0.4-0.3c0-0.2,0.2-0.3,0.3-0.5c0.1-0.2-0.1-0.7-0.3-0.9
            c-0.2-0.1-0.4-0.4-0.4-0.5c0-0.2,0-0.6-0.2-0.7c-0.2-0.1-0.3-0.6-0.3-0.6s-0.1-1.2-0.1-1.6c0-0.5-0.1-0.9-0.3-1
            c-0.2-0.1-0.5-0.8-0.6-0.8C22.2,17.2,21.6,16.9,22.2,17z M17.1,19c-0.1,0-0.1-0.2,0-0.2c0.1-0.1,0.2,0,0.3,0c0,0,0.1,0.1,0,0.1
            C17.3,19,17.2,19,17.1,19z M17.3,19.8c-0.1,0-0.1-0.2,0-0.2c0.1-0.1,0.3-0.1,0.3,0C17.6,19.6,17.4,19.7,17.3,19.8z M18.2,19.1
            c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.2C18.3,19.1,18.3,19.1,18.2,19.1z M20.7,16.5
            c-0.2-0.2-0.5-0.2-0.7-0.2c-0.1,0-0.1-0.2,0-0.2c0.3,0,0.6,0,0.8,0.2C21,16.3,20.8,16.6,20.7,16.5z M22.7,19
            c-0.2,0-0.5-0.1-0.5-0.2c0-0.1,0-0.4,0.2-0.2c0,0,0.2,0.1,0.3,0.1C22.8,18.7,22.8,19,22.7,19z M22.5,18c0.3,0.1-0.2,0.4-0.3,0.2
            c-0.1-0.2-0.4-0.6-0.5-0.6c-0.1-0.1-0.2-0.2-0.2,0c0,0-0.2,0.2-0.2,0c0-0.2,0-0.6,0.2-0.6c0.2,0,0.2,0,0.3,0.3
            C22.1,17.6,22.3,17.9,22.5,18z"
        />
      </g>
    ),
    mclaren: (
      <g>
        <g>
          <polygon
            className="st0"
            points="10.8,14.6 12.1,14.6 12.1,16.5 13,16.5 13,17.1 12.1,17.1 10.8,17.1 10.8,16.5 10.8,14.6"
          />
          <path
            className="st0"
            d="M17.1,17.1h1.3v-1.5h0.7v-0.6h-0.7h-1v0c-0.1,0-0.3,0.1-0.3,0.3v0.3V17.1L17.1,17.1L17.1,17.1z"
          />
          <path
            className="st0"
            d="M22.5,15.1h-2.3c-1.1,0-1.1,2.1,0,2.1h2.6v-0.6h-2.1v-0.3h1.8C23,16.2,23,15.1,22.5,15.1L22.5,15.1L22.5,15.1L22.5,15.1z
              M20.7,15.6h1v0.3h-1L20.7,15.6L20.7,15.6L20.7,15.6z"
          />
          <path
            className="st0"
            d="M13.7,17.1H16c1.1,0,1.1-2.1,0-2.1h-2.6v0.6h2.1v0.3h-1.8C13.2,15.9,13.2,17.1,13.7,17.1L13.7,17.1L13.7,17.1L13.7,17.1z
              M15.5,16.5h-1v-0.3h1V16.5L15.5,16.5L15.5,16.5z"
          />
          <path
            className="st0"
            d="M10.5,15.1H7.9c-1,0-1,2.1,0,2.1h2.6v-0.6H8.8c-0.5,0-0.5-0.9,0-0.9h1.7V15.1L10.5,15.1L10.5,15.1z"
          />
          <polygon
            className="st0"
            points="5.8,14.6 5.8,14.6 5.8,14.6 6.8,14.6 6.8,17.1 5.5,17.1 5.5,15.9 4.1,17.1 2.8,17.1 2.8,15.9 1.5,17.1 0,17.1
              3.1,14.6 3.1,14.6 3.1,14.6 4.1,14.6 4.1,15.9 5.8,14.6"
          />
          <path
            className="st0"
            d="M25.4,15.1h0.5c0.4,0,0.7,0.3,0.7,0.6v1.5h-1.2v-1c0-0.3-0.3-0.4-0.9-0.4v1.5h-1.3v-2.1h1.3H25.4L25.4,15.1L25.4,15.1z"
          />
        </g>

        <path
          className="st1"
          fill="#cc2a2d"
          d="M24.2,14.4c4.8-2.7,8.6-1.8,3.2,1.5C28.5,14,27,13.8,24.2,14.4L24.2,14.4L24.2,14.4z"
        />
      </g>
    ),
    'mclaren-f1': (
      <path
        className="st0"
        d="M21.2,15.1l-15.9,0L0,21h9.1l3.2-3.6h6.8L21.2,15.1L21.2,15.1L21.2,15.1z M6.6,13.8h16L26.9,9H30V21h-7.4V9H11L6.6,13.8
          L6.6,13.8L6.6,13.8z"
      />
    ),
    'mercedes ': (
      <path
        className="st0"
        d="M15,0C6.7,0,0,6.7,0,15c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15C30,6.7,23.3,0,15,0z M2.7,7.9c2.5-4.3,7.1-7,12.1-7.1
          l-1.9,12.9L2.6,22C0.2,17.6,0.2,12.3,2.7,7.9z M15.1,29.2c-5,0-9.7-2.6-12.2-6.9l12.2-5l12.2,5C24.7,26.6,20.1,29.2,15.1,29.2z
          M17,13.9l-1.8-13c5,0.1,9.6,2.8,12.1,7.1c2.5,4.3,2.6,9.6,0.2,14L17,13.9z"
      />
    ),
    'mercedes-benz': (
      <path
        className="st0"
        d="M15,0C6.7,0,0,6.7,0,15c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15C30,6.7,23.3,0,15,0z M2.7,7.9c2.5-4.3,7.1-7,12.1-7.1
          l-1.9,12.9L2.6,22C0.2,17.6,0.2,12.3,2.7,7.9z M15.1,29.2c-5,0-9.7-2.6-12.2-6.9l12.2-5l12.2,5C24.7,26.6,20.1,29.2,15.1,29.2z
          M17,13.9l-1.8-13c5,0.1,9.6,2.8,12.1,7.1c2.5,4.3,2.6,9.6,0.2,14L17,13.9z"
      />
    ),
    mercedes: (
      <path
        className="st0"
        d="M15,0C6.7,0,0,6.7,0,15c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15C30,6.7,23.3,0,15,0z M2.7,7.9c2.5-4.3,7.1-7,12.1-7.1
          l-1.9,12.9L2.6,22C0.2,17.6,0.2,12.3,2.7,7.9z M15.1,29.2c-5,0-9.7-2.6-12.2-6.9l12.2-5l12.2,5C24.7,26.6,20.1,29.2,15.1,29.2z
          M17,13.9l-1.8-13c5,0.1,9.6,2.8,12.1,7.1c2.5,4.3,2.6,9.6,0.2,14L17,13.9z"
      />
    ),
    nissan: (
      <g>
        <g>
          <polygon
            className="st0"
            points="1.8,13.5 1.8,17.3 3,17.3 3,14.8 4.4,17.3 6.2,17.3 6.2,13.5 5.3,13.5 5.3,16.2 3.8,13.5"
          />
          <polygon className="st0" points="7.1,17.3 8.5,17.3 8.5,13.5 7.1,13.5" />
          <path
            className="st0"
            d="M21.1,13.5h-1.6l-1.7,3.9h1.2l0.3-0.7h2l0.4,0.7H23L21.1,13.5L21.1,13.5z M19.8,15.7l0.6-1.1l0.6,1.1H19.8L19.8,15.7z"
          />
          <polygon
            className="st0"
            points="23.3,13.5 23.3,17.3 24.5,17.3 24.5,14.7 26,17.3 27.9,17.3 27.9,13.5 26.7,13.5 26.7,16.2 25.4,13.5"
          />
          <path
            className="st0"
            d="M9.3,16.4v0.9h3.4c0.7-0.3,0.7-1.1,0.7-1.1V16c0-0.8-0.7-1-0.7-1h-2.1v-0.6H13v-1h-2.8c-1.1,0.3-1,1.3-1,1.3v0.2
              c0.1,0.9,0.9,1,0.9,1h2.1v0.5L9.3,16.4L9.3,16.4z"
          />
          <path
            className="st0"
            d="M13.8,16.4v0.9h3.4c0.7-0.3,0.7-1.1,0.7-1.1V16c0-0.8-0.7-1-0.7-1h-2.1v-0.6h2.5v-1h-2.8c-1.1,0.3-1,1.3-1,1.3v0.2
              c0.1,0.9,0.9,1,0.9,1h2.1v0.5L13.8,16.4L13.8,16.4z"
          />
        </g>

        <path
          className="st0"
          d="M28.5,18.9c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-4.5c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.4-0.3-0.7-0.3h-3.8h-0.3
            H5.3H5H1.5c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v4.5c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3H28.5L28.5,18.9z
            M29.6,18.9c-0.3,0.3-0.6,0.5-1,0.5h-4.5c-0.7,1.7-2,3.2-3.6,4.2c-1.6,1-3.6,1.6-5.6,1.6c-2.1,0-4-0.6-5.6-1.6
            c-1.6-1-2.9-2.5-3.7-4.2H1.5c-0.4,0-0.8-0.2-1-0.5C0.2,18.6,0,18.2,0,17.8v-4.5c0-0.4,0.2-0.8,0.4-1.1c0.3-0.3,0.6-0.5,1-0.5h3.7
            C6,9.7,7.3,8,9,6.8c1.7-1.2,3.7-1.9,5.9-1.9c2.2,0,4.2,0.7,5.9,1.9c1.7,1.2,3,2.9,3.8,4.9h3.9c0.4,0,0.8,0.2,1,0.5
            c0.3,0.3,0.4,0.7,0.4,1.1v4.5C30,18.2,29.8,18.6,29.6,18.9L29.6,18.9z M5.7,11.7h18.4c-0.8-1.9-2-3.4-3.6-4.5
            C18.9,6,17,5.4,14.9,5.4c-2.1,0-4,0.7-5.6,1.8C7.7,8.3,6.4,9.8,5.7,11.7L5.7,11.7z M20.2,23.1c1.4-0.9,2.6-2.2,3.3-3.7H6.1
            c0.7,1.5,1.9,2.8,3.4,3.7c1.5,1,3.4,1.5,5.3,1.5C16.8,24.6,18.7,24.1,20.2,23.1L20.2,23.1z"
        />
      </g>
    ),
    overflow: (
      <path
        className="st0"
        d="M15,6c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S16.7,6,15,6z M15,18c-1.7,0-3-1.3-3-3
          s1.3-3,3-3s3,1.3,3,3S16.7,18,15,18z M15,30c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S16.7,30,15,30z"
      />
    ),
    pagani: (
      <g>
        <path
          d="M15,7.1C6.7,7.1,0,10.7,0,15c0,4.3,6.7,7.9,15,7.9c8.3,0,15-3.5,15-7.9C30,10.7,23.3,7.1,15,7.1z M15,22.3
            C7,22.3,0.5,19,0.5,15S7,7.7,15,7.7c8,0,14.4,3.3,14.4,7.3S23,22.3,15,22.3z"
        />

        <g>
          <g>
            <path className="st0" d="M3.2,14.6v0.7h2.2c0.5,0,0.5-0.7,0-0.7H3.2L3.2,14.6z" />
            <path
              className="st0"
              d="M10.5,15.3v-0.3c0-0.4-1-0.5-1.3-0.5c-0.3,0-1.3,0.1-1.3,0.5v0.3h1.4H10.5L10.5,15.3z"
            />
            <path
              className="st0"
              d="M20.3,15.3v-0.3c0-0.4-1-0.5-1.3-0.5c-0.3,0-1.3,0.1-1.3,0.5v0.3h1.4H20.3L20.3,15.3z"
            />
            <path
              className="st0"
              d="M15,8.3c-7.6,0-13.9,3-13.9,6.7s6.2,6.7,13.9,6.7s13.9-3,13.9-6.7S22.6,8.3,15,8.3z M26.8,12.3c0.4,0.3,0.1,1-0.4,1H10.6
                c-0.4,0-0.5-0.5-0.2-0.7c1.7-0.7,3.2-1.9,4.4-3.7C15.8,8.8,23.4,9,26.8,12.3z M25.5,14.1v1.5c0,0.4-0.5,0.7-0.8,0.4l-1.8-1.3
                c-0.1-0.1-0.3,0-0.3,0.1c0,0.4,0,0.9,0,1.3h-0.4v-1.5c0-0.4,0.5-0.7,0.9-0.4l1.8,1.3c0.1,0.1,0.3,0,0.3-0.1c0-0.4,0-0.9,0-1.3
                H25.5z M19.1,15.7h-1.4v0.4h-0.4v-1.1c0-0.8,1.1-0.9,1.7-0.9c0.6,0,1.7,0.2,1.7,0.9v1.1h-0.4v-0.4H19.1z M13.5,15.7h1.8v-0.3H14
                v-0.4h1.7c0,0.4,0,0.8,0,1.2h-2.3c-1.5,0-1.5-2,0-2h2.3v0.4h-2.3C12.6,14.6,12.6,15.7,13.5,15.7z M9.3,15.7H7.9v0.4H7.5v-1.1
                c0-0.8,1.1-0.9,1.7-0.9s1.7,0.2,1.7,0.9v1.1h-0.4v-0.4H9.3z M13.8,8.9c-1.3,1.9-3.1,3-5,3.4l2.1-3.1C11.8,9.1,12.9,8.9,13.8,8.9z
                M2.4,13.3c1-1.6,3.4-2.9,6.5-3.7l-2.4,3.7L2.4,13.3z M2.7,14.1c0.9,0,1.7,0,2.6,0c1,0,1,1.6,0,1.6H3.2v0.4H2.7V14.1z M26.7,17.7
                c-2.2,2.1-6.7,3.4-11.7,3.4c-5.1,0-9.7-1.4-11.8-3.4c-0.3-0.4-0.1-1,0.4-1c7.6,0,15.2,0,22.7,0C26.9,16.7,27.1,17.3,26.7,17.7z
                M27.4,16.1H27v-2h0.4V16.1z"
            />
          </g>

          <g>
            <path
              className="st0"
              d="M16.6,12.5c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C16.3,12.6,16.4,12.5,16.6,12.5L16.6,12.5z"
            />
            <path
              className="st0"
              d="M14.5,12.5c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C14.2,12.6,14.4,12.5,14.5,12.5L14.5,12.5z"
            />
            <path
              className="st0"
              d="M12.5,12.5c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C12.2,12.6,12.3,12.5,12.5,12.5L12.5,12.5z"
            />
            <path
              className="st0"
              d="M18.6,12.5c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C18.3,12.6,18.4,12.5,18.6,12.5L18.6,12.5z"
            />
            <path
              className="st0"
              d="M20.7,12.5c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C20.3,12.6,20.5,12.5,20.7,12.5L20.7,12.5z"
            />
            <path
              className="st0"
              d="M22.7,12.5c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C22.4,12.6,22.5,12.5,22.7,12.5L22.7,12.5z"
            />
            <path
              className="st0"
              d="M24.7,12.5c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C24.4,12.6,24.5,12.5,24.7,12.5L24.7,12.5z"
            />
            <path
              className="st0"
              d="M13.8,10.7c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.2-0.3-0.3C13.4,10.9,13.6,10.7,13.8,10.7L13.8,10.7z"
            />
            <path
              className="st0"
              d="M15,9.1c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C14.7,9.3,14.9,9.1,15,9.1L15,9.1z"
            />
            <path
              className="st0"
              d="M16.8,9.2c0.2,0,0.3,0.2,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C16.5,9.3,16.6,9.2,16.8,9.2L16.8,9.2z"
            />
            <path
              className="st0"
              d="M18.5,9.4c0.2,0,0.3,0.2,0.3,0.3c0,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C18.2,9.5,18.3,9.4,18.5,9.4L18.5,9.4z"
            />
            <path
              className="st0"
              d="M20.3,9.7c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C20,9.9,20.1,9.7,20.3,9.7L20.3,9.7z"
            />
            <path
              className="st0"
              d="M22,10.2c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.2-0.3-0.3C21.7,10.3,21.9,10.2,22,10.2L22,10.2z"
            />
            <path
              className="st0"
              d="M23.8,10.7c0.2,0,0.3,0.2,0.3,0.3c0,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C23.5,10.9,23.6,10.7,23.8,10.7L23.8,10.7z"
            />
            <path
              className="st0"
              d="M25.3,11.6c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.2-0.3-0.3C25,11.7,25.1,11.6,25.3,11.6L25.3,11.6z"
            />
          </g>

          <g>
            <path
              className="st0"
              d="M16.1,17c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C15.8,17.1,15.9,17,16.1,17L16.1,17z"
            />
            <path
              className="st0"
              d="M14,17c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C13.7,17.1,13.8,17,14,17L14,17z"
            />
            <path
              className="st0"
              d="M11.9,17c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C11.6,17.1,11.7,17,11.9,17L11.9,17z"
            />
            <path
              className="st0"
              d="M18.2,17c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C17.9,17.1,18,17,18.2,17L18.2,17z"
            />
            <path
              className="st0"
              d="M20.3,17c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C20,17.1,20.1,17,20.3,17L20.3,17z"
            />
            <path
              className="st0"
              d="M22.4,17c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3S22,17.5,22,17.3C22,17.1,22.2,17,22.4,17L22.4,17z"
            />
            <path
              className="st0"
              d="M24.5,17c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C24.1,17.1,24.3,17,24.5,17L24.5,17z"
            />
            <path
              className="st0"
              d="M9.8,17c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C9.5,17.1,9.7,17,9.8,17L9.8,17z"
            />
            <path
              className="st0"
              d="M7.7,17c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C7.4,17.1,7.6,17,7.7,17L7.7,17z"
            />
            <path
              className="st0"
              d="M5.7,17C5.8,17,6,17.1,6,17.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C5.3,17.1,5.5,17,5.7,17L5.7,17z"
            />
            <path
              className="st0"
              d="M5.3,18.1c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C5,18.2,5.1,18.1,5.3,18.1L5.3,18.1z"
            />
            <path
              className="st0"
              d="M7.2,18.9c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3C6.9,19.1,7,18.9,7.2,18.9L7.2,18.9z"
            />
            <path
              className="st0"
              d="M24.7,18.1c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3C25,18.2,24.9,18.1,24.7,18.1L24.7,18.1z"
            />
            <path
              className="st0"
              d="M22.8,18.9c-0.2,0-0.3,0.1-0.3,0.3s0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3C23.1,19.1,23,18.9,22.8,18.9L22.8,18.9z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M9.4,19.3l-0.2-0.1l-0.6,0.8l0.2,0.1L8.9,20l0.3,0.1l0,0.2l0.2,0.1L9.4,19.3z M9,19.8l0.2-0.3l0,0.4L9,19.8z"
            />
            <path
              className="st0"
              d="M10,20.4l0.2,0.1l0.2-0.7l-0.2-0.1l-0.1,0.4c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.1-0.2l0.1-0.4l-0.2,0l-0.1,0.4
                c0,0.1-0.1,0.3,0.2,0.3C9.9,20.4,10,20.4,10,20.4L10,20.4L10,20.4z"
            />
            <path
              className="st0"
              d="M10.5,19.9l0.1,0l-0.1,0.4c0,0.1,0,0.2,0.2,0.2c0,0,0.1,0,0.2,0l0-0.1c0,0,0,0-0.1,0c-0.1,0-0.1-0.1-0.1-0.1l0.1-0.3
                l0.2,0l0-0.1l-0.1,0l0-0.2l-0.2,0l0,0.2l-0.1,0L10.5,19.9L10.5,19.9z"
            />
            <path
              className="st0"
              d="M11.4,19.9c-0.2,0-0.4,0-0.4,0.3c-0.1,0.3,0.1,0.4,0.3,0.4c0.2,0,0.4,0,0.4-0.3C11.8,20.1,11.6,20,11.4,19.9z M11.5,20.3
                c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.1-0.2-0.1-0.3c0-0.2,0.2-0.2,0.2-0.2C11.4,20.1,11.6,20.1,11.5,20.3z"
            />
            <path
              className="st0"
              d="M11.9,20l-0.1,0.7l0.2,0l0-0.4c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0.1,0.1,0.2l-0.1,0.4l0.2,0l0.1-0.4
                c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.1,0.1,0.1,0.2l0,0.4l0.2,0l0-0.4c0-0.1,0-0.3-0.2-0.3c0,0-0.1,0-0.3,0.1c0-0.1-0.1-0.2-0.2-0.2
                c-0.1,0-0.1,0-0.2,0.1l0-0.1L11.9,20L11.9,20z"
            />
            <path
              className="st0"
              d="M13.5,20.1c-0.2,0-0.4,0.1-0.4,0.3c0,0.3,0.1,0.4,0.3,0.4c0.2,0,0.4-0.1,0.4-0.3C13.8,20.3,13.7,20.1,13.5,20.1z
                M13.4,20.7c-0.2,0-0.2-0.2-0.2-0.3c0-0.2,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.2,0.3C13.6,20.6,13.6,20.7,13.4,20.7z"
            />
            <path
              className="st0"
              d="M14.4,20.2c-0.1,0-0.2,0-0.2,0.1l0-0.3l-0.2,0l0,1l0.2,0v-0.1c0.1,0.1,0.1,0.1,0.2,0.1c0.2,0,0.3-0.1,0.3-0.4
                C14.7,20.3,14.6,20.2,14.4,20.2z M14.3,20.8c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
                C14.5,20.7,14.4,20.8,14.3,20.8z"
            />
            <path
              className="st0"
              d="M14.8,20.2l0,0.7H15l0-0.7H14.8L14.8,20.2z M14.8,20.1H15v-0.2h-0.2V20.1L14.8,20.1z"
            />
            <polygon className="st0" points="15.2,19.9 15.2,20.9 15.4,20.9 15.4,19.9 15.2,19.9" />
            <path
              className="st0"
              d="M15.5,20.2l0,0.7l0.2,0l0-0.7L15.5,20.2L15.5,20.2z M15.5,20.1h0.2l0-0.2l-0.2,0L15.5,20.1L15.5,20.1z"
            />
            <polygon
              className="st0"
              points="16.8,20.8 16.9,20.8 17.1,20.1 17.1,20.1 17.2,20.8 17.4,20.8 17.3,19.8 17,19.9 16.8,20.5 16.5,19.9 16.2,19.9
                16.3,20.9 16.5,20.8 16.5,20.2 16.5,20.2 16.8,20.8"
            />
            <path
              className="st0"
              d="M18.3,20.3c0-0.3-0.2-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.3,0.4c0,0.3,0.2,0.3,0.4,0.3C18.1,20.7,18.3,20.6,18.3,20.3z
                M17.9,20.6c-0.2,0-0.2-0.1-0.2-0.2c0-0.2,0.1-0.3,0.1-0.3c0,0,0.2,0,0.2,0.2C18.1,20.4,18.1,20.6,17.9,20.6z"
            />
            <path
              className="st0"
              d="M19,19.6l-0.2,0l0.1,0.3c-0.1,0-0.2-0.1-0.2-0.1c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.2,0.3,0.4,0.3c0.1,0,0.1-0.1,0.2-0.1
                l0,0.1l0.2,0L19,19.6z M18.8,20.5c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0.1-0.3c0.1,0,0.2,0.1,0.2,0.2
                C18.9,20.4,18.9,20.5,18.8,20.5z"
            />
            <path
              className="st0"
              d="M19.6,20.3c-0.1,0-0.2-0.1-0.2-0.1l0.5-0.1l0,0c0-0.1-0.1-0.4-0.4-0.3c-0.2,0-0.4,0.2-0.3,0.4c0.1,0.3,0.3,0.3,0.4,0.3
                c0.1,0,0.3-0.1,0.3-0.3l-0.2,0C19.8,20.3,19.7,20.3,19.6,20.3z M19.5,19.9c0.1,0,0.2,0,0.2,0.1l-0.3,0.1
                C19.4,19.9,19.5,19.9,19.5,19.9z"
            />
            <path
              className="st0"
              d="M20.2,19.6l-0.2,0l0.2,0.7l0.2-0.1l-0.1-0.4c0,0-0.1-0.2,0.1-0.2c0.1,0,0.1,0.1,0.2,0.2l0.1,0.4l0.2-0.1l-0.1-0.4
                c-0.1-0.3-0.2-0.3-0.3-0.2C20.3,19.6,20.2,19.6,20.2,19.6L20.2,19.6L20.2,19.6z"
            />
            <path
              className="st0"
              d="M21.7,20c0,0-0.1,0-0.1-0.1l-0.1-0.3c0-0.1-0.1-0.3-0.4-0.2c-0.1,0-0.3,0.1-0.3,0.3l0.2-0.1c0-0.1,0.1-0.1,0.1-0.1
                c0,0,0.1,0,0.1,0.1v0c0,0.1,0,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.2,0.3c0,0.1,0.1,0.2,0.3,0.1c0.1,0,0.2-0.1,0.2-0.2l0,0.1L21.7,20
                L21.7,20z M21.3,20c0,0-0.1,0-0.1,0c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1-0.1l0,0C21.4,19.9,21.3,20,21.3,20z"
            />
          </g>
        </g>
      </g>
    ),
    price: (
      <g>
        <rect className="st0" y="27" width="30" height="3" />

        <rect className="st0" x="5" y="13.5" width="20" height="3" />

        <rect className="st0" x="2.5" width="25" height="3" />
      </g>
    ),

    profile: (
      <path
        className="st0"
        d="M3.3,26.1c1.1,0.8,2.8,2.1,5.6,2.9c1.9,0.6,6.7,1.8,12.7,0c2.7-0.9,4.4-2.1,5.3-2.9
          c0.5-0.4,0.5-1.2-0.1-1.5l-7-3.6c-0.3-0.2-0.6-0.6-0.6-0.9v-1.2c0-0.3,0-0.5,0.3-0.8c1.2-1.4,2-3.3,2.3-3.9c0-0.2,0.2-0.2,0.2-0.3
          c0.2-0.2,0.6-0.6,0.9-1.8c0.1-1.1-0.2-1.6-0.5-1.9C22,10.1,22,9.9,22,9.8V5.1c-0.5-2.7-2.1-4.1-3.8-4.5c-1.8-0.8-4.8-0.8-6.6,0
          c-1.5,0.6-3.2,1.8-3.6,4.5v4.6c0,0.1,0,0.3-0.2,0.5c-0.3,0.4-0.6,1-0.5,2.1c0.3,1.2,0.6,1.7,0.9,1.8c0,0,0.1,0.2,0.1,0.3
          c0.2,0.6,1.1,2.5,2.3,3.9c0.2,0.1,0.3,0.4,0.3,0.8v1c0,0.5-0.3,0.9-0.8,1l-6.9,3.4C3,24.9,2.8,25.7,3.3,26.1z"
      />
    ),
    radio: (
      <path
        className="st0"
        d="M15,0c8.3,0,15,6.8,15,15s-6.8,15-15,15S0,23.3,0,15S6.8,0,15,0z M15,3C8.4,3,3,8.4,3,15
          s5.4,12,12,12s12-5.4,12-12S21.6,3,15,3z"
      />
    ),
    'radio-active': (
      <g>
        <path
          className="st0"
          d="M15,0c8.3,0,15,6.8,15,15s-6.8,15-15,15S0,23.3,0,15S6.8,0,15,0z M15,3C8.4,3,3,8.4,3,15
            s5.4,12,12,12s12-5.4,12-12S21.6,3,15,3z"
        />

        <circle className="st1" cx="15" cy="15" r="7.5" />
      </g>
    ),
    share: (
      <g>
        <path className="st0" d="M14,9H7l8-9l8,9h-7v15h-2V9z" />
        <path className="st0" d="M28,22v6H2v-6H0v7c0,0.6,0.4,1,1,1h28c0.6,0,1-0.4,1-1v-7H28z" />
      </g>
    ),
    showroom: (
      <path
        className="st0"
        d="M11.1,20.5h8v8.7h-8V20.5z M27.6,13.3c0.4,0,0.8,0.3,0.8,0.8v14.2c0,0.5-0.3,0.8-0.8,0.8h-7v-9.4
          c0-0.5-0.3-0.8-0.8-0.8h-9.6c-0.5,0-0.8,0.3-0.8,0.8v9.4H2.4c-0.5,0-0.8-0.3-0.8-0.8V14.3c0-0.5,0.3-0.8,0.8-0.8h25.2V13.3z
           M23.3,5.6h-4.4l-1.3-4.8h2.7L23.3,5.6z M15.7,0.8l1.3,4.8h-4.2l1.3-4.8H15.7z M11.2,5.6H6.7l3.2-4.8h2.7L11.2,5.6z M7.3,7H10
          c0.6,0,1,0.5,1,1v2.7c0,0.6-0.4,1.1-1,1.1H7.3c-0.6,0-1-0.4-1-1.1V8.1C6.3,7.5,6.7,7,7.3,7z M8.2,0.8L5.1,5.6H0.4l4.5-4.4
          C5.1,1,5.4,0.9,5.5,0.9h2.7V0.8z M4.8,7v4.8H0.7c-0.4,0-0.8-0.3-0.8-0.7V8C0,7.5,0.3,7,0.7,7H4.8z M13.6,7h2.7c0.6,0,1,0.5,1,1v2.7
          c0,0.6-0.5,1.1-1,1.1h-2.7c-0.6,0-1-0.4-1-1.1V8.1C12.6,7.5,13,7,13.6,7z M19.9,7h2.7c0.6,0,1,0.5,1,1v2.7c0,0.6-0.4,1.1-1,1.1
          h-2.7c-0.6,0-1-0.4-1-1.1V8.1C18.9,7.5,19.3,7,19.9,7z M21.8,0.8h2.7c0.3,0,0.4,0.2,0.6,0.3l4.5,4.4h-4.6L21.8,0.8z M25.2,7h3.9
          C29.7,7,30,7.5,30,8v3.2c0,0.4-0.3,0.8-0.8,0.8h-3.9V7H25.2z"
      />
    ),
    speed: (
      <path
        className="st0"
        d="M0,17.3v-0.2h2.4v0.2c-0.1,2.5,0.6,5.3,2.1,7.5v0.2L3.3,27l-0.1-0.2
          C0.9,24-0.1,20.7,0,17.3z M4.7,7L4.7,7l1.6,1.5L6.1,8.9C6,9,6,9,5.8,9.1C4.1,11,3,13.3,2.4,15.9v0.2H0.2v-0.2c0.3-3.2,1.6-6,4-8.4
          C4.4,7.4,4.4,7.2,4.7,7z M14.4,3h0.1v2.3h-0.1C11.8,5.4,9.3,6.3,7,8v0.2L5.2,6.5l0.1-0.2C8.1,4.2,11.1,3.1,14.4,3z M24.4,6.3
          l0.1,0.2l-1.6,1.6l-0.1-0.1c-2.1-1.7-4.5-2.6-7-2.7h-0.1V3h0.1C18.9,3.1,21.9,4.2,24.4,6.3z M29.8,15.9v0.2h-2.4v-0.2
          c-0.4-2.7-1.6-5.1-3.7-7.1l-0.1-0.1l1.6-1.6l0.3,0.1C27.9,9.5,29.4,12.6,29.8,15.9z M26.8,26.8L26.7,27l-1.3-2.1v-0.2
          c1.5-2.4,2.2-5,2.1-7.5v-0.2H30v0.2C30.1,20.7,29.1,24,26.8,26.8z M25.6,18.5l-5.1-0.3L16,18c-1.2,0.6-2.7-0.3-2.2-1.6
          c0.1-1.4,1.9-1.7,2.7-0.6l4.2,1.3L25.6,18.5z"
      />
    ),
    twitter: (
      <path
        className="st0"
        d="M30,6c-1.1,0.5-2.3,0.8-3.5,1c1.3-0.8,2.2-2,2.7-3.5C28,4.2,26.7,4.7,25.3,5
          c-1.1-1.2-2.7-2-4.5-2c-3.4,0-6.2,2.8-6.2,6.3c0,0.5,0.1,1,0.2,1.4C9.7,10.5,5.1,8,2.1,4.2c-0.5,0.9-0.8,2-0.8,3.2
          c0,2.2,1.1,4.1,2.7,5.3c-1,0-2-0.3-2.8-0.8v0.1c0,3.1,2.1,5.6,4.9,6.2c-0.5,0.1-1.1,0.2-1.6,0.2c-0.4,0-0.8,0-1.2-0.1
          c0.8,2.5,3.1,4.3,5.7,4.4c-2.1,1.7-4.8,2.7-7.6,2.7c-0.5,0-1,0-1.5-0.1C2.7,27,6,28,9.4,28c11.3,0,17.5-9.6,17.5-18
          c0-0.3,0-0.5,0-0.8C28.1,8.3,29.2,7.2,30,6"
      />
    ),
    type: (
      <path
        className="st0"
        d="M30,15.5c0,0.4-0.3,0.8-0.8,0.8h-19c-0.5,1-1.4,1.8-2.6,1.8s-2.1-0.8-2.4-1.8H0.8
          c-0.5,0-0.8-0.3-0.8-0.8c0-0.5,0.3-0.8,0.8-0.8h4.5c0.3-1.1,1.4-1.8,2.4-1.8s2.1,0.8,2.4,1.8h19.2C29.7,14.7,30,15,30,15.5z
           M29.3,24.6H17.4c-0.3-1-1.4-1.8-2.4-1.8s-2.1,0.8-2.4,1.8H0.8c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h11.8
          c0.3,1.1,1.3,1.8,2.4,1.8s2.1-0.8,2.4-1.8h11.8c0.4,0,0.8-0.3,0.8-0.8C30,24.9,29.7,24.6,29.3,24.6z M0.8,6.3H20
          c0.3,1,1.3,1.8,2.4,1.8c1.2,0,2.1-0.8,2.4-1.8h4.5C29.7,6.3,30,6,30,5.6s-0.3-0.8-0.8-0.8h-4.5C24.4,3.8,23.5,3,22.3,3
          c-1.2,0-2.1,0.8-2.4,1.8H0.8C0.3,4.8,0,5.1,0,5.6S0.3,6.3,0.8,6.3z"
      />
    ),
    up: (
      <path
        className="st0"
        d="M22.1,4.9c-0.7,0-1.3,0.8-1.3,1.3s0.6,1.2,1.3,1.2h3.6L14.6,17.8l-4-3.3c-0.1-0.3-0.6-0.4-1-0.4
          c-0.1,0-0.4,0.2-0.7,0.4L0.4,22c-0.4,0.5-0.6,1.4-0.1,1.8c0.4,0.5,1.3,0.6,1.8,0l7.5-6.7l4,3.3c0.4,0.4,1.3,0.6,1.8,0.1l12-11.4
          v3.8c0,0.6,0.6,1.2,1.2,1.2c0.6,0,1.2-0.6,1.2-1.2V6.3c0-0.6-0.6-1.2-1.2-1.2h-6.4V4.9z"
      />
    ),
    view: (
      <path
        className="st0"
        d="M15,25.5C9.5,25.5,4.5,22,0,15C4.5,8,9.5,4.5,15,4.5S25.5,8,30,15
          C25.5,22,20.5,25.5,15,25.5z M15,23.3c4,0,8-2.8,12-8.3c-4-5.5-8-8.2-12-8.2S7,9.5,3,15C7,20.5,11,23.3,15,23.3z M15,19.5
          c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5s4.5,2,4.5,4.5S17.5,19.5,15,19.5z"
      />
    ),
    wheel: (
      <path
        className="st0"
        d="M15,0C6.8,0,0,6.8,0,15s6.8,15,15,15s15-6.8,15-15S23.3,0,15,0z M15,1.3c7.5,0,13.7,6.2,13.7,13.6
          S22.5,28.7,15,28.7S1.3,22.5,1.3,15S7.5,1.3,15,1.3z M15,3C8.4,3,3,8.4,3,15s5.4,12,12,12s12-5.4,12-12S21.6,3,15,3z M14.3,4.5v8.3
          c-0.2,0-0.3,0.2-0.5,0.2L8,7C9.8,5.6,11.9,4.6,14.3,4.5z M15.8,4.5C18.2,4.6,20.4,5.6,22,7l-5.9,5.9c-0.1-0.2-0.3-0.2-0.5-0.2
          C15.7,12.8,15.7,4.5,15.8,4.5z M7,8l5.9,5.9c-0.2,0.1-0.2,0.3-0.2,0.4H4.5C4.6,11.9,5.6,9.8,7,8z M23,8c1.5,1.7,2.4,3.9,2.5,6.3
          h-8.3c0-0.2-0.2-0.3-0.2-0.5L23,8z M15,14c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S14.4,14,15,14z M4.5,15.8h8.3
          c0,0.2,0.2,0.3,0.2,0.5l-5.9,5.9C5.6,20.3,4.6,18.2,4.5,15.8z M17.3,15.8h8.3c-0.2,2.4-1.1,4.7-2.5,6.3l-5.9-5.9
          C17.3,16,17.3,15.9,17.3,15.8z M13.8,17.1c0.2,0.2,0.3,0.2,0.5,0.2v8.3C11.9,25.3,9.6,24.4,8,23L13.8,17.1z M16.2,17.1l5.9,5.9
          c-1.6,1.5-3.9,2.4-6.3,2.6v-8.3C15.9,17.3,16,17.3,16.2,17.1z"
      />
    ),
  };

  static displayName = 'Icon';

  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
  };

  render() {
    let { className, icon } = this.props;

    return (
      <>
        {IconLogo.icons[icon] ? (
          <svg className={classNames('icon', `icon-${icon}`, {}, className)} viewBox="0 0 30 30">
            {IconLogo.icons[icon]}
          </svg>
        ) : (
          <Image src={getCarLogoImg(icon)} alt={icon} noLoader />
        )}
      </>
    );
  }
}
export { IconLogo };
