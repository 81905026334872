import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './section-text.sass';

const SectionText = ({ children, className, white }) => (
  <div className={classNames('section-text', className, { white })}>{children}</div>
);

SectionText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  white: PropTypes.bool,
};

export { SectionText };
