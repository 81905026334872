import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './user-avatar.sass';
import { asset } from 'app/utils';
import { Icon, Image } from 'app/components/elements';
import DefaultUser from "./default-avatar.png";
import axios from 'axios';
import Identicon from 'react-identicons';

function UserAvatar(props) {

  const [showIcon, setShowIcon] = useState(false);
  const [showDefault, setShowDefault] = useState(false);


  function errorLoading(e) {
    if (props.icon) {
      // We will only show an icon if provided and src couldn't load
      // else default profile picture avatar
      setShowIcon(true);
    }
    // } else {
    //   setShowDefault(true);
    // }
    props.onError();
  }


  function renderAvatar() {
    if (showIcon) {
      return (<Icon className="icon" icon={props.icon} white />);
    /*} else if (showDefault) {
      //return <Identicon string={props.userID} bg={'#fff'} padding={0}/>
      return <Image src={require('./default-avatar.png')} alt={'user'} className="img-fluid" />
    }*/
    } else if (props.userID === 'motobloqLogo') {
      return <Image src={require('app/assets/logo_b.png')} alt={'MB'} className="img-fluid" onError={(e) => errorLoading(e)}  />
    } else if (props.userID === 'ethereum') {
      return <Image src={require('app/assets/ethereum.png')} alt={'MB'} className="img-fluid" onError={(e) => errorLoading(e)}  />
    } else {
      return <Image src={`${axios.defaults.baseURL}users/${props.userID}/profileImage`} fallbackSrc={require('./default-avatar.png')} alt={props.alt} onError={(e) => errorLoading(e)} className="profile-pic" />
    }
  }

  return (
    <div className={classNames('user-avatar', props.className, (showIcon && 'isIcon'), ((props.userID !== 'motobloqLogo') && 'addBorder') )}  onClick={props.onClick}>
      {renderAvatar()}
    </div>
  )


};

UserAvatar.propTypes = {
  userID: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  icon: PropTypes.string,
  onClick: PropTypes.func,
  onError: PropTypes.func,
  white: PropTypes.bool,
  alt: PropTypes.string,
};

UserAvatar.defaultProps = {
  onClick: () => {},
  onError: () => {},
};

export { UserAvatar };
