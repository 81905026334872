import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Row, Col } from 'react-bootstrap';
import './cart-user-garage.sass';

import { getVehicleThumbnail } from 'app/utils';
import { route } from 'app/routes';

import { UserAvatar } from 'app/components/user';

const CartUserGarage = ({ data }) => (
  <div className="cart-item cart-user-garage w-100 h-100 border border-3 rounded-3">

    <Link to={route('userGarage', { id: data.ownerId })}>
      <UserAvatar userID={data.ownerId} className="medium border border-3" />
    </Link>

    <div className="text-dark text-bold text-lg mt-3">{data.ownerName}</div>

    <Link to={route('userGarage', { id: data.ownerId, name: data.ownerName })} className="text-sm">
      See all {data.quantity} cars
    </Link>

    <div className="cart-user-garage__cars">
      {_.map(data.vehicleImages.slice(0, 6), (image, index) => (
        <div key={index} className="cart-user-garage__car-col">
          <img src={image} alt={'loading...'} />
        </div>
      ))}
    </div>

  </div>
);

CartUserGarage.propTypes = {
  data: PropTypes.object.isRequired,
};

export { CartUserGarage };
