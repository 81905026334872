import React from "react";
import PropTypes from "prop-types";

import "./user-profile-header.sass";

import { CopyToClipboard, Icon } from "app/components/elements";
import { UserAvatar } from "..";

const UserProfileHeader = ({
  data: {
    metaMaskKey,
    nickname,
    profilePhoto,
    quote
  }
}) => (
  <div className="user-profile-header">
    <div className="user-profile-header__avatar">
      <UserAvatar avatar={profilePhoto}/>
    </div>
    
    <div className="user-profile-header__name">{nickname}</div>
    
    {(
      metaMaskKey &&
      (
        <div className="user-profile-header__key">
          <span>{metaMaskKey}</span>
    
          <CopyToClipboard copy={metaMaskKey}>
            <Icon icon="copy"/>
          </CopyToClipboard>
        </div>
      )
    )}
    
    {(
      quote &&
      <div className="user-profile-header__quote">{quote}</div>
    )}
    
    {/*<div className="user-profile-header__buttons">
          <div className="buttons-inline">
            <ButtonsInline>
              <Button>
                Follow user
              </Button>
              
              <Button noStyling>
                <Icon icon="share"/>
  
                <span>Share Profile</span>
              </Button>
            </ButtonsInline>
          </div>
        </div>*/}
  </div>
);

UserProfileHeader.propTypes = {
  data: PropTypes.object
};

export { UserProfileHeader }
