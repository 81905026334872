import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import _ from "lodash";
import classNames from "classnames";
import { isMobile } from "react-device-detect";

import "./dropdown.sass";





function Dropdown (props) {

  const _elementRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [contentShow, setContentShow] = useState(false);

  const [dropdownToggle, setDropdownToggle] = useState(null);
  const [dropdownContent, setDropdownContent] = useState(null);


  useEffect(() => {
    _.forEach(props.children, child => {
      switch (child.type.displayName) {
        case 'DropdownContent':
          setDropdownContent(child);
          break;
        case 'DropdownToggle':
        default:
          setDropdownToggle(child);
          //setDropdownToggle(React.cloneElement(child, { onClick: _dropdownOpen }));
          break;
      }
    });
  }, [props.children])




  // Clean up the event listener on unmount
  // useEffect(() => {
  //   if (isOpen) {
  //     window.addEventListener('click', _windowOnClick);
  //   } else {
  //     window.removeEventListener('click', _windowOnClick);
  //   }
  //   return () => window.removeEventListener('click', _windowOnClick);
  // }, [isOpen])



  function _windowOnClick(e) {
    if (Boolean(e.target.closest(".dropdown"))) {

    } else {
      _dropdownClose();
    }
  };


  useEffect(() => {
    if (isOpen) {
      window.addEventListener('click', _windowOnClick);
    } else {
      window.removeEventListener('click', _windowOnClick);
    }
    return () => window.removeEventListener('click', _windowOnClick);
  }, [isOpen])



  function _dropdownOpen() {
    if (isOpen == false) {
      setIsOpen(true);
      setContentShow(true);
      // setTimeout(() => {
      //   window.addEventListener('click', _windowOnClick);
      // }, 0);
    }
  };



  function _dropdownClose() {
    if (isOpen == true) {
      setIsOpen(false);
      setTimeout(() => setContentShow(false), 300);
      //window.removeEventListener('click', _windowOnClick);
    }
  };


  return (
    <div
      ref={_elementRef}
      className={classNames(
        'dropdown',
        {
          'open': isOpen,
        },
        props.className
      )}
      style={{position: isMobile ? 'static' : 'relative'}}
    >

      <div onClick={_dropdownOpen}>{dropdownToggle}</div>

      {(contentShow) &&
        dropdownContent
      }

    </div>
  );





}

export { Dropdown }
