import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import { UserAvatar } from 'app/components/user';

import './header.sass';

import { route } from 'app/routes';
import { logOut } from 'app/store/actions/userActions';

import { Dropdown, DropdownContent, DropdownToggle } from 'app/components/dropdown';
import { Icon, Logo } from 'app/components/elements';

import { useAccount } from 'wagmi';
import { Address, ConnectorInfo } from 'app/components/web3'

//import { Dropdown } from 'react-bootstrap'

function Header(props) {
  const whitePaths = [
    /^(\/)$/i,
    /^(\/all-cars)/i,
    //^(\/showroom)/i,
    /^(\/marketplace)/i,
    /^(\/privatesale)/i,
    /^(\/featured)/i,
    /^(\/page)/i,
    /^(\/release)/i,
    /^(\/thank-registering)/i,
    /^(\/model\/(\d+|0))/i,
    /^(\/model)/i,
    // /^(\/garage)/i,
    /^(\/order\/(\w+)[/]?)$/i,
    /^(\/trade)/i,
    /^(\/reserved)/i,
    /^(\/redeem\/(\d+|0))/i,
    /^(\/pre-sale)/i,
  ];

  const homePaths = [
    /^(\/)$/i,
    /^(\/all-cars)/i,
    /^(\/showroom-cars)/i,
    /^(\/marketplace)/i,
    /^(\/featured)/i,
    /^(\/landing)$/i,
    /^(\/page)/i,
    /^(\/release)/i,
    /^(\/reserved)/i,
    /^(\/thank-registering)/i,
    /^(\/pre-sale)/i
  ];


  const {
    //match: { url = '' },
    userData,
    configData,
    logOut
  } = props;

  const location = useLocation();

  const { status, address, connector } = useAccount();

  const [white, setWhite] = useState(false);
  const [gray, setGray] = useState(false);
  const [isHomePageStyle, setIsHomePageStyle] = useState(false);
  const [pageGarage, setPageGarage] = useState(false);

  useEffect(() => {
    const url = location.pathname;
    const index = _.findIndex(whitePaths, (val) => val.test(url));
    setIsHomePageStyle(_.findIndex(homePaths, (val) => val.test(url)));
    setPageGarage((/^(\/garage)/i.test(url)) || (/^(\/user-garage)/i.test(url)));
    setWhite(index !== -1 || (pageGarage && !configData.garage.headerWhite));
    if (configData.headerColor === 'gray') {
      setGray(true);
    } else {
      setGray(false);
    }
    if (!(/^(\/user-garage)/i.test(url))) setGray(false);
  }, [location, configData])




  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}

    </a>
  ));

  //console.log('userData', userData);
  //<div className="main-header__profile__info">{metaMaskKey && this._renderMetaMaskInfo()}</div>

  return (
    <header
      className={classNames('main-header border-top border-primary border-5', {
        white,
        gray,
        'page-garage': pageGarage,
        'page-home': isHomePageStyle >= 0,
      })}
    >
      <div className="container">
        <div className="main-header__content-items">
          <div
            className={classNames('main-header__showroom', {
              online: userData.auth,
            })}
          >
            <Link
              to={{
                pathname: '/showroom',
                state: {
                  scrollTag: 'showroom',
                },
              }}
              className="d-inline align-middle "
            >
              <Icon icon="showroom" />

              <span className="only-desktop align-bottom">Showroom</span>
            </Link>
          </div>
        </div>

        <div className="main-header__content-items">
          <Link to={route('home')} className="main-logo custom-link">
            <Logo white={white} />
          </Link>
        </div>

        <div className="main-header__content-items">
          {userData.auth ? (
            <>

              <Dropdown>

                <DropdownToggle>
                  <div className="main-header__profile">
                    <div className={"d-none d-md-block me-2"}>
                      <div style={{ userSelect: "none" }} className={classNames('line-height-sm', { 'text-white': white, 'text-dark': !white })}>{userData.nickname ? userData.nickname : userData.email}</div>
                      {(status === 'connected') &&
                        <Address className={classNames('text-xs', { 'text-white': white, 'text-dark': !white })} address={address} noTooltip />
                      }
                    </div>
                    <UserAvatar userID={userData.userId} alt={userData.nickname} className="xsmall" />
                  </div>
                </DropdownToggle>

                <DropdownContent width={200} positionMobile="top" className="text-center">
                  <NavLink to={route('garage')} className="dropdown-menu-item">
                      Garage
                  </NavLink>

                  <hr className="my-1"/>

                  <NavLink to={route('wallet')} className="dropdown-menu-item">
                      Wallet
                  </NavLink>

                  <hr className="my-1"/>

                  <NavLink to={route('settings')} className="dropdown-menu-item">
                      Settings
                  </NavLink>

                  <hr className="my-1"/>

                  {userData.admin &&
                    <>
                      <NavLink to={route('adminPanel')} className="dropdown-menu-item">
                        Admin Panel
                      </NavLink>

                      <hr className="my-1"/>
                    </>
                  }

                  <div onClick={logOut} className="dropdown-menu-item">
                      Logout
                  </div>
                </DropdownContent>

              </Dropdown>

              {/*
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                  <div className="main-header__profile">
                    <span className={"d-none d-md-block me-2"}>
                      <span className={"me-1 " + (white ? 'text-white' : 'text-dark')}>{userData.nickname ? userData.nickname : userData.email}</span>
                      <span className={(white ? 'text-white' : 'text-blue')}>&#x25bc;</span>
                    </span>

                    <UserAvatar avatar={userData.profilePhoto} alt={userData.nickname} className="small" />
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="1">Red</Dropdown.Item>
                  <Dropdown.Item eventKey="2">Blue</Dropdown.Item>
                  <Dropdown.Item eventKey="3" active>
                    Orange
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="1">Red-Orange</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              */}

              {/* <NavLink className="main-header__profile" to="/garage">
                <div className="main-header__profile__info">
                  {
                    this.props.userData.metaMaskKey &&
                    this._renderMetaMaskInfo()
                  }
                </div>
                <Icon icon="profile"/>
              </NavLink> */}
              {/*<div className="main-header__profile__activity">
                <Icon icon="activity"/>
                <span>31</span>
              </div>*/}
            </>
          ) : (
            <ul className="main-header__actions text-sm">
              <li>
                <Link to={route('login')}>
                  Login <span className="only-desktop">&nbsp;to MotoBloq </span>
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </header>
  );

}

Header =
  connect(
    ({ config, user }) => ({
      configData: config,
      userData: user,
    }),
    {
      logOut,
    },
  )(Header);

export { Header };
