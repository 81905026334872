import { CHANGE_MODALS, SHOW_MODAL, UPDATE_MODAL } from "app/store/actions/modalsActions";

const modalsData = { modals: [] };

export function modals(state = modalsData, action = {}) {
  switch (action.type) {
    case UPDATE_MODAL:
      const { index, data } = action.payload;

      state.modals[index] = data;

      return { ...state };
    case CHANGE_MODALS:
      return { ...state, ...action.payload };
    case SHOW_MODAL:
      state.modals.push(action.payload);

      return { ...state };
    default:
      return state;
  }
}
