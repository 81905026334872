import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './timer.sass';

const typesDay = {
  default: [
    { label: 'DAYS', key: 'days', after: ' ' },
    { label: 'HOURS', key: 'hours', after: ' ' },
    { label: 'MIN', key: 'minutes', after: ' ' },
    { label: 'SEC', key: 'seconds', after: '' },
  ],
  preSale: [
    { label: 'DAYS', key: 'days', after: ':' },
    { label: 'HOURS', key: 'hours', after: ':' },
    { label: 'MINUTES', key: 'minutes', after: ':' },
    { label: 'SECONDS', key: 'seconds', after: '' },
  ],
};

class Timer extends Component {
  static propTypes = {
    timerText: PropTypes.string,
    date: PropTypes.string,
    styleTimer: PropTypes.string,
  };

  static defaultProps = {
    timerText: '',
    styleTimer: 'default',
  };
  constructor(props) {
    super(props);
    this.count = this.count.bind(this);
    this.state = {
      days: 0,
      minutes: 0,
      hours: 0,
      secounds: 0,
      time_show: false,
    };
    this.x = null;
    this.deadline = null;
  }
  count() {
    const now = new Date().getTime();
    const t = this.deadline - now;
    const days = Math.floor(t / (1000 * 60 * 60 * 24));
    const hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((t % (1000 * 60)) / 1000);
    this.setState({ days, minutes, hours, seconds, time_show: true });
    if (t < 0) {
      clearInterval(this.x);
      this.setState({ days: 0, minutes: 0, hours: 0, seconds: 0, time_show: true });
    }
  }
  componentDidMount() {
    const { date } = this.props;
    this.deadline = new Date(date).getTime();

    this.x = setInterval(this.count, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.x);
    this.setState({ days: 0, minutes: 0, hours: 0, seconds: 0, time_show: false });
  }

  render() {
    const { time_show } = this.state;
    const { timerText, styleTimer } = this.props;

    if (!time_show) return null;

    return (
      time_show && (
        <p className={`timer-wrap ${styleTimer}`}>
          {timerText && <span className="timer-text">{timerText} </span>}
          {typesDay[styleTimer] &&
            typesDay[styleTimer].map((timeItem, key) => (
              <span key={key}>
                <span>
                  <span className="modal-number">{this.state[timeItem.key]}</span>
                  {timeItem.label}
                </span>
                {timeItem.after}
              </span>
            ))}
        </p>
      )
    );
  }
}

export { Timer };
