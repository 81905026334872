import React from "react";
import PropTypes from "prop-types";

const DropdownToggle = ({ children, onClick }) => React.cloneElement(children, { onClick });

DropdownToggle.displayName = 'DropdownToggle';

DropdownToggle.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func
};

DropdownToggle.defaultProps = {
  onClick: () => {}
};

export { DropdownToggle }
