import PropTypes from "prop-types";

const ModalFooter = ({ children }) => children;

ModalFooter.displayName = 'ModalFooter';

ModalFooter.propTypes = {
  children: PropTypes.node
};

ModalFooter.defaultProps = {
  children: null
};

export { ModalFooter }
