import { CHANGE_CONFIG } from "app/store/actions/configActions";

const configData = {
  headerColor: 'default',
  garage: {
    carCount: 1,
    headerWhite: true,
  },
  showLoader: false
};

export function config(state = configData, action = {}) {
  switch(action.type) {
    case CHANGE_CONFIG:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
